import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react'

export const showToast = (options: UseToastOptions): any => {
  const { toast } = createStandaloneToast()

  return toast({
    position: 'top-right',
    duration: 3000,
    isClosable: true,
    containerStyle: {
      position: 'fixed',
      top: '90px',
    },
    ...options,
  })
}
