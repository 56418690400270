import { AxiosError, AxiosResponse } from 'axios'
import {axiosInstance} from '../../config/axios.config'
import {
  IAuthCreateErrorResponse,
  IAuthCreateSuccessResponse,
} from '../LoginPage/LoginPage.types'
export interface IPropsForgetPasswordPage {
  props?: object
}

export interface IAuthForgetPassword {
  email: string
}

export function authForgetPassword(
  url: string,
  payload: IAuthForgetPassword,
  callback?: (
    result:
      | AxiosResponse<IAuthCreateSuccessResponse>
      | AxiosError<IAuthCreateErrorResponse>
  ) => void
): void {
  axiosInstance
    .post<IAuthCreateSuccessResponse>(url, payload)
    .then((response) => {
      callback?.(response)
    })
    .catch((error: unknown) => {
      if (error instanceof AxiosError) {
        callback?.(error)
      }
    })
}
