import React, { createContext, useEffect, useState } from 'react'
import { AuthContextProps } from '../Context.types'
import { LOGIN, SIGNUP } from '../../Routes/Routes'
import { useLocation } from 'react-router-dom'
import { AuthContextType } from './AuthContextType'
import AuthContextDefault from './AuthContextDefault'
import { useMediaQuery } from '@chakra-ui/react'

export const AuthContext = createContext<AuthContextType>(AuthContextDefault)

const AuthContextProvider: React.FC<AuthContextProps> = ({ children }) => {
  let [token, setToken] = useState<string | null>(() => localStorage.getItem('token'))
  const { pathname } = useLocation()
  const [firstTimeUser, setFirstTimeUser] = useState<boolean>(true)
  const [userInfo, setUserInfo] = useState<any>({})
  const isLargeScreen991 = useMediaQuery('(min-width: 991px)')
  const defaultFontSize = {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    fontSize: isLargeScreen991 ? '16px !important' : undefined,
  }

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    if (storedToken !== token) {
      setToken(storedToken)
    }
  }, [token])

  let name = ''
  let email = ''
  let picture = 'https://img.icons8.com/fluency/48/user-male-circle--v1.png'

  useEffect(() => {
    name = localStorage.getItem('name') ?? ''
    email = localStorage.getItem('email') ?? ''
    picture = localStorage.getItem('picture') ?? 'https://img.icons8.com/fluency/48/user-male-circle--v1.png'
    token = localStorage.getItem('token')

    if (name !== undefined && email !== undefined && picture !== undefined && token !== undefined) {
      setUserInfo({
        name,
        email,
        picture,
        token,
      })
    }
  }, [])

  name = localStorage.getItem('name') ?? userInfo.name
  email = localStorage.getItem('email') ?? userInfo.email
  picture = localStorage.getItem('picture') ?? userInfo.picture
  token = localStorage.getItem('token') ?? userInfo.token

  console.log(name, email, picture)

  useEffect(() => {
    if (pathname === LOGIN || pathname === SIGNUP) {
      localStorage.clear()
    }
  }, [pathname])

  useEffect(() => {
    const cartData = localStorage.getItem('cart')
    if (cartData !== null || (token !== null && token !== '')) {
      setFirstTimeUser(false)
    } else {
      setFirstTimeUser(true)
    }
  }, [token])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        firstTimeUser,
        setFirstTimeUser,
        name,
        email,
        picture,
        defaultFontSize,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
