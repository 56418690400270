import React, { useContext, useState } from 'react'
import DSCarousel from '../../components/DSCarousel/DSCarousel'
import EmailSubscription from '../../components/EmailSubscription/EmailSubscription'
import Product from '../../components/Product/Product'
import Footer from '../../Layout/Footer/Footer'
import { IPropsProductDescription } from './ProductDescription.types'
import { Header } from '../../Layout/Header/Header'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { ProductContext } from '../../Context/ProductContext.Provider/ProductContext'
import { Navigate } from 'react-router-dom'
import { HOME } from '../../Routes/Routes'
import ContentWrap from '../../components/ContentWrap/ContentWrap'

const ProductDescription = ({ props }: IPropsProductDescription): JSX.Element => {
  const [isProductAdded, setIsProductAdded] = useState<boolean>(false)

  const { product, isLoading } = useContext(ProductContext)

  return (
    <>
      <Header headerFor="PRODUCTS" isProductAdded={isProductAdded} />
      <MobileHeader headerFor="NORMAL" />
      <ContentWrap isFetching={isLoading} isError={false} count={1}>
        {product === null || product === undefined ? <Navigate to={HOME} /> : <Product productDetails={product} isProductAdded={isProductAdded} setIsProductAdded={setIsProductAdded} />}
      </ContentWrap>
      <DSCarousel heading="Related Products" text="See how much you can save on medications with other brands." />
      <EmailSubscription />
      <Footer />
    </>
  )
}

export default ProductDescription
