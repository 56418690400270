import React, { useContext } from 'react'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { Image } from '@chakra-ui/react'
import spinnner from './rotate-spinner.svg'

const ProfileIcon = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  const { picture, token } = useContext(AuthContext)

  if (token !== null || token !== undefined) {
    return <Image fallback={<img src={spinnner} alt="dotted spinner rotating" width={48} height={48} />} src={picture} alt="" sx={{ width: '48px', height: '48px', overflow: 'hidden', borderRadius: '50%' }} />
  } else {
    return (
      <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_56397_61450)">
          <path d="M24 24C28.42 24 32 20.42 32 16C32 11.58 28.42 8 24 8C19.58 8 16 11.58 16 16C16 20.42 19.58 24 24 24ZM24 28C18.66 28 8 30.68 8 36V40H40V36C40 30.68 29.34 28 24 28Z" fill="#514F6D" />
        </g>
        <defs>
          <clipPath id="clip0_56397_61450">
            <rect width="48" height="48" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default ProfileIcon

ProfileIcon.defaultProps = {
  height: 48,
  width: 48,
}
