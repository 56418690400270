import React from 'react'
import { Heading, Box, Input, Flex, Button } from '@chakra-ui/react'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsEmailSubscription } from './EmailSubscription.types'

const EmailSubscription = (props: IPropsEmailSubscription): JSX.Element => {
  return (
    <Box sx={commonStyles.emailSubscription}>
      <Flex
        flexDirection={'column'}
        p={{ base: '4rem', lg: '8rem 5rem}' }}
        alignItems={{ lg: 'center' }}>
        <Heading
          variant={{ base: 'mobileMedium20', lg: 'font24' }}
          color={{ lg: 'secondary' }}>
          Stay up-to-date with common healthcare trends
        </Heading>
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          mt={'4rem'}
          flexDirection={{ base: 'column', lg: 'row' }}
          gap={{ base: '8px', lg: '0' }}>
          <Input
            placeholder="Enter your email address"
            size="lg"
            maxWidth={{ lg: '36rem', xl: '60rem' }}
            sx={commonStyles.inputField}
            _placeholder={{ color: 'secondary' }}
          />
          <Button sx={commonStyles.addMeButton}>Add Me</Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default EmailSubscription
