import React from 'react'

const EditIcon = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56852_1899)">
        <path
          d="M3.125 18.4684V22.3747H7.03125L18.5521 10.8538L14.6458 6.94759L3.125 18.4684ZM21.5729 7.83301C21.9792 7.42676 21.9792 6.77051 21.5729 6.36426L19.1354 3.92676C18.7292 3.52051 18.0729 3.52051 17.6667 3.92676L15.7604 5.83301L19.6667 9.73926L21.5729 7.83301Z"
          fill="#1C1B1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_56852_1899">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditIcon

EditIcon.defaultProps = {
  height: 25,
  width: 26,
}
