import React, { useEffect, useState } from 'react'
import { Button, Stack } from '@chakra-ui/react'
import BackButton from '../../Assets/Images/BackButton'
import ForwardArray from '../../Assets/Images/ForwardArray'
import { commonStyles } from '../../styles/commonStyles'
import { itemsPerPage } from '../../Content/Contants'
import { IPropsResult } from '../ProductListing/ProductListing.types'

interface PaginationProps {
  count: number
  data: IPropsResult[]
  setCurrentItems: Function
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  data,
  setCurrentItems,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const pageNumbers = []

  const totalPages = Math.ceil(count / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  useEffect(() => {
    setCurrentItems(data.slice(startIndex, endIndex))
  }, [currentPage])

  const handlePageClick = (pageNumber: number): void => {
    setCurrentPage(pageNumber)
  }

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }

  const handlePrevPageClick = (): void => {
    const prevPage = currentPage - 1
    if (prevPage >= 1) {
      handlePageClick(prevPage)
    }
  }

  const handleNextPageClick = (): void => {
    const nextPage = currentPage + 1
    if (nextPage <= totalPages) {
      handlePageClick(nextPage)
    }
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      justify="center"
      align="center"
      my="6rem"
      flexWrap={'wrap'}>
      <Button
        isDisabled={currentPage === 1}
        onClick={handlePrevPageClick}
        aria-label="Previous page">
        <BackButton fill="#313033" width={24} height={24} />
      </Button>
      {pageNumbers.map((pageNumber) => (
        <Button
          key={pageNumber}
          padding="0.5rem"
          sx={commonStyles.paginationButton}
          borderRadius={0}
          color={pageNumber === currentPage ? 'white' : 'gray'}
          backgroundColor={
            pageNumber === currentPage ? 'secondary' : 'transparent'
          }
          onClick={() => handlePageClick(pageNumber)}>
          {pageNumber}
        </Button>
      ))}
      <Button
        isDisabled={currentPage === totalPages}
        onClick={handleNextPageClick}
        aria-label="Next page">
        <ForwardArray width={24} height={24} />
      </Button>
    </Stack>
  )
}

export default Pagination
