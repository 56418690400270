import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import CartEmpty from '../../components/CartEmpty/CartEmpty'
import ProductsListing from '../../components/ProductListing/ProductsListing'
import Footer from '../../Layout/Footer/Footer'
import { IPropsCartPage } from './CartPage.types'
import useSWR from 'swr'
import swrConfig from '../../config/swr.Config'
import { Header } from '../../Layout/Header/Header'
import { endpoints } from '../../Content/Endpoints'
import ContentWrap from '../../components/ContentWrap/ContentWrap'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'

const CartPage = ({ props }: IPropsCartPage): JSX.Element => {
  const { token } = useContext(AuthContext)
  const { notificationCartCount } = useContext(CartContext)

  const { fetcher } = swrConfig
  const { data: cartListing, isLoading, error, mutate } = useSWR(token !== null && token !== '' ? [endpoints.CART, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  return (
    <Box>
      <Header headerFor="PRODUCTS" />
      <MobileHeader headerFor="NORMAL" />
      <ContentWrap isFetching={isLoading} isError={error} count={cartListing?.count === undefined || cartListing?.count === 0 ? notificationCartCount : cartListing?.count} NoDataPage={<CartEmpty cartText="You currently do not have any item on your shopping cart. Search for Item and add item to cart" />}>
        <ProductsListing data={cartListing} type="Cart" mutate={mutate} />
      </ContentWrap>
      <Footer />
    </Box>
  )
}

export default CartPage
