import { Box, Button, Center, Divider, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import ForwardArray from '../../Assets/Images/ForwardArray'
import { billType } from '../../Content/Contants'
import Footer from '../../Layout/Footer/Footer'
import ProductsListing from '../../components/ProductListing/ProductsListing'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsBillPage } from './BillPage.types'
import SignupModal from '../../components/SignupModal/SignupModal'
import useSWR from 'swr'
import { Header } from '../../Layout/Header/Header'
import { useNavigate } from 'react-router-dom'
import { CHECKOUT } from '../../Routes/Routes'
import swrConfig from '../../config/swr.Config'
import { endpoints } from '../../Content/Endpoints'
import ContentWrap from '../../components/ContentWrap/ContentWrap'
import { IPropsResult } from '../../components/ProductListing/ProductListing.types'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'

const BillPage = ({ props }: IPropsBillPage): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const { token } = useContext(AuthContext)
  const { notificationCartCount } = useContext(CartContext)

  const [businessId, setBusinessId] = useState<number>()

  const { fetcher } = swrConfig
  const { data: cartListing, isLoading, error } = useSWR(token !== null && token !== '' ? [endpoints.CART, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const [price, setPrice] = useState<number>(0)
  const [localCartItems, setLocalCartItems] = useState<IPropsResult[]>([])

  useEffect(() => {
    const cartData = localStorage.getItem('cart')
    if (cartData !== null) {
      setLocalCartItems(JSON.parse(cartData))
    }
  }, [])

  useEffect(() => {
    if (token === null || token === '') {
      setBusinessId(localCartItems?.[0]?.business?.id ?? 1)
      setPrice(localCartItems?.reduce((total: number, product: IPropsResult): number => total + (product?.quantity ?? 0) * (product?.discounted_price ?? 0), 0))
    } else {
      setBusinessId(cartListing?.results?.[0]?.business?.id)
      setPrice(cartListing?.results?.reduce((total: number, product: IPropsResult): number => total + (product?.quantity ?? 0) * (product?.unit_price ?? 0), 0))
    }
  }, [cartListing, localCartItems])

  const onPlaceOrderClick = (): void => {
    if (token !== null && token !== '' && businessId !== undefined)
      navigate(`${CHECKOUT}?businessId=${businessId}`, {
        state: {
          amount: price,
        },
      })
    else onOpen()
  }

  return (
    <Box>
      <Header headerFor="PHARMACY" showHeader={true} />
      <Box maxWidth={'80rem'} p={'0 1.5rem'} marginX={'auto'} marginY={commonStyles.spacingFromHeader}>
        <Heading sx={commonStyles.font32_lightBlack} my={{ base: '2rem', lg: '4rem' }}>
          Order Summary
        </Heading>
        <Divider borderColor="#AEAAAE" />
        <ContentWrap isFetching={isLoading} isError={error} count={cartListing?.count === undefined || cartListing?.count === 0 ? notificationCartCount : cartListing?.count}>
          <ProductsListing data={cartListing} type="bill" />
        </ContentWrap>
        <Divider borderColor="#AEAAAE" />
        <Box m={{ base: '2rem 4rem', lg: '4rem 4rem' }}>
          <Flex justifyContent={'space-between'}>
            <Heading sx={commonStyles.font24_40} fontWeight={'600'} color={'black'}>
              Subtotal
            </Heading>
            <Heading sx={commonStyles.font24_40} fontWeight={'600'} color={'black'}>
              NGN {price ?? 0}
            </Heading>
          </Flex>
          {billType.map((bill, index) => (
            <Flex key={index} justifyContent={'space-between'} mt={'1.5rem'}>
              <Text sx={commonStyles.font24_40} fontWeight={'400'} color={'black'}>
                {bill.type}
              </Text>
              <Text sx={commonStyles.font24_40} fontWeight={'400'} color={'black'}>
                NGN {bill.price}
              </Text>
            </Flex>
          ))}
          <Flex justifyContent={'space-between'} mt={'4rem'}>
            <Heading sx={commonStyles.font32}>Total</Heading>
            <Heading sx={commonStyles.font32}>NGN {price ?? 0}</Heading>
          </Flex>
        </Box>
        <Center>
          <Button sx={commonStyles.productListingButton} my={'2rem'} fontWeight="700" rightIcon={<ForwardArray />} onClick={onPlaceOrderClick}>
            Place Order
          </Button>
        </Center>
      </Box>
      <Footer />
      {isOpen && <SignupModal isOpen={isOpen} onClose={onClose} price={price} businessId={businessId ?? 0} />}
    </Box>
  )
}

export default BillPage
