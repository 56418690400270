import React from 'react'

const Justice = (): JSX.Element => {
  return (
    <svg
      width="93"
      height="92"
      viewBox="0 0 93 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56293_5772)">
        <path
          d="M92.5 5.73149L91.8682 0.377883L49.1953 5.41398V0H43.8047V6.04846L0.5 11.1591L1.13178 16.5127L16.8516 14.6472V24.6172H22.2422V14.0133L43.8047 11.4766V83.9141H49.1953V10.8422L70.7578 8.30552V19.2266H76.1484V7.6714L92.5 5.73149Z"
          fill="#736B6B"
        />
        <path
          d="M49.1953 5.41398V0H46.5V83.9141H49.1953V10.8422L70.7578 8.30552V19.2266H76.1484V7.6714L92.5 5.73149L91.8682 0.377883L49.1953 5.41398Z"
          fill="#595353"
        />
        <path
          d="M38.4141 70.4375H0.5V21.9219H38.4141V70.4375Z"
          fill="#F9F4F3"
        />
        <path d="M92.5 65.0469H54.5859V16.5312H92.5V65.0469Z" fill="#FFC0B3" />
        <path
          d="M81.0757 31.5867L77.2644 27.7754L73.453 31.5867L69.6417 27.7754L65.8303 31.5867L69.6417 35.3981L65.8303 39.2094L69.6417 43.0208L73.453 39.2094L77.2644 43.0208L81.0757 39.2094L77.2644 35.3981L81.0757 31.5867Z"
          fill="#EC4C36"
        />
        <path
          d="M16.8515 49.9904L9.5553 42.694L13.3665 38.8828L16.8515 42.3677L25.7272 33.4922L29.5383 37.3034L16.8515 49.9904Z"
          fill="#8DB5FF"
        />
        <path
          d="M11.4609 54.2656H27.6328V59.6562H11.4609V54.2656Z"
          fill="#E6D5C3"
        />
        <path
          d="M65.3672 48.875H81.5391V54.2656H65.3672V48.875Z"
          fill="#F29885"
        />
        <path
          d="M27.6328 92C27.6328 86.054 32.4707 81.2188 38.4141 81.2188H54.5859C60.5293 81.2188 65.3672 86.054 65.3672 92H27.6328Z"
          fill="#595353"
        />
        <path
          d="M46.5 81.2188V92H65.3672C65.3672 86.054 60.5293 81.2188 54.5859 81.2188H46.5Z"
          fill="#403939"
        />
      </g>
      <defs>
        <clipPath id="clip0_56293_5772">
          <rect
            width="92"
            height="92"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Justice
