import React from 'react'
import { SimpleGrid, Heading, Box, Text, Flex } from '@chakra-ui/react'
import Facebook from '../../Assets/Images/Facebook.svg'
import Linkedin from '../../Assets/Images/Linkedin.svg'
import Instagram from '../../Assets/Images/Instagram.svg'

const FooterNavbar = (): JSX.Element => {
  const HealthArray = [
    'Latest Health News',
    'COVID-19 Vaccine',
    'COVID-19',
    'Seasonal Flu',
    'Erectile Dysfunction',
  ]
  const ToolInfoArray = [
    'How Drugstoc Works',
    'Find a Pharmacy Near Me',
    'Healthcare Professionals',
  ]
  const supportArray = ['Help & FAQs', 'Advertising Notice', 'Accessibility']
  return (
    <Box p={{ base: '2rem 1.5rem', lg: '4rem 8rem' }}>
      <SimpleGrid
        columns={{ base: 1, lg: 2, xl: 4 }}
        gap={{ base: '2.4rem', lg: '2rem', xl: '0' }}>
        <Box>
          <Heading variant={{ base: 'mobileBold20', lg: 'Bold' }} mb={'2rem'}>
            Health
          </Heading>
          {HealthArray?.map((healthPoint, index) => (
            <Text
              variant={{ base: 'mobileMediumBold', lg: 'light' }}
              key={index}>
              {healthPoint}
            </Text>
          ))}
        </Box>
        <Box>
          <Heading variant={{ base: 'mobileBold20', lg: 'Bold' }} mb={'2rem'}>
            Tool & Info
          </Heading>
          {ToolInfoArray?.map((toolPoint, index) => (
            <Text
              variant={{ base: 'mobileMediumBold', lg: 'light' }}
              key={index}>
              {toolPoint}
            </Text>
          ))}
        </Box>
        <Box>
          <Heading variant={{ base: 'mobileBold20', lg: 'Bold' }} mb={'2rem'}>
            Support
          </Heading>
          {supportArray?.map((supportPoint, index) => (
            <Text
              variant={{ base: 'mobileMediumBold', lg: 'light' }}
              key={index}>
              {supportPoint}
            </Text>
          ))}
        </Box>
        <Box>
          <Heading variant={{ base: 'mobileBold20', lg: 'Bold' }} mb={'2rem'}>
            Contact Us
          </Heading>
          <Text variant={{ base: 'mobileMediumBold', lg: 'light' }}>
            info@Drugstoc.com
          </Text>
          <Flex
            mt={{ base: '2rem', lg: '3.5rem' }}
            justifyContent={'flex-start'}
            gap={'3rem'}>
            <img src={Facebook} alt="facebook" />
            <img src={Linkedin} alt="linkedin" />
            <img src={Instagram} alt="instagram" />
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default FooterNavbar
