import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsCarouselContent } from './DSCarousel.types'
import ProductImage from '../ProductImage/ProductImage'
import { useNavigate } from 'react-router-dom'
import { PRODUCT } from '../../Routes/Routes'

const CarouselContent = ({ Product }: IPropsCarouselContent): JSX.Element => {
  const navigate = useNavigate()
  const discountPercent: number =
    ((Product?.price - Product?.discounted_price) * 100) / Product?.price

  const onProductNameClick = (): void => {
    navigate(
      `${PRODUCT}${Product?.product?.name !== undefined
        ? `?search=${Product?.product?.name}`
        : ''
      }`
    )
  }

  const showPrice: boolean =
    Product?.price !== Product?.discounted_price &&
    Product?.discounted_price !== 0

  return (
    <Box sx={commonStyles.innerCarouselContent}>
      <ProductImage
        imageUrl={Product?.product?.image ?? ''}
        styleHeight={{ base: '216px !important' }}
        style={{ base: '100%', lg: '250px' }}
      />
      <Text variant={'Bold'} onClick={onProductNameClick} cursor="pointer">
        {Product?.product?.name}
      </Text>
      <Text sx={commonStyles.text14}>{Product?.product?.composition}</Text>
      <Text>
        {showPrice && (
          <span style={commonStyles.text18}>NGN{Product?.price}</span>
        )}
        <span style={{ ...commonStyles.text22, whiteSpace: 'nowrap' }}>
          NGN{' '}
          {Product?.discounted_price === 0
            ? Product?.price
            : Product?.discounted_price}
        </span>
      </Text>
      {showPrice && (
        <Text sx={commonStyles.text14}>
          {discountPercent.toFixed(0)}% off with DrugStoc Voucher
        </Text>
      )}
    </Box>
  )
}

export default CarouselContent
