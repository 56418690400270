import { Box, Button, Heading, Spinner, Td, Text, Tr } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { commonStyles, contentWrap, contentWrapTable } from '../../styles/commonStyles'
import { IPropContentWrap } from './ContentWrap.types'
import { CheckoutContext } from '../../Context/CheckoutContext.Provider/CheckoutContext'

const ContentWrap = (props: IPropContentWrap): React.ReactElement => {
  const { isFetching, isError = false, children, count, NoDataPage, isInsideTable, styleNoDataFound } = props
  const { handleFormClick, shippingResult, addressCount } = useContext(CheckoutContext)
  const getJSXForContent = (): JSX.Element => {
    if (isError)
      return (
        <Box sx={contentWrap} my={'7rem'}>
          <Heading sx={commonStyles.font28}>Something went wrong.....</Heading>
        </Box>
      )
    else if (isFetching && isInsideTable === true) {
      return (
        <Tr>
          <Td colSpan={4} sx={contentWrapTable}>
            <Spinner />
          </Td>
        </Tr>
      )
    } else if (isFetching)
      return (
        <Box sx={contentWrap}>
          <Spinner />
        </Box>
      )
    else if (count === 0 || count === undefined) {
      return NoDataPage !== undefined ? (
        NoDataPage
      ) : (
        <>
          <Box sx={{ ...contentWrap, ...styleNoDataFound, height: '5rem' }} my={'1rem'}>
            <Heading sx={commonStyles.font28} textAlign="center">
              {shippingResult?.length > 0 ?? addressCount > 0 ? 'Shipping Address is Available' : 'Add Shipping Address to Place Order'}
            </Heading>
          </Box>
          {shippingResult?.length > 0 || addressCount > 0 ? (
            <Text textAlign="center" pos="relative" top={-4} fontSize={14}>
              Proceed to place an order
            </Text>
          ) : null}

          {shippingResult?.length === 0 && (
            <Button backgroundColor="#3182ce" color="#fff" fontSize={14} marginInline="auto" width="90%" onClick={handleFormClick} colorScheme="blue">
              Shipping Address Required
            </Button>
          )}
        </>
      )
    } else return children
  }

  return getJSXForContent()
}

export default ContentWrap
