import React from 'react'

const Cart = ({
  height,
  width,
  fill,
}: {
  height: number
  width: number
  fill?: string
}): JSX.Element => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.1 22C30.6 22 31.92 21.18 32.6 19.94L39.76 6.96C40.5 5.64 39.54 4 38.02 4H8.42L6.54 0H0V4H4L11.2 19.18L8.5 24.06C7.04 26.74 8.96 30 12 30H36V26H12L14.2 22H29.1ZM10.32 8H34.62L29.1 18H15.06L10.32 8ZM12 32C9.8 32 8.02 33.8 8.02 36C8.02 38.2 9.8 40 12 40C14.2 40 16 38.2 16 36C16 33.8 14.2 32 12 32ZM32 32C29.8 32 28.02 33.8 28.02 36C28.02 38.2 29.8 40 32 40C34.2 40 36 38.2 36 36C36 33.8 34.2 32 32 32Z"
        fill={fill}
      />
    </svg>
  )
}

export default Cart

Cart.defaultProps = {
  height: 40,
  width: 40,
  fill: '#514F6D',
}
