import React from 'react'

const ForwardArray = ({
  height,
  width,
  fill,
}: {
  height?: number
  width?: number
  fill?: string
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56421_918)">
        <path
          d="M4 14.6667H22.8933L18.12 9.88L20 8L28 16L20 24L18.12 22.12L22.8933 17.3333H4V14.6667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_56421_918">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="matrix(-1 0 0 1 32 0)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ForwardArray

ForwardArray.defaultProps = {
  height: 32,
  width: 32,
  fill: '#323232',
}
