import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../Context/AuthContext.Provider/AuthContext'
import { Box, Spinner } from '@chakra-ui/react'

const PrivateRoutes = (): JSX.Element => {
  const { token } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const checkLoggedIn = async (): Promise<void> => {
      setLoading(false)
    }
    void checkLoggedIn()
  }, [])
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" height="100vh" alignItems="center">
        <Spinner width={38} height={38} />
      </Box>
    )
  }
  return token?.length > 0 ? <Outlet /> : <Navigate to="login" />
}

export default PrivateRoutes
