import { useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import VisibilityFILL from '../../Assets/Images/VisibilityFILL'
import VisibilityOFF from '../../Assets/Images/VisibilityOFF'
import { IPropRightSidePasswordIcon } from './RightSidePasswordIcon.types'

const RightSidePasswordIcon = ({
  showPassword,
}: IPropRightSidePasswordIcon): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')

  return showPassword ? (
    largeScreen992 ? (
      <VisibilityFILL />
    ) : (
      <VisibilityFILL height={20} width={20} />
    )
  ) : largeScreen992 ? (
    <VisibilityOFF />
  ) : (
    <VisibilityOFF height={20} width={20} />
  )
}

export default RightSidePasswordIcon
