import React from 'react'

const Visa = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_57723_79332)">
        <path
          d="M26.1779 19.2077H21.4332L24.4012 0.857081H29.1459L26.1779 19.2077ZM43.3792 1.30575C42.4426 0.933747 40.9592 0.524414 39.1226 0.524414C34.4366 0.524414 31.1372 3.02308 31.1166 6.59508C31.0779 9.23108 33.4792 10.6951 35.2752 11.5737C37.1112 12.4717 37.7352 13.0577 37.7352 13.8584C37.7166 15.0877 36.2519 15.6544 34.8859 15.6544C32.9912 15.6544 31.9759 15.3624 30.4332 14.6784L29.8086 14.3851L29.1446 18.5051C30.2579 19.0117 32.3079 19.4617 34.4366 19.4811C39.4152 19.4811 42.6566 17.0211 42.6952 13.2144C42.7139 11.1251 41.4459 9.52441 38.7126 8.21641C37.0526 7.37641 36.0366 6.81041 36.0366 5.95175C36.0559 5.17041 36.8966 4.37041 38.7699 4.37041C40.3119 4.33175 41.4452 4.70241 42.3032 5.07308L42.7332 5.26841L43.3786 1.30575H43.3792ZM49.6852 12.7071C50.0759 11.6524 51.5799 7.57241 51.5799 7.57241C51.5599 7.61175 51.9699 6.49908 52.2039 5.81508L52.5359 7.39641C52.5359 7.39641 53.4346 11.7897 53.6292 12.7064H49.6852V12.7071ZM55.5426 0.856414H51.8726C50.7406 0.856414 49.8806 1.18775 49.3926 2.37908L42.3439 19.2071H47.3232C47.3232 19.2071 48.1432 16.9417 48.3186 16.4537H54.4112C54.5472 17.0984 54.9772 19.2071 54.9772 19.2071H59.3706L55.5426 0.857081V0.856414ZM5.78256 3.24308C4.32256 2.38975 2.6579 1.70375 0.795898 1.22708L0.873898 0.856414H8.5079C9.54323 0.895081 10.3826 1.22641 10.6559 2.39908L12.3146 10.8304L12.3152 10.8317L12.8232 13.3697L17.4699 0.856414H22.4872L15.0292 19.1884H10.0112L5.78256 3.24308Z"
          fill="#1A1F71"
        />
      </g>
      <defs>
        <clipPath id="clip0_57723_79332">
          <rect
            width="59.3333"
            height="20"
            fill="white"
            transform="translate(0.176758)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Visa

Visa.defaultProps = {
  height: 20,
  width: 60,
}
