/* eslint-disable linebreak-style */

export const toastStyles = {
  padding: '5px 10px',
  color: '#fff',
  borderRadius: 4,
  marginBlock: 5,
  position: 'absolute',
  top: '5%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: '1px solid #fff',
}

export const failedToastStyles = {
  backgroundColor: '#e53e3e',
}

export const successToastStyles = {
  backgroundColor: '#38a169',
}
