/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { FormLabel, InputGroup, InputLeftElement, Input, FormControl, Text, InputRightElement, Button, useMediaQuery } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { commonStyles } from '../../styles/commonStyles'
import { getIsValidValidation } from '../../utils/getIsValidValidation'
import { IPropsInput } from './DSInput.types'

const DSInput = ({ isRequired, formLabel, type, placeHolder, Icon, rightIcon, styles, name, formValue, setFormValue, error, setErrors, rightIconClickhandler, showLabel, showIcon }: IPropsInput): JSX.Element => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    getIsValidValidation(e.target.name, e.target.value, setErrors, formValue)

    setFormValue?.((prevValue: any) => ({
      ...prevValue,
      [e.target.name]: e.target.value,
    }))
  }

  const isLargeScreen991 = useMediaQuery('(min-width: 991px)')
  const defaultFontSize = {
    fontSize: isLargeScreen991 ? '16px !important' : undefined,
  }

  const isLargerThan991 = useMediaQuery('(min-width: 991px)')
  const isLessThan991 = useMediaQuery('(min-width: 990px)')

  return (
    <>
      <FormControl className="FormControlStyle" w={'100%'} sx={{ mt: isLessThan991 ? 10 : undefined, pos: 'relative', zIndex: 10 }} isRequired={isRequired !== undefined ? isRequired : false}>
        {showLabel !== false && <FormLabel sx={{ ...(styles !== undefined ? styles.label : commonStyles.formLabel), defaultFontSize, mt: -9 }}>{formLabel}</FormLabel>}
        <InputGroup gap={'1rem'} pos="relative">
          {showIcon !== false && (
            <InputLeftElement pointerEvents="none" h="full" left={'1rem'}>
              {Icon}
            </InputLeftElement>
          )}
          <Input
            sx={{
              ...(styles !== undefined ? styles.input : commonStyles.formInput),
              fontSize: isLargerThan991 ? defaultFontSize : 16,
              mt: isLargerThan991 ? -10 : undefined,
            }}
            type={type}
            name={name}
            placeholder={placeHolder}
            onChange={onChangeHandler}
            value={formValue}
          />
          {rightIcon !== undefined && (
            <InputRightElement h="full" right={'1rem'}>
              <Button onClick={rightIconClickhandler} background={'inherit'}>
                {rightIcon}
              </Button>
            </InputRightElement>
          )}
        </InputGroup>
        {error !== undefined && <Text sx={{ ...commonStyles.errorText, pos: 'absolute', bottom: '-30px' }}>{error}</Text>}
      </FormControl>
    </>
  )
}

export default DSInput
