import React from 'react'

const Download = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_55444_56681)">
        <path
          d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z"
          fill="#1C1B1F"
        />
      </g>
      <defs>
        <clipPath id="clip0_55444_56681">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Download
