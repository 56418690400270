import React from 'react'
import NoImage from '../../Assets/Images/NoImage.png'
import { Image } from '@chakra-ui/react'
import { IPropProductImage } from './ProductImage.types'
import { commonStyles } from '../../styles/commonStyles'

const ProductImage = ({
  imageUrl,
  styleHeight,
  style,
}: IPropProductImage): JSX.Element => {
  return (
    <Image
      src={imageUrl.includes('None') ? NoImage : imageUrl}
      alt="product"
      objectFit="contain"
      height={styleHeight != null ? styleHeight : 'auto'}
      width={style !== undefined ? style : commonStyles.imageWidthProducts}
      margin={'auto'}
    />
  )
}

export default ProductImage
