/* eslint-disable linebreak-style */
const CheckoutContextDefault = {
  largeScreen992: false,
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  businessId: null,
  setPreviousAddressSelected: () => {},
  notificationCartCount: 0,
  addressCount: 0,
  amount: undefined,
  previousAddressSelected: undefined,
  setUserLocation: () => {},
  formValue: undefined,
  setErrors: () => {},
  paymentMethod: 'card',
  setPaymentMethod: () => {},
  isButtonEnabled: false,
  formSuccess: '',
  formFail: '',
  shippingResult: undefined,
  showModal: false,
  handleResultClick: () => {},
  handleSubmit: async () => {},
  handleChange: () => {},
  handleCloseModal: () => {},
  handleFormClick: () => {},
  onCloseModal: () => {},
  showDropdown: false,
  handleInputFocus: () => {},
  regionList: [],
  textToCheck: '',
  setShowModal: () => {},
  sendButton: false,
  onDeliverSelectHandler: () => {},
  setQuery: () => {},
  query: '',
  userLocation: '',
  isDeliverChecked: false,
  formData: {
    is_default_address: true,
    first_name: '',
    last_name: '',
    address_line1: '',
    address_line2: '',
    phone_number1: '',
    phone_number2: '',
    additional_information: '',
    region: '',
  },
}

export default CheckoutContextDefault
