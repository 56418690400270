import React from 'react'
import NoInternet from '../../Assets/Images/NoInternet'
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { INoNetworkProp } from './NoNetwork.types'

const NoNetwork = ({ noNetwork, children }: INoNetworkProp): JSX.Element => {
  const [largeScreen992] = useMediaQuery(`(min-width: 992px)`)
  //
  if (!noNetwork)
    return (
      <Box w="100vw" h="100vh">
        <Flex
          height="100vh"
          gap={{ base: '4rem', lg: '6rem' }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          {largeScreen992 ? (
            <NoInternet />
          ) : (
            <NoInternet height={151} width={151} />
          )}
          <Flex
            gap={{ base: '10px', lg: '2rem' }}
            flexDirection="column"
            alignItems="center">
            <Heading fontSize={{ base: '20px', lg: '30px' }}>
              Hey, Looks like you&apos;re offline!{' '}
            </Heading>
            <Text>Please check your internet connection and try again.</Text>
          </Flex>
          <Button
            color={'secondary'}
            border={'1px solid #556AB0'}
            padding={'1rem 3rem'}
            borderRadius="20px">
            Try Connecting
          </Button>
        </Flex>
      </Box>
    )
  return children
}

export default NoNetwork
