export const Select = {
  parts: ['field', 'icon'],
  sizes: {
    md: {
      field: {
        h: { base: '3rem', lg: '4.5rem' },
        background: 'rgba(255, 251, 254, 0.12)',
        border: '1px solid #484649',
        borderRadius: '8px',
        Weight: 400,
        fontSize: { base: '13px', lg: '22px' },
        lineHeight: { base: '13px', lg: '40px' },
      },
    },
  },
}
