import React, { createContext, useEffect, useState } from 'react'
import { PharmacyProduct } from '../../components/DSCarousel/DSCarousel.types'
import { IPropsResult } from '../../components/ProductListing/ProductListing.types'
import { AuthContextProps } from '../Context.types'
import { ProductContextType } from './ProductContextType'
import ProductContextDefault from './ProductContextDefault'

export const ProductContext = createContext<ProductContextType>(ProductContextDefault)

const ProductContextProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [product, setProduct] = useState<IPropsResult | PharmacyProduct>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    const data = localStorage.getItem('product')
    if (data !== null) setProduct(JSON.parse(data))
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (product !== undefined) localStorage.setItem('product', JSON.stringify(product))
  }, [product])

  return <ProductContext.Provider value={{ product, setProduct, isLoading, setIsLoading }}>{children}</ProductContext.Provider>
}

export default ProductContextProvider
