import React from 'react'
import { Image } from '@chakra-ui/react'

const LockIcon = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  return <Image src="data:image/svg+xml,%3Csvg width='22' height='27' viewBox='0 0 22 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.9999 9.16683H17.6666V6.75016C17.6666 3.41516 14.6799 0.708496 10.9999 0.708496C7.31992 0.708496 4.33325 3.41516 4.33325 6.75016V9.16683H2.99992C1.53325 9.16683 0.333252 10.2543 0.333252 11.5835V23.6668C0.333252 24.996 1.53325 26.0835 2.99992 26.0835H18.9999C20.4666 26.0835 21.6666 24.996 21.6666 23.6668V11.5835C21.6666 10.2543 20.4666 9.16683 18.9999 9.16683ZM6.99992 6.75016C6.99992 4.74433 8.78658 3.12516 10.9999 3.12516C13.2133 3.12516 14.9999 4.74433 14.9999 6.75016V9.16683H6.99992V6.75016ZM17.6666 23.6668H4.33325C3.59992 23.6668 2.99992 23.1231 2.99992 22.4585V12.7918C2.99992 12.1272 3.59992 11.5835 4.33325 11.5835H17.6666C18.3999 11.5835 18.9999 12.1272 18.9999 12.7918V22.4585C18.9999 23.1231 18.3999 23.6668 17.6666 23.6668ZM10.9999 20.0418C12.4666 20.0418 13.6666 18.9543 13.6666 17.6252C13.6666 16.296 12.4666 15.2085 10.9999 15.2085C9.53325 15.2085 8.33325 16.296 8.33325 17.6252C8.33325 18.9543 9.53325 20.0418 10.9999 20.0418Z' fill='%23787579'/%3E%3C/svg%3E" boxSize={{ width, height }} alt="Lock Icon" sx={{ top: '-20px !important' }} className="custom-svg" />
}

export default LockIcon

LockIcon.defaultProps = {
  height: 27,
  width: 22,
}
