import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFound from './Pages/NotFoundPage/NotFound'
import { publicRoute } from './Routes/PublicRoutes'
import swrConfig from './config/swr.Config'
import { SWRConfig } from 'swr'
import { ACCOUNT, CHECKOUT } from './Routes/Routes'
import PrivateRoutes from './Routes/PrivateRoutes'
import AccountPage from './Pages/AccountPage/AccountPage'
import CartContextProvider from './Context/CartContext.Provider/CartContext'
import CheckoutPage from './Pages/CheckoutPage/CheckoutPage'
import AuthContextProvider from './Context/AuthContext.Provider/AuthContext'
import ProductContextProvider from './Context/ProductContext.Provider/ProductContext'
import GoToTop from './components/GoToTop/GoToTop'
import NoNetwork from './Layout/NoNetwork/NoNetwork'
import CustomScrollbar from './components/CustomScrollBar/CustomScrollBar'
import LocationContextProvider from './Context/LocationContext.Provider/LocationContext'
import CheckoutContextProvider from './Context/CheckoutContext.Provider/CheckoutContext'

function App(): JSX.Element {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true))
    window.addEventListener('offline', () => setIsOnline(false))
    return () => {
      window.removeEventListener('online', () => setIsOnline(true))
      window.removeEventListener('offline', () => setIsOnline(false))
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <NoNetwork noNetwork={isOnline}>
          <AuthContextProvider>
            <LocationContextProvider>
              <ProductContextProvider>
                <CartContextProvider>
                  <CheckoutContextProvider>
                    <CustomScrollbar>
                      <SWRConfig value={swrConfig}>
                        <GoToTop />
                        <Routes>
                          {publicRoute.map((route, index) => {
                            return <Route key={index} path={route.path} element={route.component} />
                          })}
                          <Route element={<PrivateRoutes />}>
                            <Route path={ACCOUNT} element={<AccountPage />} />
                            <Route path={CHECKOUT} element={<CheckoutPage />} />
                          </Route>
                          <Route path="*" element={<NotFound />} />
                        </Routes>
                      </SWRConfig>
                    </CustomScrollbar>
                  </CheckoutContextProvider>
                </CartContextProvider>
              </ProductContextProvider>
            </LocationContextProvider>
          </AuthContextProvider>
        </NoNetwork>
      </BrowserRouter>
    </>
  )
}

export default App
