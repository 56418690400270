import React from 'react'

const AddIcon = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_57092_66218)">
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#323232" />
      </g>
      <defs>
        <clipPath id="clip0_57092_66218">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddIcon

AddIcon.defaultProps = {
  height: 32,
  width: 32,
}
