import { endpoints } from '../../Content/Endpoints'
import { axiosAuthInstance } from '../../config/axios.config'
import { showToast } from '../Toaster/Toaster'
import { APIMessages } from '../../Content/Contants'
import swrConfig from '../../config/swr.Config'

export interface IPropsProductListing {
  data: IPropsProducts
  type: 'product' | 'Cart' | 'bill'
  mutate?: Function
  sepcificPharmacyProduct?: IPropsResult[]
  dataWithNoZeroQuantity?: IPropsResult[]
}

interface IPropsProducts {
  title: string
  content: string
  usage?: string
  countWithMG?: string
  img?: string
  count?: number
  next?: string
  previous?: string
  results?: IPropsResult[]
}

export interface IPropsResult {
  business: IPropBusiness
  id: number
  is_available: boolean
  price: number
  product: IPropsProduct
  quantity_in_stock: number
  quantity?: number
  unit_price?: number
  discounted_price?: number
  distance: number
}

interface IPropBusiness {
  id: number
  name: string
  logo: string | null
  email: string
  address: string
  phone: string
  year_established: string
  slug: string
  geo_coordinates: string
  location: string
  premise_license: string
  practice_license: string
  about: string | null
  is_verified: boolean
  created_at: string
}

interface IPropsProduct {
  SKU: string
  category: string
  composition: string[]
  desc: string
  id: number
  image: string
  manufacturer: string
  name: string
  quantity: number
  slug: string
}

interface IPropFormValue {
  product_id: number
  business_id: number
  quantity: number
}

export async function addProduct({
  payload,
  cartItem,
  localCartItems,
  setLocalCartItems,
  quantity,
  token,
}: {
  payload: IPropFormValue
  cartItem: IPropsResult
  localCartItems: IPropsResult[]
  setLocalCartItems: Function
  quantity: number
  token: string
}): Promise<IPropsResult | string> {
  if (token !== null && token !== '')
    return await axiosAuthInstance.post(endpoints.ADD_TO_CART, payload)
  else {
    const isItemAlreadyAdded =
      localCartItems.filter((item) => item.id === cartItem?.id)?.length > 0
    if (cartItem !== undefined && !isItemAlreadyAdded) {
      setLocalCartItems((prevCartItems: IPropsResult[]): IPropsResult[] => {
        const newCartItems = [...prevCartItems, { ...cartItem, quantity }]
        localStorage.setItem('cart', JSON.stringify(newCartItems))
        return newCartItems
      })
      return await Promise.resolve(APIMessages.ADD_TO_CART)
    } else return await Promise.reject(APIMessages.ADD_TO_CART_ERROR)
  }
}

export function deleteProduct({
  id,
  setLocalCartItems,
  localCartItems,
  setNotificationCartCount,
  mutate,
  token,
  setIsLoading,
  onClose,
  setQuantity,
}: {
  id: number
  setLocalCartItems: React.Dispatch<React.SetStateAction<IPropsResult[]>>
  localCartItems: IPropsResult[]
  setNotificationCartCount: React.Dispatch<React.SetStateAction<number>>
  mutate: Function
  token: string
  setIsLoading: (value: boolean) => void
  onClose: Function
  setQuantity: (value: number) => void
}): void {
  setIsLoading(true)
  if (token !== null && token !== '')
    axiosAuthInstance
      .delete(`${endpoints.CART}/${id}/delete`)
      .then(async () => {
        setNotificationCartCount((prevCount: number): number => prevCount - 1)
        showToast({
          title: 'Success!',
          description: APIMessages.DELETE_FROM_CART,
          status: 'success',
        })
        if (mutate !== undefined) {
          await mutate()
        }
      })
      .catch((err) =>
        showToast({
          title: 'Error!',
          description: err?.message,
          status: 'error',
        })
      )
      .finally(() => {
        setIsLoading(false)
        onClose()
        setQuantity(1)
      })
  else {
    if (localCartItems.length === 1) localStorage.removeItem('cart')
    else
      setLocalCartItems((prevCartItem: any) => {
        const newCartItems = prevCartItem.filter((item: any) => item.id !== id)
        localStorage.setItem('cart', JSON.stringify(newCartItems))
        return newCartItems
      })
    setNotificationCartCount((prevCount: number): number => prevCount - 1)
    showToast({
      title: 'Success!',
      description: APIMessages.DELETE_FROM_CART,
      status: 'success',
    })
    setIsLoading(false)
    onClose()
    setQuantity(1)
  }
}

export function updateProduct({
  setLocalCartItems,
  quantity,
  idToUpdate,
  mutate,
  token,
  setIsLoading,
  onClose,
  setQuantity,
}: {
  setLocalCartItems: Function
  quantity: number
  mutate: Function
  idToUpdate: number
  token: string
  setIsLoading: (value: boolean) => void
  onClose: Function
  setQuantity: (value: number) => void
}): void {
  setIsLoading(true)
  const { fetcher } = swrConfig
  if (token !== null && token !== '')
    axiosAuthInstance
      .patch(`${endpoints.CART}/${idToUpdate}`, {
        quantity,
      })
      .then(async () => {
        showToast({
          title: 'Success!',
          description: APIMessages.UPDATE_CART_ITEM,
          status: 'success',
        })
        if (mutate !== undefined) {
          const updatedCartListing = await fetcher(endpoints.CART, true)
          await mutate(updatedCartListing, false)
        }
      })
      .catch((err) =>
        showToast({
          title: 'Error!',
          description: err?.message,
          status: 'error',
        })
      )
      .finally(() => {
        setIsLoading(false)
        onClose()
        setQuantity(1)
      })
  else {
    setLocalCartItems((prevCartItem: IPropsResult[]): IPropsResult[] => {
      const itemIndex = prevCartItem.findIndex((item) => item.id === idToUpdate)
      prevCartItem[itemIndex].quantity = quantity
      localStorage.setItem('cart', JSON.stringify(prevCartItem))
      return prevCartItem
    })
    setIsLoading(false)
    onClose()
    setQuantity(1)
  }
}

export async function addMultipleItems({
  cartData,
  setNotificationCartCount,
}: {
  cartData: string
  setNotificationCartCount: React.Dispatch<React.SetStateAction<number>>
}): Promise<void> {
  const Products: IPropsResult[] = JSON.parse(cartData)

  for (const product of Products) {
    try {
      await axiosAuthInstance.post(endpoints.ADD_TO_CART, {
        product_id: product?.id,
        business_id: product?.business.id,
        quantity: product?.quantity,
      })
      setNotificationCartCount((prevCount: number): number => prevCount + 1)
    } catch (err) {}
  }
}
