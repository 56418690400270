/* eslint-disable linebreak-style */
/* eslint-disable indent */
import React, { useContext } from 'react'

import { Box, Button, Checkbox, Flex, FormLabel, Heading, Input, InputGroup, InputLeftElement, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'

import {
  commonStyles,
  exitingAddressButton,
  linkButton,
  // locationInput,
  radioStyles,
} from '../../styles/commonStyles'
import MasterCard from '../../Assets/MasterCard'
import Paypal from '../../Assets/Paypal'
import Visa from '../../Assets/Visa'
import Zapper from '../../Assets/Zapper'
import { Link } from 'react-router-dom'
import { CART } from '../../Routes/Routes'
import PaystackButton from './PaystackButton'
import LocationIcon from '../../Assets/Images/LocationIcon'
import ArrowDown from '../../Assets/Images/ArrowDown'
import { CheckoutContext } from '../../Context/CheckoutContext.Provider/CheckoutContext'

interface ShippingAddress {
  address_line1: string
  region: string
}

const Checkout = (): JSX.Element => {
  const { largeScreen992, onOpen, shippingResult, addressCount, businessId, notificationCartCount, amount, previousAddressSelected, setUserLocation, formValue, setErrors, paymentMethod, setPaymentMethod, isButtonEnabled, onDeliverSelectHandler, isDeliverChecked } = useContext(CheckoutContext)

  const defaultAddress = shippingResult?.find((item: any) => {
    return item.is_default === true
  }) as ShippingAddress | undefined

  const currentAddress = defaultAddress !== undefined ? `${defaultAddress?.address_line1} — ${defaultAddress?.region}` : 'No Address'

  return (
    <Flex p={'0 1.5rem'} my={commonStyles.spacingFromHeader} mx="auto" maxW={commonStyles.containerMaxWidth} justifyContent="space-between" alignItems="center" flexDirection={{ base: 'column', lg: 'row' }}>
      <Flex gap={{ base: '3rem', lg: '5rem' }} flexDirection={'column'}>
        <Box flex="1">
          <Heading sx={commonStyles.Font40Checkout}>Checkout</Heading>
          <Text sx={commonStyles.font16}>
            There {notificationCartCount === 1 ? 'is' : 'are'} {notificationCartCount} {notificationCartCount === 1 ? 'product' : 'products'} in your cart.
            {!largeScreen992 && <br />}
            <Link to={CART} style={linkButton}>
              View products
            </Link>
          </Text>
        </Box>
        <Box flex="1">
          <Stack direction={'row'} gap={{ base: '1rem', lg: '5rem' }}>
            <FormLabel htmlFor="checkbox">
              <Heading sx={commonStyles.checkoutHeadingDeliver}>Deliver to me</Heading>
              <Text sx={commonStyles.checkoutTextDeliver} mt={{ base: '0.5rem', lg: '1rem' }} width={{ base: '220px', lg: '412px' }}>
                would like the order delivered to the my delivery address. <span style={{ fontWeight: '500' }}>Delivery charges apply.</span>
              </Text>
            </FormLabel>
            <Checkbox color={'red'} size={{ base: 'sm', lg: 'lg' }} id="checkbox" isChecked={isDeliverChecked} onChange={onDeliverSelectHandler} />
          </Stack>
          {(shippingResult?.length > 0 || addressCount > 0) && (
            <Button mt={'1rem'} color={'black'} rightIcon={<ArrowDown />} onClick={onOpen} sx={exitingAddressButton}>
              Use existing address
            </Button>
          )}
          {(addressCount > 0 || shippingResult?.length > 0) && (
            <Box>
              <Text sx={commonStyles.text14_22} mb="1rem">
                Delivery address
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none" width={largeScreen992 ? '28px' : '20px'}>
                  {largeScreen992 ? <LocationIcon height={28} width={28} fill="#5EA3D6" /> : <LocationIcon height={20} width={20} fill="#5EA3D6" />}
                </InputLeftElement>
                <Input
                  sx={{ width: 'auto' }}
                  placeholder="Enter an address or ZIP code"
                  // sx={locationInput}
                  type="button"
                  value={currentAddress}
                  onChange={(e) => setUserLocation(e.target.value)}
                />
              </InputGroup>
            </Box>
          )}
          <Stack direction={'row'} gap={{ base: '1rem', lg: '5rem' }} mt={'4rem'}>
            <FormLabel htmlFor="checkbox">
              <Heading sx={commonStyles.checkoutHeadingDeliver}>Picking up Item</Heading>
              <Text sx={commonStyles.checkoutTextDeliver} mt={{ base: '0.5rem', lg: '1rem' }} width={{ base: '220px', lg: '412px' }}>
                would like to go pick up item(s) at pharamcy.
              </Text>
            </FormLabel>
            <Checkbox
              color={'red'}
              size={{ base: 'sm', lg: 'lg' }}
              id="checkbox"
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              isChecked={!isDeliverChecked}
              onChange={onDeliverSelectHandler}
            />
          </Stack>
        </Box>
      </Flex>

      <Box>
        <Flex gap={{ base: '3rem', lg: '7rem' }} flexDirection={{ base: 'column', lg: 'row' }}>
          <Box flex={2} mt="1.5rem">
            <Box
              sx={
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                isDeliverChecked ? commonStyles.paymentGetwayContainer : commonStyles.ButttonTakeFromStore
              }>
              {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                isDeliverChecked && <Heading sx={commonStyles.checkoutHeading}>Payment</Heading>
              }
              {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                isDeliverChecked && (
                  <RadioGroup onChange={setPaymentMethod} value={paymentMethod}>
                    <Stack direction="column" gap={'1rem'}>
                      <Radio value="card">
                        <Text sx={radioStyles} color={paymentMethod === 'card' ? '#253D4E' : '#7E7E7E'}>
                          Card
                        </Text>
                      </Radio>
                      <Radio value="bank_transfer">
                        <Text sx={radioStyles} color={paymentMethod === 'bank_transfer' ? '#253D4E' : '#7E7E7E'}>
                          Direct bank transfer
                        </Text>
                      </Radio>
                      <Radio value="ussd">
                        <Text sx={radioStyles} color={paymentMethod === 'ussd' ? '#253D4E' : '#7E7E7E'}>
                          USSD
                        </Text>
                      </Radio>
                      <Radio value="cash">
                        <Text sx={radioStyles} color={paymentMethod === 'cash' ? '#253D4E' : '#7E7E7E'}>
                          Cash on pickup
                        </Text>
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                isDeliverChecked && (
                  <Flex gap={'1rem'} margin="auto">
                    {largeScreen992 ? <Paypal /> : <Paypal width={59} height={14} />}
                    {largeScreen992 ? <Visa /> : <Visa width={43} height={14} />}
                    {largeScreen992 ? <MasterCard /> : <MasterCard width={19} height={14} />}
                    {largeScreen992 ? <Zapper /> : <Zapper width={58} height={14} />}
                  </Flex>
                )
              }
              <PaystackButton amount={amount} businessId={businessId} formValue={formValue ?? previousAddressSelected} isDeliverChecked={isDeliverChecked} paymentMethod={paymentMethod} isButtonEnabled={isButtonEnabled} addressId={previousAddressSelected?.id} setErrors={setErrors} />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Checkout
