import React from 'react'

const NoInternet = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 151 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_58586_76800)">
        <path
          d="M142.107 9.12326C130.224 -2.76082 110.987 -2.76259 99.1012 9.12326L75.5104 32.714L51.9197 9.12296C40.0628 -2.73336 20.7702 -2.73336 8.91361 9.12355C-2.97047 21.0073 -2.97194 40.2438 8.91361 52.1297L35.636 78.8517C37.3652 80.5813 40.1694 80.5815 41.8989 78.8517L75.5104 45.2402L109.122 78.8517C110.851 80.5813 113.656 80.5815 115.385 78.8517L142.108 52.1297C153.964 40.2725 153.964 20.9802 142.107 9.12326V9.12326Z"
          fill="#514F6D"
        />
        <path
          d="M66.0639 112.999C73.7276 112.999 79.9402 106.786 79.9402 99.1225C79.9402 91.4588 73.7276 85.2461 66.0639 85.2461C58.4002 85.2461 52.1875 91.4588 52.1875 99.1225C52.1875 106.786 58.4002 112.999 66.0639 112.999Z"
          fill="#514F6D"
        />
        <path
          d="M99.1303 136.618C106.794 136.618 113.007 130.406 113.007 122.742C113.007 115.078 106.794 108.865 99.1303 108.865C91.4666 108.865 85.2539 115.078 85.2539 122.742C85.2539 130.406 91.4666 136.618 99.1303 136.618Z"
          fill="#514F6D"
        />
        <path
          d="M64.1871 72.0665C65.0129 74.6005 67.5097 75.6699 69.711 75.0159C72.2463 74.1869 73.316 71.6892 72.6605 69.492C71.7801 66.7878 68.9567 65.7504 66.7291 66.6901C64.6323 67.5758 63.5257 69.8504 64.1871 72.0665V72.0665Z"
          fill="#514F6D"
        />
        <path
          d="M87.8087 83.8735C88.6185 86.4034 91.1544 87.4864 93.3297 86.8259C95.8564 86.017 96.9399 83.4994 96.2821 81.302C95.4082 78.6085 92.5869 77.5565 90.3507 78.5001C88.1966 79.4089 87.16 81.7369 87.8087 83.8735Z"
          fill="#514F6D"
        />
        <path
          d="M120.006 145.496C119.451 142.846 116.829 141.393 114.376 142.124C111.858 142.945 110.768 145.431 111.427 147.648C112.259 150.196 114.781 151.256 116.948 150.598C119.248 149.862 120.415 147.678 120.006 145.496Z"
          fill="#514F6D"
        />
        <path
          d="M86.9376 145.496C86.3881 142.874 83.7926 141.383 81.3073 142.124C79.0148 142.823 77.6434 145.243 78.3578 147.648C79.0711 149.981 81.5349 151.31 83.8789 150.598C86.1741 149.863 87.3468 147.683 86.9376 145.496Z"
          fill="#514F6D"
        />
        <path
          d="M72.7665 126.601C72.2229 123.994 69.6322 122.485 67.1362 123.229C64.6066 124.053 63.5245 126.574 64.1867 128.75C64.9786 131.231 67.4621 132.37 69.7107 131.702C72.0484 130.938 73.1683 128.747 72.7665 126.601Z"
          fill="#514F6D"
        />
        <path
          d="M47.9371 123.512C49.6661 121.731 49.6676 119.03 47.9371 117.247C45.9561 115.324 42.9644 115.631 41.3798 117.572C39.7896 119.523 40.167 122.311 41.9969 123.805C43.7096 125.201 46.2215 125.178 47.9371 123.512V123.512Z"
          fill="#514F6D"
        />
        <path
          d="M56.2329 145.496C55.6787 142.839 53.028 141.386 50.6056 142.124C48.1114 142.92 46.988 145.417 47.6532 147.648C48.4792 150.183 50.9755 151.252 53.1771 150.598C55.5146 149.834 56.6347 147.643 56.2329 145.496Z"
          fill="#514F6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_58586_76800">
          <rect width="151" height="151" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NoInternet

NoInternet.defaultProps = {
  height: 250,
  width: 250,
}
