export const minWidth = `(min-width: 1400px)`

export const itemsPerPage = 10

export const avarageSpeed = 45

export const voucherExpireDays = 3
export const scaleQualityPDF = 3
export const pageWidth = 210 // A4 width in mm
export const margin = 25 // margin in mm
export const downloadPDFFilename = 'drugStoc-Invoice.pdf'
export const defaultAvatar = 'https://bit.ly/broken-link'

export const stateOptions = [
  { id: 'lagos', value: 'Lagos' },
  { id: 'ogun', value: 'Ogun' },
  { id: 'oyo', value: 'Oyo' },
  { id: 'cross-rivers', value: 'Cross Rivers' },
  { id: 'others', value: 'Others' },
]

export const InputName = {
  EMAIL: 'email',
  PASSWORD: 'password',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  PHONE: 'phone',
  CONFIRM_PASSWORD: 'confirm_password',
  ADDRESS1: 'address_line1',
  ADDRESS2: 'address_line2',
  PHONE_CHECKOUT: 'phone_number1',
  ADDITIONAL_INFORMATION: 'additional_information',
  CITY: 'city',
  REGION: 'region',
}

export const ErrorMessages = {
  EMAIL: 'Email is invalid',
  PASSWORD: 'Password must be 8 characters',
  NAME: 'Name is invalid',
  PHONE: 'Phone number is invalid',
  CONFIRM_PASSWORD: 'Confirm password not matched with password',
  DELETE_MSG: 'Are you sure you want to delete this product from cart?',
  MAXIMUM_QUANTITY: 'Maximum quantity reached*',
}

export const APIMessages = {
  LOGIN_SUCCESS: 'Logged in successfully!!',
  SIGNUP_SUCESS: "We've created your account for you.",
  FORGET_PASSWORD_API_SUCCESS:
    'An email with reset link is send to your mail!!',
  NEW_PASSWORD_SET_SUCCESS: 'Password updated succesfully',
  ADD_TO_CART: 'Item successfully added to cart',
  ADD_TO_CART_ERROR: 'Item already exist in cart',
  DELETE_FROM_CART: 'Item successfully removed from cart',
  DELETE_ALL_FROM_CART: 'All Items successfully removed from cart',
  UPDATE_CART_ITEM: 'Item Updated successfully',
  SALES_ORDER_CREATE: 'Successfully placed order',
  ON_CLOSE_PAYMENT: 'Wait! Get your medicine fast!!!!',
  ON_SUCCESS_PAYMENT: 'Thanks for doing business with us! Come back soon!!',
  SUCCESS_PDF_DOWNLOAD: 'Successfully donwload pdf',
  FAILED_PDF_DOWNLOAD: 'Failed to download as pdf',
  Location_Fetch_Failed_Title: 'Invalid location',
  Location_Fetch_Failed_Message: 'Please try another search query',
}

export const FAQs = [
  {
    heading: 'Who can use TPN? ',
    text: 'TPN can be used by anyone (Healthcare Facilities and Retail Customers) for easy access to medications when needed.',
  },
  {
    heading: 'How do I sign in?  ',
    text: 'Sign in on either the web or mobile app platform with an email address and password. Ensure your password or email credential is valid. If not sure, do reach out to our customer service to assist you in whatever issue you may be experiencing signing in. (provide a customer service number or medium to get in touch or a link to click to WhatsApp or so)',
  },
  {
    heading: 'What are the requirements for signup?  ',
    text: 'You require your full name, email, password, and phone number.',
  },
  {
    heading: 'Are there any hidden charges on checkout?  ',
    text: 'No',
  },
  {
    heading:
      'I can’t seem to find the product I am looking for, what do I do?  ',
    text: 'Request via our live chat or call customer service for the requested product.',
  },
  {
    heading: 'Is there any receipt issued after I make a purchase?  ',
    text: 'Yes, the receipt is generated automatically after checkout and can be downloaded.',
  },
  {
    heading: 'How do I receive my goods?  ',
    text: 'Items can either be delivered or picked up at the pharmacy depending on the delivery method selected during checkout.',
  },
  {
    heading: 'Do you have pickup centers, or do you do home delivery?  ',
    text: 'We do have fulfillment centers you can pick up from. Kindly reach out to customer service for more information. Furthermore, we do home deliveries.',
  },
  {
    heading: 'How do I check out orders from my cart?  ',
    text: 'Checking out orders from the cart is pretty simple. Click on the button that says “checkout” and you will be routed to a new page called checkout page then click on the “Create order” button and follow the prompt that comes next. ',
  },
]

export const billType = [
  {
    type: 'Delivery',
    price: '0',
  },
  {
    type: 'Discount',
    price: '0',
  },
  {
    type: 'VAT',
    price: '0',
  },
]
