import { Box, Flex, Heading, Text, Button, Image, useMediaQuery } from '@chakra-ui/react'
import React, { useState, useEffect, useContext } from 'react'
import { authForgetPassword, IAuthForgetPassword, IPropsForgetPasswordPage } from './ForgetPassworPage.types'
import LoginSide from '../../Assets/Images/LoginSide.svg'
import { commonStyles } from '../../styles/commonStyles'
import MailIcon from '../../Assets/Images/MailIcon'
import DSInput from '../../components/DSInput/DSInput'
import { APIMessages, InputName, minWidth } from '../../Content/Contants'
import { endpoints } from '../../Content/Endpoints'
import { AxiosError } from 'axios'
import { showToast } from '../../components/Toaster/Toaster'
import { Header } from '../../Layout/Header/Header'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import imageCover from '../../Assets/Images/ImageCover.png'
import { Link } from 'react-router-dom'
import arrow from '../../Assets/arrow.svg'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'

const ForgetPassworPage = ({ props }: IPropsForgetPasswordPage): JSX.Element => {
  const [largeScreen992] = useMediaQuery(minWidth)

  const [formValue, setFormValue] = useState<IAuthForgetPassword>({
    email: '',
  })

  const [errors, setErrors] = useState<IAuthForgetPassword>()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const [isLargeScreen] = useMediaQuery('(min-width: 1057px)')
  const { defaultFontSize } = useContext(AuthContext)

  useEffect(() => {
    setIsButtonEnabled(errors?.email === '')
  }, [errors])

  const onSubmitHandler = (event: any): void => {
    event.preventDefault()
    authForgetPassword(endpoints.FORGET_PASSWORD_ROUTE, formValue, (result) => {
      if (result instanceof AxiosError) {
        const { response } = result
        if (response?.status === 400) {
          const message = Object.values(response.data)?.[0]?.[0]
          showToast({
            title: 'Error!',
            description: message,
            status: 'error',
          })
        } else {
          showToast({
            title: 'Error!',
            description: result?.message,
            status: 'error',
          })
        }
      } else {
        showToast({
          title: 'Success!',
          description: APIMessages.FORGET_PASSWORD_API_SUCCESS,
          status: 'success',
        })
      }
    })
  }

  return (
    <Box>
      <Header headerFor="AUTH" />
      <MobileHeader headerFor="AUTH" />
      <Box sx={{ display: 'flex', justifyContent: isLargeScreen ? 'space-between' : 'center' }}>
        <Box
          sx={{
            display: isLargeScreen ? 'flex' : 'none',
            alignItems: 'stretch',
          }}>
          <Image
            src={imageCover}
            alt=""
            sx={{
              flex: 1,
              display: 'flex',
              height: '100%',
              position: 'fixed',
              mw: '25%',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </Box>
        <Box sx={{ width: isLargeScreen ? '75%' : '90%' }}>
          <Flex mt={'4rem'}>
            {largeScreen992 && (
              <Box flex={3}>
                <Image src={LoginSide} alt="sidebar img" position={'fixed'} height={'100%'} />
              </Box>
            )}
            <Box flex={7} p={commonStyles.ProductHeaderDisplay}>
              <Link to="/login">
                <Image sx={{ cursor: 'pointer' }} width="24px" src={arrow} alt="arrow icon" />
              </Link>
              <Heading sx={commonStyles.font40}>Forgot Password?</Heading>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={defaultFontSize}>
                Enter your email address to recovery your password.
              </Text>
              <Flex mt={commonStyles.margin3} sx={commonStyles.FormContainer}>
                <DSInput formLabel="Enter your email address" type="email" placeHolder="example@mail.com" Icon={largeScreen992 ? <MailIcon /> : <MailIcon width={20} height={16} />} name={InputName.EMAIL} setFormValue={setFormValue} error={errors?.email} setErrors={setErrors} isRequired={true} />
              </Flex>
              <Flex sx={commonStyles.loginBottom}>
                <style>
                  {`
                     @media (max-width: 991px) {
                      .forgotPasswordButton {
                        margin-top:-40px;
                      }
                     } 
                    `}
                </style>
                <Box className="forgotPasswordButton" width={'100%'} mt="-120px">
                  <Button sx={{ ...commonStyles.buttonFullWidth, height: 50, fontSize: 16 }} onClick={onSubmitHandler} isDisabled={!isButtonEnabled}>
                    Next
                  </Button>
                </Box>
                <Box>
                  <Text sx={{ ...commonStyles.font20, fontSize: 14 }}>
                    By continuing, you agree to our
                    <span style={commonStyles.blueColor}> Terms</span> and <span style={commonStyles.blueColor}>Privacy Policy.</span>
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default ForgetPassworPage
