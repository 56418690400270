export const ButtonStyles = {
  baseStyle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: { base: '16px', lg: '22px' },
    lineHeight: { base: '32px', lg: '40px' },
  },
  sizes: {},
  variants: {
    primary: {
      boxSizing: 'border-box',
      background: 'primary',
      color: 'white',
      borderRadius: '8px',
    },
    secondary: {
      background: '#F9F9FC',
      border: '1px solid #E6E1E5',
      borderRadius: '8px',
    },
    buttonBorder: {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '35px',
      color: '#000000',
      border: '1px solid #514F6D',
      width: '136px',
    },
    mobileSecondary: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '32px',
      color: '#514F6D',
      border: '1px solid #514F6D',
      width: '136px',
    },
    mediumButton: {
      fontSize: '25px',
      lineHeight: '40px',
      borderRadius: '8px',
      padding: '1rem 3rem',
    },
    mediumMobileButton: {
      fontSize: '13px',
      lineHeight: '20px',
      borderRadius: '8px',
      padding: '0.5rem 1.5rem',
    },
  },
  defaultProps: {
    variant: 'primary',
    size: 'primary',
  },
}
