import React from 'react'

const Delete = ({
  height,
  width,
  fill,
}: {
  height: number
  width: number
  fill: string
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0007 4.5V5.83333H26.6673V8.5H25.334V25.8333C25.334 27.3 24.134 28.5 22.6673 28.5H9.33398C7.86732 28.5 6.66732 27.3 6.66732 25.8333V8.5H5.33398V5.83333H12.0007V4.5H20.0007ZM9.33398 25.8333H22.6673V8.5H9.33398V25.8333ZM12.0007 11.1667H14.6673V23.1667H12.0007V11.1667ZM20.0007 11.1667H17.334V23.1667H20.0007V11.1667Z"
        fill={fill}
      />
    </svg>
  )
}

export default Delete

Delete.defaultProps = {
  height: 33,
  width: 32,
  fill: '#1F1C19',
}
