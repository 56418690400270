import React, { useContext, useEffect, useState } from 'react'
import { Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useMediaQuery } from '@chakra-ui/react'
import { IPropsSignupModal } from './SignupModal.types'
import DSInput from '../DSInput/DSInput'
import Person from '../../Assets/Images/Person'
import { commonStyles } from '../../styles/commonStyles'
import LockIcon from '../../Assets/Images/LockIcon'
import MailIcon from '../../Assets/Images/MailIcon'
import PhoneIcon from '../../Assets/Images/PhoneIcon'
import { useNavigate } from 'react-router-dom'
import { CHECKOUT } from '../../Routes/Routes'
import RightSidePasswordIcon from '../RightSidePasswordIcon/RightSidePasswordIcon'
import { authLogin, IAuthLogin } from '../../Pages/LoginPage/LoginPage.types'
import { endpoints } from '../../Content/Endpoints'
import { showToast } from '../Toaster/Toaster'
import { APIMessages, InputName } from '../../Content/Contants'
import { AxiosError } from 'axios'
import { authRegisterCreate, IAuthCreate } from '../../Pages/SignUpPage/SignUpPage.types'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { addMultipleItems } from '../ProductListing/ProductListing.types'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'

function SignupModal({ isOpen, onClose, price, businessId }: IPropsSignupModal): JSX.Element {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext)
  const { setNotificationCartCount } = useContext(CartContext)

  const [isLoginUI, setisLoginUI] = useState(true)
  const [isSkip] = useState(false)

  const [formValue, setFormValue] = useState<IAuthLogin>({
    email: '',
    password: '',
    confirm_password: '',
  })

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [errors, setErrors] = useState<IAuthLogin>()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  // signup
  const [formValueSignUp, setFormValueSignUp] = useState<IAuthCreate>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
  })
  const [errorsSignup, setErrorsSignup] = useState<IAuthCreate>()

  useEffect(() => {
    isLoginUI && setIsButtonEnabled(errors?.email === '' && errors?.password === '')
    !isLoginUI && setIsButtonEnabled(errorsSignup?.first_name === '' && errorsSignup?.last_name === '' && errorsSignup?.email === '' && errorsSignup?.phone === '' && errorsSignup?.password === '')
  }, [errors, errorsSignup, isLoginUI])

  const cartData = localStorage.getItem('cart')

  const onSubmitHandler = (event: any): void => {
    event.preventDefault()
    isLoginUI &&
      authLogin(endpoints.LOGIN_ROUTE, formValue, (result) => {
        if (result instanceof AxiosError) {
          const { response } = result
          if (response?.status === 400) {
            const message = Object.values(response.data)?.[0]?.[0]
            showToast({
              title: 'Error!',
              description: message,
              status: 'error',
            })
          } else {
            showToast({
              title: 'Error!',
              description: result?.message,
              status: 'error',
            })
          }
        } else {
          showToast({
            title: 'Success!',
            description: APIMessages.LOGIN_SUCCESS,
            status: 'success',
          })
          setToken(result.data.token)
          localStorage.setItem('token', result.data.token)
          if (cartData !== null) {
            addMultipleItems({
              cartData,
              setNotificationCartCount,
            })
              .then(() => {
                localStorage.removeItem('cart')
                navigate(`${CHECKOUT}?businessId=${businessId}`, {
                  state: {
                    amount: price,
                  },
                })
              })
              .catch(() => {
                onClose()
              })
          }
        }
      })
    !isLoginUI &&
      authRegisterCreate(endpoints.SIGNUP_ROUTE, formValueSignUp, (result) => {
        if (result instanceof AxiosError) {
          const { response } = result
          if (response?.status === 400) {
            const message = Object.values(response.data)?.[0]?.[0]
            showToast({
              title: 'Error!',
              description: message,
              status: 'error',
            })
          } else {
            showToast({
              title: 'Error!',
              description: result?.message,
              status: 'error',
            })
          }
        } else {
          showToast({
            title: 'Success!',
            description: APIMessages.SIGNUP_SUCESS,
            status: 'success',
          })
          setToken(result.data.token)
          localStorage.setItem('token', result.data.token)
          if (cartData !== null) {
            addMultipleItems({
              cartData,
              setNotificationCartCount,
            })
              .then(() => {
                localStorage.removeItem('cart')
                navigate(`${CHECKOUT}?businessId=${businessId}`, {
                  state: {
                    amount: price,
                  },
                })
              })
              .catch(() => {
                onClose()
              })
          }
        }
      })
  }

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={{ base: '19rem', lg: '50rem' }} p={3} pt={10}>
          <ModalHeader>
            <Heading variant={{ base: 'mobileBold20', lg: 'Bold' }} color={'#1C1B1F'}>
              {isLoginUI && !isSkip && 'Already have an account to continue with?'}
              {!isLoginUI && !isSkip && 'Setup an account to experience more!'}
              {isSkip && 'Provide detail'}
            </Heading>
            <ModalCloseButton size={{ base: 'md', lg: 'lg' }} top={{ base: '1.5rem', lg: '2rem' }} right={{ base: '1.5rem', lg: '2.5rem' }} />
          </ModalHeader>
          <ModalBody pb={6}>
            {(!isLoginUI || isSkip) && (
              <DSInput
                formLabel="Enter your first name:"
                type="text"
                placeHolder="First name"
                Icon={largeScreen992 ? <Person /> : <Person height={16} width={16} />}
                styles={{
                  label: commonStyles.labelModal,
                  input: commonStyles.inputModal,
                }}
                setFormValue={setFormValueSignUp}
                name={InputName.FIRST_NAME}
                error={errorsSignup?.first_name}
                setErrors={setErrorsSignup}
                isRequired={true}
              />
            )}
            {!isLoginUI && !isSkip && (
              <DSInput
                formLabel="Enter your last name:"
                type="text"
                placeHolder="Last name"
                Icon={largeScreen992 ? <Person /> : <Person height={16} width={16} />}
                styles={{
                  label: commonStyles.labelModal,
                  input: commonStyles.inputModal,
                }}
                setFormValue={setFormValueSignUp}
                name={InputName.LAST_NAME}
                error={errorsSignup?.last_name}
                setErrors={setErrorsSignup}
                isRequired={true}
              />
            )}
            {(!isLoginUI || isSkip) && (
              <DSInput
                formLabel="Enter a phone number to text this voucher to:"
                type="text"
                placeHolder="Phone number"
                Icon={largeScreen992 ? <PhoneIcon /> : <PhoneIcon height={16} width={16} />}
                styles={{
                  label: commonStyles.labelModal,
                  input: commonStyles.inputModal,
                }}
                setFormValue={setFormValueSignUp}
                name={InputName.PHONE}
                error={errorsSignup?.phone}
                setErrors={setErrorsSignup}
                isRequired={true}
              />
            )}
            {!isSkip && (
              <DSInput
                formLabel="Enter a email to text this voucher to:"
                type="email"
                placeHolder="Email"
                name={InputName.EMAIL}
                Icon={largeScreen992 ? <MailIcon /> : <MailIcon height={16} width={16} />}
                styles={{
                  label: commonStyles.labelModal,
                  input: commonStyles.inputModal,
                }}
                setFormValue={isLoginUI ? setFormValue : setFormValueSignUp}
                error={isLoginUI ? errors?.email : errorsSignup?.email}
                setErrors={isLoginUI ? setErrors : setErrorsSignup}
                isRequired={true}
              />
            )}
            {!isSkip && (
              <DSInput
                formLabel="Enter a password to text this voucher to:"
                type={showPassword ? 'text' : 'password'}
                placeHolder="8 characters or more"
                name={InputName.PASSWORD}
                Icon={largeScreen992 ? <LockIcon /> : <LockIcon height={16} width={16} />}
                styles={{
                  label: commonStyles.labelModal,
                  input: commonStyles.inputModal,
                }}
                rightIcon={<RightSidePasswordIcon showPassword={showPassword} />}
                rightIconClickhandler={() => {
                  setShowPassword(!showPassword)
                }}
                setFormValue={isLoginUI ? setFormValue : setFormValueSignUp}
                error={isLoginUI ? errors?.password : errorsSignup?.password}
                setErrors={isLoginUI ? setErrors : setErrorsSignup}
                isRequired={true}
              />
            )}
            <Text sx={commonStyles.modalContent}>
              By claiming your voucher, you agree to the <span style={commonStyles.blueColor}>Terms of Use </span>and
              <span style={commonStyles.blueColor}> Policy</span> and consent to receive marketing texts from or on behalf of TPN at any number you provide us.
            </Text>
            {!isSkip && (
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} mt={'3rem'}>
                {isLoginUI ? 'Don’t have an account? ' : 'Already have an account?'}
                <span
                  style={commonStyles.blueColor}
                  onClick={() => {
                    window.scrollTo(0, 0)
                    setisLoginUI((prev) => !prev)
                  }}>
                  {'   '}
                  {isLoginUI ? 'Create an ccount' : 'Log in'}
                </span>
              </Text>
            )}
          </ModalBody>

          <ModalFooter flexDirection={{ base: 'column', lg: 'row' }} gap={'1rem'}>
            {/* <Button
              onClick={() => {
                setIsSkip(true)
              }}
              variant={commonStyles.locationModalFooterButtonVariant}
              backgroundColor={'white'}
              color="black"
              border={'1px solid #E6E1E5'}
              width={'100%'}
              mr={commonStyles.locationModalR}>
              Skip for now
            </Button> */}
            <Button
              isDisabled={!isButtonEnabled}
              variant={commonStyles.locationModalFooterButtonVariant}
              sx={{
                '&:hover[disabled]': {
                  background: 'secondary',
                },
              }}
              backgroundColor={'secondary'}
              color="white"
              // width={'100%'}
              width={'18rem'}
              onClick={onSubmitHandler}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SignupModal
