import React, { useContext, useEffect, useState } from 'react'
import { Box, Heading, Text, Button, Flex, useMediaQuery, useDisclosure } from '@chakra-ui/react'
import ProductImage from '../ProductImage/ProductImage'
import EditProductModal from '../EditProductModal/EditProductModal'
import Cart from '../../Assets/Images/Cart'
import { centerHeading, commonStyles, font20Center, font32Center } from '../../styles/commonStyles'
import { addProduct, IPropsResult } from '../ProductListing/ProductListing.types'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import { IproductDetails } from './Product.types'
import SearchIcon from '../../Assets/Images/SearchIcon'
import { useNavigate } from 'react-router-dom'
import LocationIcon from '../../Assets/Images/LocationIcon'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { APIMessages, avarageSpeed } from '../../Content/Contants'
import { showToast } from '../Toaster/Toaster'
import { convertHoursToDaysHoursMinutes } from '../../utils/getTime'

const Product = ({ productDetails, isProductAdded, setIsProductAdded }: IproductDetails): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')

  const [quantity, setQuantity] = useState<number>(1)
  const [quantityInStock, setQuantityInStock] = useState<number>(1)

  const [cartItem, setCartItem] = useState<IPropsResult>()
  const [localCartItems, setLocalCartItems] = useState<IPropsResult[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { notificationCartCount, setNotificationCartCount } = useContext(CartContext)
  const { token } = useContext(AuthContext)

  const onModalOpenHandler = (productItems: any): void => {
    onOpen()
    setQuantityInStock(productItems?.quantity_in_stock)
    setCartItem(productItems)
  }

  useEffect(() => {
    const cartData = localStorage.getItem('cart')
    if (cartData !== null) setLocalCartItems(JSON.parse(cartData))
  }, [])

  const addProductHandler = (): void => {
    const payload = {
      product_id: cartItem?.id ?? 1,
      business_id: cartItem?.business.id ?? 1,
      quantity,
    }
    setIsLoading(true)
    if (cartItem !== undefined)
      addProduct({
        payload,
        cartItem,
        localCartItems,
        quantity,
        setLocalCartItems,
        token,
      })
        .then(() => {
          setIsProductAdded(true)
          setIsLoading(false)

          setNotificationCartCount(notificationCartCount + 1)
          showToast({
            title: 'Success!',
            description: APIMessages.ADD_TO_CART,
            status: 'success',
          })
        })
        .catch((err) => {
          setIsLoading(false)
          setIsProductAdded(true)
          if (err?.response === undefined)
            showToast({
              title: 'Error!',
              description: APIMessages.ADD_TO_CART_ERROR,
              status: 'error',
            })
          else {
            const messageArray = Object.values<string[]>(err?.response?.data)?.[0]
            const message: string = messageArray[0]
            showToast({
              title: 'Error!',
              description: message,
              status: 'error',
            })
          }
        })
        .finally(() => {
          setQuantity(1)
          onClose()
        })
  }

  const showDiscountedPrice: boolean = productDetails?.discounted_price !== undefined && productDetails?.discounted_price !== productDetails?.price && productDetails?.discounted_price !== 0

  return (
    <Box maxW={commonStyles.containerMaxWidth} mx={'auto'} mt={commonStyles.spacingFromHeader} mb={{ lg: '7rem' }}>
      <Flex gap={{ base: '1rem', lg: '4.5rem' }} alignItems={'center'} justifyContent={'space-between'} flexDirection={{ base: 'column', lg: 'row' }}>
        <Box flex={3}>
          <ProductImage imageUrl={productDetails?.product?.image} style={commonStyles.imageWidthProductDescription} />
        </Box>
        <Box flex={5} width={'100%'}>
          <Flex gap={'1rem'}>
            <Heading flex={'2'} variant={commonStyles.productListingVariant} mb={commonStyles.locationModalCloseTop}>
              {productDetails?.product?.name.charAt(0).toUpperCase() + productDetails?.product?.name.slice(1)}
            </Heading>
            {largeScreen992 && (
              <Box flex={'1'}>
                <Flex gap={'8px'} alignItems="center" justifyContent={{ base: 'flex-start', lg: 'flex-end' }}>
                  {showDiscountedPrice && (
                    <Heading sx={commonStyles.font20_400} textDecoration="line-through" color="#556AB0">
                      NGN {productDetails.price}
                    </Heading>
                  )}
                  <Heading sx={font32Center.style}>NGN {(productDetails.discounted_price === 0 ? productDetails.price : productDetails.discounted_price) ?? productDetails.price}</Heading>
                </Flex>
                <Text sx={font20Center.style} mt={'19px'}>
                  delivery chargers applied
                </Text>
              </Box>
            )}
          </Flex>
          <Text sx={commonStyles.font24}>{productDetails?.product?.composition?.join(', ')}</Text>
          <Text sx={commonStyles.font24}>{productDetails?.product?.desc}</Text>
          <Text sx={commonStyles.font1624} mt={commonStyles.locationModalCloseTop}>
            Related Categories{' '}
          </Text>
          <Text sx={commonStyles.font2424} mt={'1rem'}>
            # {productDetails?.product?.category}
          </Text>
          <Heading variant={commonStyles.productListingVariant} mb={'1rem'} mt="1.5rem">
            {productDetails?.business?.name.charAt(0).toUpperCase() + productDetails?.business?.name.slice(1)}
          </Heading>
          {!largeScreen992 && (
            <Box>
              <Flex gap={'8px'} alignItems="center" justifyContent={{ base: 'flex-start', lg: 'flex-end' }}>
                {showDiscountedPrice && (
                  <Heading sx={commonStyles.font20_400} textDecoration="line-through" color="#556AB0">
                    NGN {productDetails.price}
                  </Heading>
                )}
                <Heading sx={font32Center.style}>NGN {(productDetails.discounted_price === 0 ? productDetails.price : productDetails.discounted_price) ?? productDetails.price}</Heading>
              </Flex>
              <Text sx={font20Center.style} mt={'19px'}>
                delivery chargers applied
              </Text>
            </Box>
          )}
          {productDetails?.distance != null && (
            <Flex alignItems={'center'} gap="1rem">
              {largeScreen992 ? <LocationIcon fill="#556AB0" /> : <LocationIcon fill="#556AB0" height={25} width={25} />}
              <Heading sx={centerHeading.style}>{convertHoursToDaysHoursMinutes(productDetails?.distance / avarageSpeed)}</Heading>
            </Flex>
          )}
          <Text sx={commonStyles.font2424} color={'#4B70D6'} mt={commonStyles.margin2_Half} textDecoration="underline">
            Side Effects
          </Text>

          {isProductAdded ? (
            <Button iconSpacing={'6'} my={'2rem'} leftIcon={largeScreen992 ? <SearchIcon fill={false} /> : <SearchIcon base={18} fill={false} />} sx={commonStyles.continueSearchButton} onClick={() => navigate(-1)}>
              Continue search
            </Button>
          ) : (
            <Button iconSpacing={'6'} my={'2rem'} leftIcon={largeScreen992 ? <Cart fill={'white'} /> : <Cart height={25} width={25} fill={'white'} />} sx={commonStyles.addToCart} onClick={() => onModalOpenHandler(productDetails)}>
              Add to cart
            </Button>
          )}
        </Box>
      </Flex>
      <EditProductModal isOpen={isOpen} onClose={onClose} setQuantity={setQuantity} quantity={quantity} isLoading={isLoading} addProductHandler={addProductHandler} quantityInStock={quantityInStock} />
    </Box>
  )
}

export default Product
