import React from 'react'

const ShoppingCart = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 196 217"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6953 139.263C5.39276 131.296 1.98129 120.068 1.1678 109.005C-0.365476 88.1488 6.94258 66.7407 20.72 51.7325C28.2596 43.5183 37.5576 37.2002 44.5995 28.5062C51.6083 19.8595 56.557 8.73279 66.0267 3.31676C78.1543 -3.62049 93.3367 1.35359 105.195 8.79503C117.053 16.2365 127.91 26.1704 141.263 29.8021C150.667 32.3601 161.368 31.859 168.943 38.285C180.844 48.3797 177.583 68.0082 181.701 83.5449C185.573 98.1584 196.816 111.265 194.794 126.29C193.236 137.871 183.727 146.835 173.282 150.679C162.836 154.524 151.492 154.219 140.437 153.868C115.452 153.08 90.2048 151.546 65.3637 152.524C49.2151 153.159 23.9173 155.093 12.6953 139.263Z"
        fill="#F1F4F9"
      />
      <g>
        <path
          d="M82.5985 216.999C121.764 216.999 153.513 212.288 153.513 206.478C153.513 200.667 121.764 195.957 82.5985 195.957C43.4333 195.957 11.6836 200.667 11.6836 206.478C11.6836 212.288 43.4333 216.999 82.5985 216.999Z"
          fill="#CED3DD"
        />
      </g>
      <path
        d="M164.437 76.4378C164.658 76.5056 164.898 76.4827 165.102 76.3744C165.307 76.266 165.461 76.0809 165.53 75.8596L167.213 70.405C167.28 70.1833 167.258 69.9439 167.149 69.7389C167.041 69.534 166.856 69.3804 166.635 69.3116C166.413 69.244 166.174 69.2668 165.969 69.375C165.764 69.4832 165.611 69.6679 165.542 69.889L163.859 75.3436C163.791 75.5654 163.814 75.805 163.922 76.01C164.03 76.215 164.215 76.3688 164.437 76.4378Z"
        fill="#F4B683"
      />
      <path
        d="M166.328 76.1123C166.787 76.2549 167.267 76.0335 167.394 75.6231L168.898 70.7467C168.948 70.5817 168.937 70.4043 168.868 70.2464C168.814 70.1258 168.735 70.0182 168.636 69.9311C168.537 69.8441 168.42 69.7796 168.293 69.7422C167.834 69.6004 167.354 69.821 167.227 70.2314L165.723 75.1079C165.687 75.2317 165.684 75.3625 165.713 75.4881C165.742 75.6136 165.802 75.7297 165.888 75.8255C166.005 75.9607 166.157 76.0601 166.328 76.1123Z"
        fill="#F4B683"
      />
      <path
        d="M168.357 75.3928C168.816 75.5346 169.279 75.3707 169.381 75.0288L170.635 70.9662C170.655 70.8953 170.661 70.8212 170.652 70.7481C170.643 70.6751 170.619 70.6046 170.583 70.5408C170.452 70.3188 170.24 70.156 169.992 70.0862C169.532 69.9444 169.07 70.1083 168.964 70.451L167.711 74.5128C167.647 74.7216 167.729 74.95 167.908 75.1289C168.034 75.2524 168.188 75.343 168.357 75.3928Z"
        fill="#F4B683"
      />
      <path
        d="M123.23 204.091C128.14 204.091 132.121 200.108 132.121 195.196C132.121 190.283 128.14 186.301 123.23 186.301C118.319 186.301 114.338 190.283 114.338 195.196C114.338 200.108 118.319 204.091 123.23 204.091Z"
        fill="#414143"
      />
      <path
        d="M123.229 199.986C125.874 199.986 128.017 197.841 128.017 195.196C128.017 192.551 125.874 190.406 123.229 190.406C120.585 190.406 118.441 192.551 118.441 195.196C118.441 197.841 120.585 199.986 123.229 199.986Z"
        fill="#FDFDFB"
      />
      <path
        d="M126.203 195.764C126.519 194.121 125.444 192.533 123.801 192.216C122.159 191.9 120.571 192.975 120.254 194.619C119.938 196.262 121.013 197.85 122.656 198.167C124.298 198.483 125.886 197.408 126.203 195.764Z"
        fill="#8AA8B2"
      />
      <path
        d="M50.5363 204.091C55.447 204.091 59.428 200.108 59.428 195.196C59.428 190.283 55.447 186.301 50.5363 186.301C45.6255 186.301 41.6445 190.283 41.6445 195.196C41.6445 200.108 45.6255 204.091 50.5363 204.091Z"
        fill="#414143"
      />
      <path
        d="M50.5341 199.986C53.1785 199.986 55.3222 197.841 55.3222 195.196C55.3222 192.551 53.1785 190.406 50.5341 190.406C47.8898 190.406 45.7461 192.551 45.7461 195.196C45.7461 197.841 47.8898 199.986 50.5341 199.986Z"
        fill="#FDFDFB"
      />
      <path
        d="M53.5086 195.744C53.8226 194.1 52.7453 192.513 51.1023 192.199C49.4593 191.885 47.8728 192.963 47.5588 194.606C47.2448 196.25 48.3221 197.837 49.9651 198.151C51.6081 198.465 53.1946 197.387 53.5086 195.744Z"
        fill="#8AA8B2"
      />
      <path
        d="M121.08 195.682H116.976C116.552 195.682 116.133 195.599 115.741 195.436C115.35 195.274 114.994 195.036 114.695 194.736C114.395 194.436 114.158 194.08 113.996 193.688C113.834 193.296 113.751 192.876 113.752 192.452V184.934H124.305V192.459C124.305 192.884 124.222 193.304 124.06 193.696C123.898 194.088 123.66 194.445 123.36 194.745C123.06 195.045 122.704 195.283 122.312 195.445C121.92 195.607 121.5 195.69 121.076 195.689L121.08 195.682Z"
        fill="#8499A5"
      />
      <path
        d="M48.2867 195.682H44.183C43.7593 195.682 43.3396 195.599 42.9482 195.436C42.5567 195.274 42.2011 195.036 41.9016 194.736C41.6022 194.436 41.3648 194.08 41.203 193.688C41.0413 193.296 40.9584 192.876 40.959 192.452V184.934H51.5116V192.459C51.5116 193.316 51.1714 194.137 50.5659 194.743C49.9604 195.349 49.1391 195.689 48.2828 195.689L48.2867 195.682Z"
        fill="#8499A5"
      />
      <path
        d="M169.561 76.3458C171.374 74.5325 171.374 71.5925 169.561 69.7792C167.748 67.966 164.81 67.966 162.997 69.7793C161.184 71.5925 161.184 74.5325 162.997 76.3458C164.81 78.159 167.748 78.159 169.561 76.3458Z"
        fill="#414143"
      />
      <path
        d="M129.191 154.602C129.158 154.602 129.124 154.602 129.09 154.598L36.1688 147.854C35.8751 147.833 35.5959 147.718 35.3717 147.527C35.1475 147.336 34.99 147.079 34.9221 146.792L22.0244 92.3976C21.9439 92.0418 22.007 91.6685 22.2 91.359C22.3931 91.0494 22.7004 90.8285 23.0553 90.7444C23.4102 90.6602 23.784 90.7196 24.0954 90.9095C24.4068 91.0994 24.6307 91.4046 24.7184 91.7587L37.3815 145.165L129.291 151.836C129.648 151.863 129.98 152.027 130.219 152.294C130.457 152.561 130.583 152.909 130.571 153.267C130.558 153.624 130.407 153.963 130.15 154.212C129.893 154.461 129.55 154.601 129.192 154.602H129.191Z"
        fill="#8499A5"
      />
      <path
        d="M142.09 117.156H142.042L27.5792 111.963C27.443 111.957 27.3094 111.924 27.1859 111.866C27.0624 111.808 26.9515 111.726 26.8596 111.626C26.6739 111.422 26.5765 111.154 26.5889 110.878C26.6014 110.603 26.7226 110.344 26.9259 110.159C27.1291 109.973 27.3979 109.875 27.6729 109.888L142.136 115.08C142.408 115.091 142.665 115.208 142.852 115.406C143.039 115.605 143.14 115.868 143.134 116.141C143.128 116.413 143.015 116.672 142.819 116.862C142.624 117.052 142.362 117.158 142.09 117.156Z"
        fill="#8499A5"
      />
      <path
        d="M136.047 134.604H135.983L31.4815 128.302C31.2066 128.285 30.9496 128.16 30.767 127.954C30.5844 127.748 30.4911 127.478 30.5078 127.203C30.5244 126.928 30.6495 126.67 30.8556 126.488C31.0617 126.305 31.3319 126.212 31.6067 126.228L136.109 132.531C136.379 132.546 136.633 132.666 136.816 132.865C136.999 133.064 137.097 133.328 137.089 133.598C137.081 133.869 136.968 134.126 136.773 134.314C136.578 134.502 136.318 134.607 136.047 134.606V134.604Z"
        fill="#8499A5"
      />
      <path
        d="M52.0991 148.389C51.847 148.389 51.6036 148.297 51.4143 148.13C51.2251 147.963 51.1029 147.733 51.0706 147.483L43.8894 91.9157C43.8699 91.7796 43.8777 91.641 43.9122 91.5078C43.9468 91.3747 44.0074 91.2498 44.0906 91.1403C44.1738 91.0309 44.2779 90.939 44.3969 90.8701C44.5159 90.8012 44.6474 90.7567 44.7837 90.739C44.9201 90.7214 45.0586 90.731 45.1912 90.7674C45.3237 90.8037 45.4478 90.8661 45.5561 90.9508C45.6644 91.0355 45.7548 91.1409 45.8221 91.2608C45.8893 91.3808 45.9321 91.5129 45.9479 91.6495L53.1299 147.217C53.1477 147.352 53.1387 147.49 53.1032 147.622C53.0677 147.754 53.0066 147.878 52.9233 147.986C52.8399 148.094 52.7361 148.185 52.6176 148.253C52.4992 148.321 52.3685 148.365 52.233 148.383C52.1885 148.388 52.1438 148.39 52.0991 148.389Z"
        fill="#8499A5"
      />
      <path
        d="M68.5124 150.145C68.243 150.146 67.984 150.041 67.7899 149.855C67.5958 149.668 67.4819 149.413 67.4721 149.144L65.4246 91.8163C65.4194 91.68 65.4411 91.544 65.4885 91.4161C65.5358 91.2882 65.6079 91.1709 65.7006 91.0709C65.7933 90.9708 65.9048 90.8901 66.0287 90.8331C66.1526 90.7762 66.2865 90.7442 66.4227 90.739C66.559 90.7339 66.6949 90.7556 66.8228 90.803C66.9506 90.8503 67.0679 90.9225 67.1679 91.0152C67.2679 91.1079 67.3487 91.2194 67.4056 91.3434C67.4625 91.4673 67.4945 91.6013 67.4996 91.7376L69.5511 149.065C69.5609 149.34 69.461 149.608 69.2735 149.809C69.0859 150.011 68.826 150.13 68.551 150.139L68.5124 150.145Z"
        fill="#8499A5"
      />
      <path
        d="M83.3178 150.44C83.2942 150.44 83.2706 150.44 83.2469 150.44C82.9729 150.422 82.7174 150.295 82.5364 150.089C82.3554 149.882 82.2637 149.612 82.2815 149.338L86.1402 92.2065C86.1588 91.9317 86.2858 91.6756 86.4931 91.4945C86.7005 91.3134 86.9713 91.2221 87.2459 91.2407C87.5206 91.2592 87.7766 91.3862 87.9576 91.5936C88.1387 91.8011 88.23 92.072 88.2114 92.3467L84.3526 149.478C84.3335 149.739 84.2162 149.984 84.0243 150.162C83.8323 150.341 83.5799 150.44 83.3178 150.44Z"
        fill="#8499A5"
      />
      <path
        d="M98.5605 151.61C98.5006 151.61 98.4408 151.605 98.3818 151.595C98.1106 151.548 97.8692 151.395 97.7107 151.17C97.5523 150.945 97.4897 150.666 97.5368 150.395L107.649 92.0418C107.696 91.7702 107.849 91.5284 108.074 91.3695C108.186 91.2909 108.312 91.235 108.445 91.205C108.578 91.1751 108.716 91.1716 108.85 91.1949C108.985 91.2182 109.113 91.2677 109.228 91.3407C109.344 91.4137 109.444 91.5087 109.522 91.6202C109.601 91.7318 109.657 91.8577 109.687 91.9909C109.717 92.124 109.72 92.2618 109.697 92.3963L99.5835 150.749C99.5418 150.99 99.4162 151.209 99.2291 151.367C99.0419 151.524 98.8051 151.61 98.5605 151.61Z"
        fill="#8499A5"
      />
      <path
        d="M113.801 153.23C113.641 153.23 113.484 153.193 113.341 153.122C113.198 153.051 113.073 152.949 112.976 152.822C112.879 152.695 112.812 152.548 112.782 152.392C112.751 152.235 112.756 152.074 112.797 151.92L129.211 91.2206C129.283 90.9547 129.458 90.7283 129.696 90.5911C129.935 90.4539 130.219 90.4172 130.484 90.4891C130.75 90.561 130.976 90.7355 131.114 90.9744C131.251 91.2132 131.287 91.4967 131.216 91.7626L114.8 152.462C114.741 152.681 114.611 152.876 114.43 153.015C114.25 153.153 114.028 153.229 113.801 153.23Z"
        fill="#8499A5"
      />
      <path
        d="M37.8825 185.386C37.5153 185.386 37.1632 185.24 36.9035 184.98C36.6439 184.72 36.498 184.368 36.498 184.001C36.498 183.633 36.6439 183.281 36.9035 183.021C37.1632 182.762 37.5153 182.616 37.8825 182.616L131.983 182.47C132.395 182.375 134.064 181.919 134.915 180.571C135.524 179.606 135.616 178.318 135.186 176.741C133.829 171.762 130.703 159.356 129.421 154.253C128.902 152.193 129.002 150.025 129.71 148.021L138.88 121.962L138.897 121.917L157.511 74.4132C157.599 74.1883 157.744 73.9905 157.932 73.8393C158.12 73.688 158.345 73.5888 158.583 73.5513L166.935 72.2318C167.297 72.1745 167.668 72.2637 167.965 72.4797C168.262 72.6957 168.461 73.0208 168.518 73.3835C168.575 73.7462 168.486 74.1168 168.27 74.4138C168.054 74.7107 167.729 74.9097 167.366 74.967L159.796 76.1628L141.481 122.905L132.319 148.941C131.792 150.432 131.717 152.045 132.104 153.579C133.383 158.673 136.504 171.058 137.853 176.013C138.5 178.385 138.291 180.424 137.233 182.078C135.584 184.651 132.48 185.196 132.35 185.218C132.276 185.23 132.2 185.237 132.125 185.237L37.8841 185.383L37.8825 185.386Z"
        fill="#90A3C1"
      />
      <path
        d="M37.8821 183.518C37.6985 183.519 37.5222 183.446 37.3919 183.317C37.2617 183.187 37.1881 183.011 37.1875 182.828C37.1869 182.644 37.2592 182.468 37.3886 182.337C37.518 182.207 37.6938 182.134 37.8774 182.133L129.479 181.551H129.511C129.541 181.551 132.647 181.646 134 179.941C134.686 179.075 134.817 177.84 134.388 176.266C134.035 174.971 133.551 173.222 132.992 171.196C131.471 165.687 129.387 158.143 128.375 154.114C127.862 152.066 127.916 150.212 128.54 148.438L139.237 117.214C139.237 117.203 139.245 117.192 139.25 117.181L155.516 76.5752C156.604 73.7328 157.753 72.3006 159.026 72.2006L166.456 71.0126C166.638 70.9836 166.823 71.0278 166.972 71.1355C167.121 71.2432 167.22 71.4056 167.25 71.5869C167.279 71.7683 167.234 71.9537 167.127 72.1025C167.019 72.2513 166.857 72.3512 166.675 72.3802L159.217 73.5729L159.148 73.58C158.675 73.6076 157.779 74.5387 156.809 77.0754C156.809 77.0788 156.809 77.0823 156.809 77.0857L140.547 117.682L129.843 148.894C129.31 150.41 129.268 152.006 129.713 153.778C130.72 157.791 132.802 165.326 134.322 170.829C134.881 172.856 135.365 174.607 135.719 175.904C136.27 177.929 136.055 179.579 135.076 180.809C133.327 183.007 129.789 182.947 129.471 182.936L37.886 183.518H37.8821Z"
        fill="#8499A5"
      />
      <path
        d="M151.987 90.3398H22.3653C21.5724 90.3398 20.9297 90.9828 20.9297 91.776V92.7646C20.9297 93.5578 21.5724 94.2008 22.3653 94.2008H151.987C152.78 94.2008 153.423 93.5578 153.423 92.7646V91.776C153.423 90.9828 152.78 90.3398 151.987 90.3398Z"
        fill="#414143"
      />
    </svg>
  )
}

export default ShoppingCart

ShoppingCart.defaultProps = {
  width: 294,
  height: 328,
}
