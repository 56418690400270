import React, { useState } from 'react'
import { Box, Text, Flex, Heading, SimpleGrid, Button, useDisclosure } from '@chakra-ui/react'
import HeaderWithText from '../HeaderWithText/HeaderWithText'
import Medicine from '../../Assets/Images/Medicine'
import BackgroundImge from '../../Assets/Images/BackgroundImge.svg'
import { commonStyles } from '../../styles/commonStyles'
import LocationModal from '../LocationModal/LocationModal'
import { IOptions, IPropsFindDrug } from './FindDrug.types'
import { endpoints } from '../../Content/Endpoints'
import useSWR from 'swr'
import { PharmacyProduct } from '../DSCarousel/DSCarousel.types'
import ContentWrap from '../ContentWrap/ContentWrap'
import LocationIcon from '../../Assets/Images/LocationIcon'
import DrugAutoComplete from '../DrugAutocomplete/DrugAutoComplete'

const FindDrug = ({ userAddress, position }: IPropsFindDrug): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const stringWithKindProp: string = BackgroundImge

  const { data: mostSearched, isLoading: productsLoading, error: productError } = useSWR(endpoints.MOST_SEARCHED)
  const InitialMostSearchedArray = mostSearched?.results?.slice(0, 6)

  const [selectedOption, setSelectedOption] = useState<IOptions>()

  const onProductNameClick = (Product: PharmacyProduct): void => {
    onOpen()
    Product?.product?.name != null &&
      setSelectedOption({
        label: Product?.product?.name,
        value: Product?.product?.name,
      })
  }

  return (
    <Box p={{ base: '0 1.5rem', xl: '0 4rem' }} mt={'80px'} background={`url(${stringWithKindProp}) no-repeat `}>
      <Flex gap={{ base: '3rem', xl: '7rem', '3xl': '8rem' }} flexDirection={{ base: 'column', xl: 'row' }}>
        <Box
          flex={2}
          minW={{ base: '100%', lg: '25rem', '3xl': '30rem' }}
          m={{
            base: '',
            xl: '5rem 0 4rem 0',
            '3xl': '6rem 4rem 4rem 4rem',
          }}
          p={'0.8rem 0'}>
          <Flex gap={{ base: '1rem', '3xl': '2rem' }} sx={commonStyles.grayBackGround} alignItems={'center'} mb={'3rem'} mt={{ base: '0', lg: '3rem', xl: '0rem' }}>
            <Medicine />
            <Text sx={commonStyles.textBold}>Discover healthcare around you</Text>
          </Flex>
          <HeaderWithText heading="Get the lowest prices for your medications" text={'Check price options and use discount vouchers now. Save more than 70% on medicines.'} textStyle={{ ...commonStyles.variantTextBold, fontWeight: '500' }} />
        </Box>
        <Box flex={3} margin={{ base: '0 0 3rem 0', lg: ' 2rem auto', xl: '0 0 4rem 0' }}>
          <Flex justifyContent={'flex-end'}>
            <Flex alignItems="center" sx={commonStyles.borderedPlaceButton}>
              <LocationIcon height={32} width={32} />
              <Heading fontSize={{ base: '14px', xl: '16px', '3xl': '18px' }}>{userAddress}</Heading>
            </Flex>
          </Flex>

          <Box sx={commonStyles.grayBorder}>
            <DrugAutoComplete selectedOption={selectedOption} setSelectedOption={setSelectedOption} onClick={onOpen} />
            <Heading mb={'2rem'} variant={commonStyles.variantBoldNormal}>
              People also search for :
            </Heading>
            <ContentWrap isFetching={productsLoading} isError={productError} count={mostSearched?.count} styleNoDataFound={{ my: '0' }}>
              <SimpleGrid columns={commonStyles.gridColumns} spacing={10}>
                {InitialMostSearchedArray?.map((Product: PharmacyProduct, index: number) => (
                  <Button key={index} variant={'secondary'} sx={commonStyles.button} onClick={() => onProductNameClick(Product)}>
                    {Product?.product?.name}
                  </Button>
                ))}
              </SimpleGrid>
            </ContentWrap>
          </Box>
        </Box>
      </Flex>
      {isOpen && <LocationModal isOpen={isOpen} onClose={onClose} selectedMedicine={selectedOption?.label} userAddress={userAddress} position={position} />}
    </Box>
  )
}

export default FindDrug
