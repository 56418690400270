import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Text,
  // useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import useSWR from 'swr'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Download from '../../Assets/Images/Download'
import GreenCheck from '../../Assets/Images/GreenCheck'
import PrintIcon from '../../Assets/Images/PrintIcon'
import QRCode from 'react-qr-code'
import FAQ from '../../components/FAQs/FAQ'
import Footer from '../../Layout/Footer/Footer'
import { Header } from '../../Layout/Header/Header'
import { commonStyles, Text1422500, Text1422600, Text1422700 } from '../../styles/commonStyles'
import { IPropsVoucherPage } from './VoucherPage.types'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import JSPDF from 'jspdf'
import { showToast } from '../../components/Toaster/Toaster'
import { APIMessages, downloadPDFFilename, margin, pageWidth, scaleQualityPDF, voucherExpireDays } from '../../Content/Contants'
import html2canvas from 'html2canvas'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import swrConfig from '../../config/swr.Config'
import { endpoints } from '../../Content/Endpoints'
import { IPropsResult } from '../../components/ProductListing/ProductListing.types'
// import DownloadModal from '../../components/Modal/DownloadModal'
// import Mail from '../../Assets/Images/Mail'
// import TextIcon from '../../Assets/Images/TextIcon'

const VoucherPage = ({ props }: IPropsVoucherPage): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const QRRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const { business: businessName, orderId, price, updatedAt, name, phone, reference, onlinePayment } = location.state

  console.log('businessName', businessName)

  const { token } = useContext(AuthContext)

  const { fetcher } = swrConfig

  const { data: orderListing } = useSWR(token !== null && token !== '' && orderId != null ? [`${endpoints.ORDER_LIST}${String(orderId)}`, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const qrData = JSON.stringify({
    orderId,
    price,
    updatedAt: moment(updatedAt).format('YYYY/MM/DD'),
    name,
    phone,
  })

  // const { isOpen, onOpen, onClose } = useDisclosure()

  // const [downloadby, setDownloadBy] = useState('')

  // const phoneButtonClick = (): void => {
  //   setDownloadBy('phone')
  //   onOpen()
  // }

  // const mailButtonClick = (): void => {
  //   setDownloadBy('mail')
  //   onOpen()
  // }

  const [metaData, setMetaData] = useState('')

  const handleVerify = (): void => {
    axios
      .get(`https://api.paystack.co/transaction/verify/${String(reference) ?? ''}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY ?? ''}`,
        },
      })
      .then((rest: any) => {
        setMetaData(rest.data.data.metadata)
      })
      .catch((error: any) => {
        console.error(error)
      })
  }

  console.log(metaData)

  const printContent = (content: string): void => {
    const printWindow = window.open('', 'Print')
    printWindow?.document.write(content)
    printWindow?.document.close()
    printWindow?.focus()
    printWindow?.print()
    printWindow?.close()
  }

  const handlePrint = (): void => {
    const content = QRRef.current?.innerHTML
    if (content !== undefined) printContent(content)
  }

  const downloadContent = async (content: HTMLElement): Promise<any> => {
    const canvas = await html2canvas(content, {
      scale: scaleQualityPDF,
    })
    const imgData = canvas.toDataURL('image/png')
    const pdf = new JSPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    })
    const contentWidth = canvas.width
    const contentHeight = canvas.height
    const imageWidth = pageWidth - margin * 2
    const imageHeight = imageWidth * (contentHeight / contentWidth)
    pdf.addImage(imgData, 'PNG', margin, 10, imageWidth, imageHeight)
    pdf.save(downloadPDFFilename)
  }

  const handleDownload = (): void => {
    const content: HTMLElement | null = QRRef.current
    if (content !== null) {
      downloadContent(content)
        .then(() => {
          showToast({
            title: 'Success!',
            description: APIMessages.SUCCESS_PDF_DOWNLOAD,
            status: 'success',
          })
        })
        .catch(() => {
          showToast({
            title: 'Error!',
            description: APIMessages.FAILED_PDF_DOWNLOAD,
            status: 'error',
          })
        })
    }
  }

  useEffect(() => {
    handleVerify()
  }, [onlinePayment === true])

  const getItemPrice = (productItem: IPropsResult): JSX.Element => {
    return productItem?.unit_price != null && productItem?.quantity != null ? <Text sx={Text1422500}>NGN {productItem?.unit_price * productItem?.quantity}</Text> : <></>
  }

  return (
    <div>
      <Header headerFor="VOUCHER" showHeader={true} />
      <Box my={commonStyles.spacingFromHeader} mx="auto" maxW={commonStyles.containerMaxWidth}>
        {largeScreen992 && (
          <Center>
            <GreenCheck />
          </Center>
        )}
        {largeScreen992 && (
          <Heading sx={commonStyles.font32Only} textAlign={'center'} fontWeight={'500'} color={'black'} mb={'2rem'}>
            Order Completed
          </Heading>
        )}
        <Heading sx={commonStyles.font32Only} textAlign={'center'} fontWeight={'500'} color={'black'}>
          Show this invoice to pharmacies at pickups
        </Heading>
        <Box ref={QRRef} sx={commonStyles.cartBody}>
          <Box>
            <Flex
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Flex
                style={{
                  display: 'flex',
                  gap: '21px',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}>
                <Box>
                  <Text style={commonStyles.text14_22}>Order ID:</Text>
                  <Text style={Text1422600}>{orderListing?.order_no}</Text>
                </Box>
                <Box>
                  <Text style={commonStyles.text14_22}>Exp. {moment(orderListing?.created_at).add(voucherExpireDays, 'days').format('MMM DD')}</Text>
                  <Text style={commonStyles.text14_22}>
                    Total Item(s): <span style={{ fontWeight: '600' }}>{orderListing?.items?.length}</span>
                  </Text>
                </Box>
              </Flex>

              <Box>
                <Text style={commonStyles.text14_22} mb={'5px'}>
                  Show at pharmacy
                </Text>
                <QRCode size={largeScreen992 ? 102 : 75} level="H" style={{ height: 'auto', maxWidth: '100%', width: '100%' }} value={qrData} viewBox={`0 0 256 256`} />
              </Box>
            </Flex>
            <Divider background={'#556AB0'} colorScheme={'red'} my={'2rem'} borderBottom={'1px dashed #AEAAAE'} gap={'2rem'} flexDirection={'column'} />
            <Box>
              <Flex
                style={{
                  display: 'flex',
                  gap: '2rem',
                  flexDirection: 'column',
                }}>
                <Box>
                  <Text style={{ ...commonStyles.text14_22, marginBottom: '1rem' }}>{String(orderListing?.business)?.charAt(0).toUpperCase() + String(orderListing?.business)?.slice(1)}</Text>
                  {orderListing?.items?.map((productItem: IPropsResult, index: number) => (
                    <Flex
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      key={index}>
                      <Text style={{ ...Text1422500, fontWeight: 500 }}>{productItem?.product?.name}</Text>
                      {getItemPrice(productItem)}
                    </Flex>
                  ))}
                </Box>
                <Box>
                  <Flex
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '1rem',
                    }}>
                    <Text sx={Text1422700}>Total</Text>
                    <Text sx={Text1422700}>NGN {orderListing?.total_amount}</Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Box>
        <Center my={{ base: '2.5rem', lg: '5rem' }} sx={commonStyles.accordianPanel}>
          Print or Download this invoice on your phone
        </Center>
        <Flex gap={{ base: '1rem', lg: '3rem' }} justifyContent={'center'} alignItems={'center'} flexDirection={{ base: 'column', lg: 'row' }}>
          <Button leftIcon={<Download />} sx={commonStyles.productButton} width={{ base: '100%', lg: 'auto' }} onClick={handleDownload}>
            Download
          </Button>
          {/* <Button
            leftIcon={<TextIcon />}
            sx={commonStyles.productButton}
            onClick={phoneButtonClick}
            width={{ base: '100%', lg: 'auto' }}>
            Text Me Details
          </Button> */}
          {/* <Button
            leftIcon={<Mail />}
            sx={commonStyles.productButton}
            onClick={mailButtonClick}
            width={{ base: '100%', lg: 'auto' }}>
            Send As Mail{' '}
          </Button> */}
          <Button leftIcon={<PrintIcon />} sx={commonStyles.productButton} width={{ base: '100%', lg: 'auto' }} onClick={handlePrint}>
            Print
          </Button>
        </Flex>
      </Box>
      <FAQ />
      <Footer />
      {/* <DownloadModal
        isOpen={isOpen}
        onClose={onClose}
        downloadby={downloadby}
      /> */}
    </div>
  )
}

export default VoucherPage
