import React, { useState } from 'react'
import {
  Button,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import HeaderWithText from '../HeaderWithText/HeaderWithText'
import LocationIcon from '../../Assets/Images/LocationIcon'
import { buttonDisabled, commonStyles } from '../../styles/commonStyles'
import { useNavigate } from 'react-router-dom'
import { IPropsLocationModal } from './LocationModal.types'
import { PRODUCT } from '../../Routes/Routes'
import { showToast } from '../Toaster/Toaster'
import { APIMessages } from '../../Content/Contants'

function LocationModal({
  isOpen,
  selectedMedicine,
  onClose,
  userAddress,
  position,
}: IPropsLocationModal): JSX.Element {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const navigate = useNavigate()
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const [locationField, setLocationField] = useState(userAddress ?? '')

  const handleConvertClick = (): void => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${String(
        locationField
      )}&key=${process.env.REACT_APP_GOOGLEAPIKEY as string}`
    )
      .then(async (response) => await response.json())
      .then((data) => {
        const { lat, lng }: { lat: number; lng: number } =
          data.results[0].geometry.location
        navigate(
          `${PRODUCT}${
            selectedMedicine !== undefined
              ? `?search=${selectedMedicine}&latitude=${lat}&longitude=${lng}`
              : ''
          }`
        )
      })
      .catch(() => {
        showToast({
          title: APIMessages.Location_Fetch_Failed_Title,
          description: APIMessages.Location_Fetch_Failed_Message,
          status: 'error',
        })
      })
  }

  const setLocationHandler = (): void => {
    if (locationField !== '') handleConvertClick()
    else
      navigate(
        `${PRODUCT}${
          selectedMedicine !== undefined
            ? `?search=${selectedMedicine}${
                position.latitude != null
                  ? `&latitude=${String(position.latitude ?? '')}`
                  : ''
              }${
                position.longitude != null
                  ? `&longitude=${String(position.longitude ?? '')}`
                  : ''
              }`
            : ''
        }`
      )
  }

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          maxWidth={commonStyles.locationModalWidth}
          p={3}
          pt={{ base: '', lg: 10 }}>
          <ModalHeader>
            <HeaderWithText
              heading="Set your location"
              text="See the lowest prices from pharmacies closest to you."
              headingStyle={{ sx: commonStyles.font32, variant: '' }}
              textStyle={commonStyles.locationModalText}
            />
          </ModalHeader>
          <ModalCloseButton
            size={largeScreen992 ? 'lg' : 'sm'}
            top={commonStyles.locationModalCloseTop}
            right={commonStyles.locationModalCloseRight}
          />
          <ModalBody pb={6}>
            <Text sx={commonStyles.locationModalTextBody}>
              Note: Your browser will ask permission first
            </Text>
            <FormControl>
              <InputGroup alignItems={'center'}>
                <InputLeftElement pointerEvents="none" h="full">
                  {largeScreen992 ? (
                    <LocationIcon />
                  ) : (
                    <LocationIcon height={16} width={16} />
                  )}
                </InputLeftElement>
                <Input
                  placeholder="Enter an address or ZIP code"
                  sx={commonStyles.locationModal.inputField}
                  value={locationField}
                  onChange={(e) => setLocationField(e.target.value)}
                />
              </InputGroup>
            </FormControl>

            {/* <Flex
              alignItems={'center'}
              justifyContent={'space-between'}
              my={commonStyles.locationModalY}>
              <Divider />
              <Text mx={'2rem'} fontSize={commonStyles.locationModalFont}>
                {' '}
                or{' '}
              </Text>
              <Divider />
            </Flex> */}
            {/* 
            <FormControl>
              <Button
                leftIcon={
                  largeScreen992 ? (
                    <LocationHome />
                  ) : (
                    <LocationHome height={14} width={14} />
                  )
                }
                variant={'secondary'}
                sx={commonStyles.locationModal.buttonStyles}
                onClick={updateGeoLocationHandler}>
                Update my location
              </Button>
            </FormControl> */}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              variant={commonStyles.locationModalFooterButtonVariant}
              backgroundColor={'white'}
              color="black"
              border={'1px solid #E6E1E5'}
              mr={commonStyles.locationModalR}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              variant={commonStyles.locationModalFooterButtonVariant}
              backgroundColor={'secondary'}
              color="white"
              sx={buttonDisabled}
              onClick={setLocationHandler}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LocationModal
