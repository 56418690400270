import React from 'react'
import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import NotFoundHeader from '../../Layout/NotFoundHeader/NotFoundHeader'
import NotFoundIcon from '../../Assets/Images/NotFoundIcon.svg'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsNotFoundPage } from './NotFound.types'

const NotFound = ({ props }: IPropsNotFoundPage): JSX.Element => {
  return (
    <Box>
      <NotFoundHeader type="NotFound" />
      <Flex
        flexDirection={'column'}
        p={'5rem'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={'4rem'}>
        <Heading sx={commonStyles.font40}>Oops..</Heading>
        <img src={NotFoundIcon} alt="not found" />
        <Text variant={'Bold'}>
          Page not found, <span style={{ color: '#4B70D6' }}>Try Again</span>
        </Text>
      </Flex>
    </Box>
  )
}

export default NotFound
