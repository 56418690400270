import React from 'react'

const LocationIcon = ({
  height,
  width,
  fill,
}: {
  height?: number
  width?: number
  fill?: string
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56059_55617)">
        <path
          d="M16.0003 2.66699C10.8403 2.66699 6.66699 6.84033 6.66699 12.0003C6.66699 14.3203 7.33366 16.4937 8.54699 18.4537C9.81366 20.507 11.4803 22.267 12.7603 24.3203C13.387 25.3203 13.8403 26.2537 14.3203 27.3337C14.667 28.067 14.947 29.3337 16.0003 29.3337C17.0537 29.3337 17.3337 28.067 17.667 27.3337C18.1603 26.2537 18.6003 25.3203 19.227 24.3203C20.507 22.2803 22.1737 20.5203 23.4403 18.4537C24.667 16.4937 25.3337 14.3203 25.3337 12.0003C25.3337 6.84033 21.1603 2.66699 16.0003 2.66699ZM16.0003 15.667C14.1603 15.667 12.667 14.1737 12.667 12.3337C12.667 10.4937 14.1603 9.00033 16.0003 9.00033C17.8403 9.00033 19.3337 10.4937 19.3337 12.3337C19.3337 14.1737 17.8403 15.667 16.0003 15.667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_56059_55617">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LocationIcon

LocationIcon.defaultProps = {
  height: 32,
  width: 32,
  fill: '#787579',
}
