import React from 'react'

const Medicine = (): JSX.Element => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_52433_49017)">
        <path
          d="M15.0786 6.2334L15.0506 6.20777C13.5521 4.83409 11.4527 4.32698 9.49243 4.86512C7.00235 5.54865 4.43192 5.89505 1.84977 5.89505H0.993501C0.472873 5.89505 0.212173 6.52449 0.58027 6.89265L1.25749 7.56986C1.74489 8.05727 2.40601 8.3311 3.09533 8.3311H3.71653C4.40585 8.3311 5.06697 8.60494 5.55437 9.09234C6.04177 9.57975 6.70289 9.85358 7.39221 9.85358H8.65625C9.34556 9.85358 10.0067 10.1274 10.4941 10.6148L10.8494 10.9701C11.3368 11.4575 11.9979 11.7313 12.6872 11.7313H15.0786V6.2334Z"
          fill="#62A4FB"
        />
        <path
          d="M15.2792 17.044C15.5766 16.9456 15.7231 16.673 16.01 16.4708C15.9708 16.4587 15.9316 16.4468 15.8926 16.4348C13.783 15.7887 11.6867 15.0991 9.60552 14.3665C9.30059 14.2592 8.98342 14.1429 8.76309 13.9063C8.29603 13.4048 8.55854 12.442 9.21541 12.247C9.74872 12.0886 10.298 12.3825 10.8435 12.4912C11.3009 12.5823 11.7964 12.5355 12.198 12.2985C12.5996 12.0614 12.89 11.6189 12.8837 11.1526C12.8744 10.4633 12.2286 9.9107 11.5499 9.78972C10.8712 9.66874 10.179 9.8816 9.53972 10.1397C9.14928 10.2973 8.76612 10.4083 8.39171 10.6009C8.05323 10.775 7.75197 10.9914 7.50112 11.2736C7.00895 11.8274 6.70041 12.5019 6.70576 13.2537C6.71142 14.0442 7.0687 14.8258 7.66273 15.3473C8.24021 15.8543 8.99437 16.1018 9.7256 16.3384C10.9224 16.7256 12.1177 17.1174 13.3116 17.5133C14.1732 17.7024 14.6602 17.2488 15.2792 17.044Z"
          fill="#A2F5C3"
        />
        <path
          d="M16.9213 6.2334L16.9492 6.20777C18.4477 4.83409 20.5471 4.32698 22.5074 4.86512C24.9974 5.54865 27.5679 5.89505 30.15 5.89505H31.0062C31.5268 5.89505 31.7875 6.52449 31.4195 6.89265L30.7422 7.56986C30.2548 8.05727 29.5937 8.3311 28.9044 8.3311H28.2832C27.5939 8.3311 26.9328 8.60494 26.4454 9.09234C25.9579 9.57975 25.2968 9.85358 24.6075 9.85358H23.3435C22.6542 9.85358 21.993 10.1274 21.5056 10.6148L21.1504 10.9701C20.663 11.4575 20.0019 11.7313 19.3126 11.7313H16.9213V6.2334Z"
          fill="#62A4FB"
        />
        <path
          d="M31.0063 5.89503H30.1501C29.7962 5.89503 29.4425 5.88788 29.0892 5.87488L28.8108 6.15328C28.3234 6.64069 27.6623 6.91452 26.973 6.91452H26.3518C25.6625 6.91452 25.0014 7.18836 24.514 7.67576C24.0265 8.16317 23.3654 8.437 22.6761 8.437H21.4121C20.7228 8.437 20.0616 8.71084 19.5742 9.19824L19.219 9.55353C18.7316 10.0409 18.0705 10.3148 17.3812 10.3148H16.9214V11.7313H19.3128C20.0021 11.7313 20.6632 11.4574 21.1506 10.97L21.5058 10.6147C21.9932 10.1273 22.6543 9.8535 23.3437 9.8535H24.6077C25.297 9.8535 25.9581 9.57967 26.4455 9.09226C26.9329 8.60486 27.5941 8.33102 28.2834 8.33102H28.9046C29.5939 8.33102 30.255 8.05719 30.7424 7.56978L31.4196 6.89257C31.7877 6.52454 31.527 5.89503 31.0063 5.89503Z"
          fill="#5392F9"
        />
        <path
          d="M18.9765 25.389C18.5208 24.9205 17.9858 24.543 17.4285 24.1978H17.1842C16.832 24.3259 16.4859 24.4713 16.1456 24.6274C15.9881 24.6996 15.8319 24.7745 15.6768 24.8517C15.6256 24.8772 15.5748 24.9034 15.5239 24.9293C15.4992 24.9425 15.401 24.9938 15.3718 25.0097C15.8315 25.2271 16.2924 25.4429 16.7363 25.6893C17.4643 26.0932 18.1802 26.6208 18.5007 27.3893C18.7544 27.9977 18.7258 28.6709 18.5834 29.3247C18.5082 29.6696 18.9011 29.9303 19.1839 29.7193C19.712 29.3254 20.0588 28.6593 20.0938 27.9891C20.1445 27.0197 19.6533 26.085 18.9765 25.389Z"
          fill="#BED9FD"
        />
        <path
          d="M18.1076 2.10827C18.1076 0.943903 17.1639 0 15.9999 0C14.8359 0 13.8922 0.943903 13.8922 2.10827C13.8922 2.93917 14.373 3.65753 15.0713 4.00103V31.0747C15.0713 31.5857 15.4855 32 15.9965 32C16.5076 32 16.9218 31.5857 16.9218 31.0747V4.00451C17.6238 3.66236 18.1076 2.94187 18.1076 2.10827Z"
          fill="#BED9FD"
        />
        <path
          d="M16 0C15.6518 0 15.3236 0.0849899 15.0342 0.234559C15.7123 0.58495 16.1762 1.29223 16.1762 2.10827C16.1762 2.92417 15.7121 3.63068 15.0343 3.98113C15.0468 3.98757 15.0587 3.99478 15.0714 4.00103V22.822L14.4275 23.5096L15.0714 24.4276V31.0748C15.0714 31.5858 15.4856 32.0001 15.9966 32.0001C16.5077 32.0001 16.9219 31.5858 16.9219 31.0748V18.7271L17.5658 17.8955L16.9219 16.4951V4.00451C17.6239 3.66236 18.1077 2.94194 18.1077 2.10833C18.1077 0.943903 17.164 0 16 0V0Z"
          fill="#9EC8FC"
        />
        <path
          d="M25.1719 12.687C25.0617 12.127 24.7699 11.6105 24.3642 11.2097C24.0105 10.8602 23.5703 10.5986 23.0887 10.473C22.8619 10.4138 22.6271 10.3824 22.4079 10.2992C22.1736 10.2102 21.9651 10.0651 21.7384 9.95819C21.345 9.77269 20.8966 9.70586 20.4663 9.76876C19.7549 9.87268 19.0609 10.4335 19.0461 11.1523C19.0352 11.6848 19.407 12.1799 19.8898 12.4047C20.3727 12.6294 20.9422 12.6125 21.4505 12.4537C21.7958 12.3458 22.137 12.1737 22.4976 12.2015C22.9102 12.2333 23.2802 12.5533 23.3866 12.9532C23.4929 13.3531 23.338 13.8059 23.0189 14.0694C22.8026 14.2482 22.5294 14.34 22.263 14.4281C19.8842 15.2151 17.5053 16.0021 15.1265 16.7892C14.3337 17.0515 13.5223 17.3237 12.8703 17.8454C11.8806 18.6373 11.495 19.8376 11.5786 21.0661C11.6661 22.3526 12.4952 23.3297 13.5402 24.0277C14.0254 24.3517 14.5452 24.6153 15.072 24.8676V22.8221C14.983 22.7705 14.894 22.719 14.805 22.6675C14.4258 22.4479 14.0383 22.2214 13.7588 21.884C13.1865 21.1932 13.2236 20.0753 13.8404 19.4239C14.2609 18.9799 14.867 18.7729 15.4479 18.5814L16.9218 18.0957L17.7991 18.4505L18.6058 17.5408L22.3977 16.2912C22.9864 16.0972 23.5868 15.8972 24.0826 15.5252C24.9373 14.8839 25.3781 13.7353 25.1719 12.687Z"
          fill="#BED9FD"
        />
        <path
          d="M19.8688 18.767C19.5258 18.2146 19.1597 17.8708 18.6058 17.5406C17.7638 17.8182 17.7284 17.8321 16.9218 18.0956L16.9233 18.727C17.2932 18.8592 17.6566 19.0152 17.949 19.2737C18.5253 19.7828 18.7213 20.6817 18.3961 21.3787C18.2015 21.7958 17.8522 22.1203 17.4854 22.3984C16.7391 22.9642 15.893 23.3807 15.0733 23.834C14.2537 24.2872 13.4435 24.7904 12.82 25.4892C12.1964 26.188 11.7738 27.1127 11.8572 28.0456C11.9138 28.6792 12.2435 29.2986 12.7376 29.6843C13.0215 29.9058 13.427 29.6576 13.3542 29.3051C13.2173 28.6425 13.1684 27.967 13.4228 27.3529C13.762 26.5345 14.5649 26.0146 15.3504 25.605C17.0002 24.7447 18.8973 24.0365 19.8428 22.434C19.9877 22.1884 20.1029 21.925 20.1871 21.6519C20.4825 20.694 20.3963 19.6163 19.8688 18.767Z"
          fill="#A2F5C3"
        />
      </g>
      <defs>
        <clipPath id="clip0_52433_49017">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Medicine
