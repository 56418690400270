/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect } from 'react'
import { Box } from '@chakra-ui/react'

import Blogs from '../../components/Blogs/Blogs'
import DSCarousel from '../../components/DSCarousel/DSCarousel'
import EmailSubscription from '../../components/EmailSubscription/EmailSubscription'
import FindDrug from '../../components/FindDrug/FindDrug'
import HowItWorks from '../../components/HowItWorks/HowItWorks'
import Footer from '../../Layout/Footer/Footer'
import { Header } from '../../Layout/Header/Header'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { IPropsHomePage } from './HomePage.types'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import { addMultipleItems } from '../../components/ProductListing/ProductListing.types'
import { LocationContext } from '../../Context/LocationContext.Provider/LocationContext'

const HomePage = ({ props }: IPropsHomePage): JSX.Element => {
  const { token } = useContext(AuthContext)
  const { setNotificationCartCount } = useContext(CartContext)
  const { userLocation, position } = useContext(LocationContext)

  const cartData = localStorage.getItem('cart')

  useEffect(() => {
    if (cartData !== null && token !== '') {
      addMultipleItems({
        cartData,
        setNotificationCartCount,
      })
        .then(() => localStorage.removeItem('cart'))
        .catch(() => localStorage.removeItem('cart'))
    }
  }, [cartData, token, setNotificationCartCount])

  return (
    <Box>
      <Header headerFor="HOME" />
      <MobileHeader headerFor="NORMAL" />
      <Box maxW={'120rem'} mx="auto">
        <FindDrug userAddress={userLocation} position={position} />
        <HowItWorks />
        <DSCarousel heading="Sample savings" text="See how much you can save on medications with TPN's discount voucher" />
        <Blogs />
        <EmailSubscription />
      </Box>
      <Footer />
    </Box>
  )
}

export default React.memo(HomePage)
