import React from 'react'
import { Button, Text, Flex, useMediaQuery } from '@chakra-ui/react'
import ShoppingCart from '../../Assets/Images/ShoppingCart'
import SearchIcon from '../../Assets/Images/SearchIcon'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsCartEmpty } from './CartEmpty.types'
import { Link } from 'react-router-dom'
import { HOME } from '../../Routes/Routes'

const CartEmpty = ({ cartText }: IPropsCartEmpty): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')

  return (
    <Flex
      mt={{ base: '7rem', lg: '5rem' }}
      p={commonStyles.paddingLg}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-around'}
      gap={'5rem'}>
      {largeScreen992 ? (
        <ShoppingCart />
      ) : (
        <ShoppingCart width={194} height={217} />
      )}
      <Text
        sx={commonStyles.lightTextCart}
        variant={commonStyles.variantMobileLightMedium}>
        {cartText}
      </Text>
      <Link to={HOME}>
        <Button
          leftIcon={
            largeScreen992 ? (
              <SearchIcon fill={true} />
            ) : (
              <SearchIcon fill={true} base={18} />
            )
          }
          sx={commonStyles.searchButton}>
          Search for your medication
        </Button>
      </Link>
    </Flex>
  )
}

export default CartEmpty
