import React from 'react'

const InfoIcon = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8 23.6H21.2V26H18.8V23.6ZM18.8 14H21.2V21.2H18.8V14ZM19.988 8C13.364 8 8 13.376 8 20C8 26.624 13.364 32 19.988 32C26.624 32 32 26.624 32 20C32 13.376 26.624 8 19.988 8ZM20 29.6C14.696 29.6 10.4 25.304 10.4 20C10.4 14.696 14.696 10.4 20 10.4C25.304 10.4 29.6 14.696 29.6 20C29.6 25.304 25.304 29.6 20 29.6Z"
        fill="#1F1C19"
      />
    </svg>
  )
}

export default InfoIcon

InfoIcon.defaultProps = {
  height: 40,
  width: 40,
}
