//  @ts-nocheck
import { Button } from '@chakra-ui/react'
import React, { useEffect, useState, useContext } from 'react'
import { usePaystackPayment } from 'react-paystack'
import { PaystackProps } from 'react-paystack/dist/types'
import { useNavigate } from 'react-router-dom'
import PlaceOrderIcon from '../../Assets/Images/PlaceOrderIcon'
import { showToast } from '../../components/Toaster/Toaster'
import { axiosAuthInstance, baseURL } from '../../config/axios.config'
import { APIMessages, InputName } from '../../Content/Contants'
import { endpoints } from '../../Content/Endpoints'
import { VOUCHER } from '../../Routes/Routes'
import { commonStyles } from '../../styles/commonStyles'
import { IAddress, IAddressReceive } from './CheckoutPage.types'
import { paymentSuccess } from './PayStackButton.types'
import moment from 'moment'
import swrConfig from '../../config/swr.Config'
import useSWR from 'swr'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import toastStyles from './Toast.module.css'
import { CheckoutContext } from '../../Context/CheckoutContext.Provider/CheckoutContext'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'

const PaystackButton = ({ amount, businessId, formValue, isDeliverChecked, paymentMethod, isButtonEnabled, setErrors, addressId }: { amount: number; businessId: string | null; formValue: IAddress | IAddressReceive | undefined; isDeliverChecked: boolean; paymentMethod: string; isButtonEnabled: boolean; setErrors: Function; addressId: number | undefined }): JSX.Element => {
  const navigate = useNavigate()
  const [cashConfirmed, setCashConfirmed] = useState(false)
  const [shippingDetail, setShippingDetail] = useState()
  const [orderID, setOrderID] = useState('')
  const [responseDetail, setResponseDetail] = useState({})
  const { shippingResult, handleFormClick, addressCount } = useContext(CheckoutContext)
  const { setNotificationCartCount } = useContext(CartContext)

  const { fetcher } = swrConfig
  const { token } = useContext(AuthContext)

  const { data: profileData } = useSWR(token !== null && token !== '' ? [endpoints.ACCOUNT, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const tokenLength = token?.length
  const checkToken = tokenLength !== null

  const authorizationHeader = `token ${String(token)}`

  useEffect(() => {
    if (checkToken) {
      const addressEndpoint = `${baseURL}${endpoints.ADDRESS}`
      const getShippingAddressDetail = async (): any => {
        const response = await fetch(addressEndpoint, {
          headers: {
            Authorization: authorizationHeader,
          },
        })

        const data = await response.json()
        setShippingDetail(data)
      }

      getShippingAddressDetail()
    }
  }, [checkToken])

  const shippingInfo = shippingDetail?.results.find((item) => {
    return item.is_default === true
  })

  const [config, setConfig] = useState<PaystackProps>({
    email: formValue?.email,
    amount: amount * 100,
    metadata: {
      name: String(formValue?.first_name) + ' ' + String(formValue?.last_name),
      phone: formValue?.phone_number1,
      custom_fields: [],
    },
    channels: [paymentMethod],
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    currency: 'NGN',
  })

  useEffect(() => {
    setConfig((prevConfig) => {
      return {
        ...prevConfig,
        email: profileData?.email,
        name: String(profileData?.first_name) + String(profileData?.last_name),
        phone: profileData?.phone,
      }
    })
  }, [profileData])

  const [addressID, setAddressID] = useState(addressId)

  useEffect(() => {
    setAddressID(addressId)
  }, [addressId])

  const onSuccess = (response: paymentSuccess): void => {
    showToast({
      title: 'Success!',
      description: APIMessages.ON_SUCCESS_PAYMENT,
      status: 'success',
    })
    axiosAuthInstance
      .post(endpoints.SALES_ORDER, {
        business_id: businessId,
        shipping: addressID,
        payment_method: 'card_payment',
      })
      .then((response2) => {
        showToast({
          title: 'Success!',
          description: APIMessages.SALES_ORDER_CREATE,
          status: 'success',
        })
        const orderId: string = response2?.data?.order_no ?? ''
        const business: string = response2?.data?.business ?? ''
        const updatedAtDate: string = response2?.data?.updated_at ?? ''
        const price: string = response2?.data?.total_amount ?? ''
        const firstName: string = response2?.data?.shipping_address?.first_name ?? ''
        const lastName: string = response2?.data?.shipping_address?.last_name ?? ''
        const phone: string = response2?.data?.shipping_address?.phone_number1 ?? ''

        const updatedAt = moment.parseZone(updatedAtDate).format('YYYY/MM/DD')
        navigate(VOUCHER, {
          state: {
            business,
            orderId,
            price,
            updatedAt,
            name: firstName + ' ' + lastName,
            phone,
            reference: response2?.reference,
          },
        })
      })
      .catch((err) => console.log(err))
  }

  const onClose = (): void => {
    alert(APIMessages.ON_CLOSE_PAYMENT)
  }

  const initializePayment = usePaystackPayment(config)

  const isValidateRegion = (): boolean => {
    if (formValue?.region === '' || formValue?.region === undefined) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [InputName.REGION]: 'Please select a valid region',
      }))
      return false
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [InputName.REGION]: '',
      }))
    }
    return true
  }

  const handleCashOnPickup = (): any => {
    const requestData = {
      transaction_no: 'cash_on_delivery',
      payment_method: 'card_payment',
      business_id: businessId,
      shipping_address: {
        first_name: shippingInfo?.first_name,
        last_name: shippingInfo?.last_name,
        address_line1: shippingInfo?.address_line1,
        address_line2: shippingInfo?.address_line2,
        phone_number1: shippingInfo?.phone_number1,
        phone_number2: shippingInfo?.phone_number2,
        additional_information: 'Cash on delivery request.',
        region: shippingInfo?.region,
      },
      shipping: addressID,
    }

    const salesOrderEndpoint = endpoints.SALES_ORDER

    axiosAuthInstance
      .post(salesOrderEndpoint, requestData, {
        headers: {
          Authorization: authorizationHeader,
        },
      })
      .then((response) => {
        console.log('Cash on Pickup Order Created:', response.data)
        setOrderID(response.data.order_no)
        setCashConfirmed(true)
        const orderId = response.data.order_no
        const price = response.data.total_amount ?? ''
        const updatedAt = response.data.updated_at ?? ''
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const name: any = response.data.items[0].business.name
        const business = response.data.business
        const phone = response.data.items[0].business.phone
        const reference = response.data.items[0].product.SKU
        setResponseDetail({
          orderId,
          price,
          updatedAt,
          name,
          reference,
          business,
          phone,
        })
      })
      .catch((error) => {
        console.error('Error creating Cash on Pickup Order:', error)
      })
  }

  const business = responseDetail.business
  const orderId = responseDetail.orderId
  const price = responseDetail.price
  const updatedAtDate = responseDetail.updatedAt
  const updatedAt = moment.parseZone(updatedAtDate).format('YYYY/MM/DD')
  const name = responseDetail.name
  const phone = responseDetail.phone
  const reference = responseDetail.reference

  useEffect(() => {
    if (cashConfirmed) {
      const timer = setTimeout(() => {
        setCashConfirmed(false)
        navigate(VOUCHER, {
          state: {
            business,
            orderId,
            price,
            updatedAt,
            name,
            phone,
            reference,
          },
        })
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [cashConfirmed, responseDetail, navigate])

  localStorage.setItem('last_payment_method_checked', paymentMethod)
  const payment = localStorage.getItem('last_payment_method_checked')

  const onPlaceOrder = (): void => {
    if (isValidateRegion() && addressID == null && shippingResult?.length > 0) {
      axiosAuthInstance
        .post(endpoints.ADDRESS, { ...formValue, is_default_address: true })
        .then((res) => {
          const id = res?.data?.id
          setAddressID(id)

          if (paymentMethod === 'cash' && isDeliverChecked) {
            formValue.transaction_no = 'cash_on_delivery'
          }

          const requestEndpoint = isDeliverChecked ? endpoints.SALES_ORDER : endpoints.SALES_ORDER_CREATE

          const requestData = {
            business_id: businessId,
            shipping: id,
            payment_method: paymentMethod,
            ...formValue,
          }

          axiosAuthInstance
            .post(requestEndpoint, requestData)
            .then((response) => {
              console.log('Order placed successfully:', response)
            })
            .catch((err) => console.log('Error placing order:', err))
        })
        .catch((err) => console.log('Failed to create address:', err))
    } else {
      initializePayment(onSuccess, onClose)
    }
  }

  const handlePaymentClick = (): any => {
    payment === 'cash' ? handleCashOnPickup() : onPlaceOrder()
    setNotificationCartCount(0)
  }

  return (
    <>
      {cashConfirmed && (
        <div className={toastStyles.toast}>
          <p>
            Your order has been confirmed for Cash on Delivery. <br />
            <span style={{ fontSize: 14 }}>
              Order ID: <strong>{orderID}</strong>
            </span>
          </p>
        </div>
      )}

      {shippingResult?.length > 0 ?? addressCount > 0 ? (
        <Button isDisabled={!isButtonEnabled && addressID == null && config.email == null} sx={commonStyles.placeOrderButton} rightIcon={<PlaceOrderIcon />} onClick={handlePaymentClick}>
          Place Order
        </Button>
      ) : (
        <Button title="First add a shipping address" isDisabled={!isButtonEnabled && addressID == null && config.email == null} sx={commonStyles.placeOrderButton} rightIcon={<img src="https://img.icons8.com/material-outlined/24/ffffff/address.png" />} onClick={handleFormClick}>
          Add Shipping&nbsp;<span className="address_first">Address First</span>
        </Button>
      )}
    </>
  )
}

export default PaystackButton
