import React from 'react'
import { Image } from '@chakra-ui/react'

const MailIcon = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  return <Image sx={{ top: '-20px !important' }} src="data:image/svg+xml,%3Csvg width='28' height='22' viewBox='0 0 28 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.6667 0.333496H3.33341C1.86675 0.333496 0.680081 1.5335 0.680081 3.00016L0.666748 19.0002C0.666748 20.4668 1.86675 21.6668 3.33341 21.6668H24.6667C26.1334 21.6668 27.3334 20.4668 27.3334 19.0002V3.00016C27.3334 1.5335 26.1334 0.333496 24.6667 0.333496ZM23.3334 19.0002H4.66675C3.93341 19.0002 3.33341 18.4002 3.33341 17.6668V5.66683L12.5867 11.4535C13.4534 12.0002 14.5467 12.0002 15.4134 11.4535L24.6667 5.66683V17.6668C24.6667 18.4002 24.0667 19.0002 23.3334 19.0002ZM14.0001 9.66683L3.33341 3.00016H24.6667L14.0001 9.66683Z' fill='%2361656A'/%3E%3C/svg%3E" boxSize={{ width, height }} className="custom-svg" alt="Mail Icon" />
}

export default MailIcon

MailIcon.defaultProps = {
  height: 22,
  width: 28,
}
