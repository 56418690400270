import React from 'react'
import { Box, Text, Flex } from '@chakra-ui/react'
import { commonStyles } from '../../styles/commonStyles'

const FooterInfo = (): JSX.Element => {
  return (
    <Box sx={commonStyles.footer}>
      <Flex alignItems={'center'} flexDirection={{ base: 'column', xl: 'row' }}>
        <Text variant={{ base: 'Mobilelight', lg: 'light' }} flex={'3'}>
          Copyright ©2022 Drugstoc, Inc.
        </Text>
        <Flex flex={'5'} alignItems={'center'} justifyContent={'space-around'} flexDirection={{ base: 'column', lg: 'row' }} gap={{ lg: '2rem' }}>
          <Text variant={{ base: 'mobileLightMedium', lg: 'lightBold' }}>Cookie Preferences</Text>
          <Text variant={{ base: 'mobileLightMedium', lg: 'lightBold' }}>Privacy Policy</Text>
          <Text variant={{ base: 'mobileLightMedium', lg: 'lightBold' }}>Disclaimer</Text>
          <Text variant={{ base: 'mobileLightMedium', lg: 'lightBold' }}>Terms of Use</Text>
        </Flex>
      </Flex>
    </Box>
  )
}

export default FooterInfo
