import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, Text, ModalContent, ModalFooter, ModalHeader, FormLabel, ModalOverlay, useMediaQuery, Radio, RadioGroup, Box } from '@chakra-ui/react'
import { commonStyles } from '../../styles/commonStyles'
import { IPropAddressModal } from './AddressModal.types'
import { IAddressReceive } from '../../Pages/CheckoutPage/CheckoutPage.types'
import swrConfig from '../../config/swr.Config'
import useSWR from 'swr'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import { endpoints } from '../../Content/Endpoints'
import { axiosAuthInstance } from '../../config/axios.config'
import ContentWrap from '../../components/ContentWrap/ContentWrap'
import Delete from '../../Assets/Images/Delete'
import { CheckoutContext } from '../../Context/CheckoutContext.Provider/CheckoutContext'

const AddressModal = ({ isOpen, onClose, setPreviousAddressSelected }: IPropAddressModal): any => {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const [defaultValue, setDefaultValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const { token } = useContext(AuthContext)
  const { handleFormClick, showModal, shippingResult, addressCount } = useContext(CheckoutContext)

  const { fetcher } = swrConfig
  const { data: addressListing, isLoading, error, mutate } = useSWR(token !== null && token !== '' ? [endpoints.ADDRESS_LISTING, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  useEffect(() => {
    const defaultData = addressListing?.results?.find((address: IAddressReceive) => address?.is_default)
    setDefaultValue(defaultData?.id)
  }, [addressListing])

  const updateAddressDefault = (): void => {
    setLoading(true)
    axiosAuthInstance
      .patch(`${endpoints.ADDRESS_LISTING}/${defaultValue}`, {
        is_default_address: true,
      })
      .then((res) => {
        setPreviousAddressSelected(res?.data)
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
        onClose()
      })
  }

  const addressRemoveHandler = (id: number): void => {
    setLoading(true)
    axiosAuthInstance
      .delete(`${endpoints.ADDRESS_LISTING}/${id}`)
      .then(async () => {
        await mutate()
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    !showModal && (
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={commonStyles.addressModal} maxH={{ base: '27rem', lg: '52rem' }} p={3} pt={4}>
          <ModalHeader pb={6}>
            <Heading sx={{ ...commonStyles.font32, mb: -35, fontSize: 16 }}>Ship to</Heading>
          </ModalHeader>
          <ModalCloseButton size={largeScreen992 ? 'lg' : 'sm'} top={commonStyles.locationModalCloseTop} right={commonStyles.locationModalCloseRight} />
          <ModalBody pb={6} overflowY="auto">
            <ContentWrap isFetching={isLoading || loading} isError={error} count={addressListing?.count}>
              <RadioGroup
                onChange={(id: string) => {
                  setDefaultValue(id)
                }}
                value={defaultValue?.toString()}>
                {addressListing?.results?.map((address: IAddressReceive, index: number) => (
                  <Flex flexDirection="column" flex="1" key={index} mt="1rem">
                    <Box display="flex" gap={'26px'}>
                      <Radio defaultChecked={address?.is_default} size={{ base: 'sm', lg: 'lg' }} value={address?.id.toString()} id="radio" />
                      <FormLabel
                        htmlFor="radio"
                        sx={{
                          backgroundColor: address?.is_default ? '#f0f0f0' : undefined,
                          borderRadius: 4,
                          padding: 2,
                          transition: 'background-color 0.3s',
                          _hover: {
                            backgroundColor: '#f0f0f0',
                          },
                          w: 'full',
                        }}>
                        <Text sx={{ ...commonStyles.checkoutTextDeliver, padding: 1, fontSize: 12 }}>
                          {address?.first_name} {address?.last_name} &mdash; {address?.region}
                        </Text>
                        <Text sx={{ ...commonStyles.checkoutTextDeliver, padding: 1, fontSize: 12 }} color="black">
                          <span style={{ fontWeight: 600 }}>Address 1: </span>
                          {address?.address_line1}
                        </Text>
                        {String(address?.address_line2).length > 0 && (
                          <Box>
                            <Text sx={{ ...commonStyles.checkoutTextDeliver, padding: 1, fontSize: 12 }} color="black">
                              <span style={{ fontWeight: 600 }}>Address 2: </span>
                              {address?.address_line2}
                            </Text>
                          </Box>
                        )}
                        <Text sx={commonStyles.checkoutTextDeliver} color="black">
                          {address?.phone_number1}
                        </Text>
                      </FormLabel>
                    </Box>
                    <Button rightIcon={<Delete height={16} width={16} />} variant="outline" sx={{ ...commonStyles.font20, fontSize: 14 }} mx="auto" color={'#484649'} width={{ base: '10rem', lg: '12rem' }} onClick={() => addressRemoveHandler(address?.id)}>
                      Remove
                    </Button>
                  </Flex>
                ))}
              </RadioGroup>
            </ContentWrap>
          </ModalBody>

          <>
            {shippingResult?.length > 0 || addressCount > 0 ? (
              <Button backgroundColor="#3182ce" color="#fff" fontSize={14} marginInline="auto" width="90%" onClick={handleFormClick}>
                Add another address
              </Button>
            ) : null}
          </>

          <Box mx="auto" display="flex" justifyContent="center">
            <ModalFooter>
              <Button colorScheme="blue" variant={commonStyles.locationModalFooterButtonVariant} backgroundColor={'secondary'} color="white" onClick={shippingResult?.length > 0 ? updateAddressDefault : handleFormClick}>
                Continue
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    )
  )
}

export default AddressModal
