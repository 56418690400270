import React from 'react'

const PhoneIcon = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56059_56149)">
        <path
          d="M20.6665 1.33203H9.99984C8.15984 1.33203 6.6665 2.82536 6.6665 4.66536V27.332C6.6665 29.172 8.15984 30.6654 9.99984 30.6654H20.6665C22.5065 30.6654 23.9998 29.172 23.9998 27.332V4.66536C23.9998 2.82536 22.5065 1.33203 20.6665 1.33203ZM15.3332 29.332C14.2265 29.332 13.3332 28.4387 13.3332 27.332C13.3332 26.2254 14.2265 25.332 15.3332 25.332C16.4398 25.332 17.3332 26.2254 17.3332 27.332C17.3332 28.4387 16.4398 29.332 15.3332 29.332ZM21.3332 23.9987H9.33317V5.33203H21.3332V23.9987Z"
          fill="#787579"
        />
      </g>
      <defs>
        <clipPath id="clip0_56059_56149">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PhoneIcon

PhoneIcon.defaultProps = {
  height: 32,
  width: 32,
}
