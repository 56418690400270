/* eslint-disable linebreak-style */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'
import { HOME } from '../../Routes/Routes'
import HomePage from '../HomePage/HomePage'

const FacebookPage = (): JSX.Element => {
  const [facebookCode, setFacebookCode] = useState<string>('')
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(false)
  const [facebookUserInfo, setFacebookUserInfo] = useState<any>('')

  const navigate = useNavigate()

  const urlParams = new URLSearchParams(useLocation().search)
  const code: any = urlParams.get('code')

  useEffect(() => {
    if (pathname === '/redirect') {
      void handleAccessToken()
    }
  }, [code, facebookCode, facebookUserInfo, pathname, navigate])

  const handleAccessToken = async (): Promise<void> => {
    try {
      setFacebookCode(code)
      const response = await fetch(`${process.env.REACT_APP_BASE_URL_OAUTH}/api/v2/auth/facebook_auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: facebookCode,
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to obtain access token')
      }

      const data = await response.json()
      setLoading(true)
      setFacebookUserInfo(data)

      localStorage.setItem('token', data?.access_token)
      localStorage.setItem('name', data?.userData.name)
      localStorage.setItem('email', data?.userData?.email)
      localStorage.setItem('picture', data?.userData?.picture?.data?.url)
      localStorage.setItem('oauth_provider', 'facebook')

      const oauthToken = localStorage.getItem('token')
      const oauthPicture = localStorage.getItem('picture') ?? ''
      const oauthEmail = localStorage.getItem('email') ?? ''
      const oauthName = localStorage.getItem('name') ?? ''
      const oauthIDToken = localStorage.getItem('id_token')
      const oauthProvider = localStorage.getItem('oauth_provider')

      if (oauthToken !== undefined && oauthIDToken !== undefined && oauthPicture !== undefined && oauthEmail !== undefined && oauthName !== undefined && oauthIDToken !== undefined && oauthProvider !== undefined) {
        navigate(HOME)
      }
    } catch (error: any) {
      console.error(error.message)
    }
  }

  if (!loading) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </div>
      </>
    )
  } else {
    return <HomePage />
  }
}

export default FacebookPage
