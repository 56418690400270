/* eslint-disable linebreak-style */
import React from 'react'
import { handleGoogleCallback } from '../../utils/OAuthCallback/handleOAuthCallback'
import { Button, Image, Box } from '@chakra-ui/react'
import GoogleImg from '../../Assets/Images/google-logo.svg'

const GoogleButton = (): JSX.Element => {
  const handleClickButton = (): void => {
    handleGoogleCallback()
  }

  const buttonStyles = {
    border: '1px solid #787579',
    paddingInline: '5px',
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
    fontSize: 14,
    w: '190px',
    h: '50px',
  }

  const imgStyles = {
    w: '30px',
    mr: -2,
  }

  return (
    <Button onClick={handleClickButton} sx={{ ...buttonStyles, color: '#757575' }} className="socialButton">
      <Image src={GoogleImg} alt="google logo" fallbackSrc={'https://icons8.com/icon/5gQEqHavGCc4/iphone-spinner' ?? 'https://via.placeholder.com/24'} sx={imgStyles} borderRadius="full" />
      <Box>Sign in with Google</Box>
    </Button>
  )
}

export default GoogleButton
