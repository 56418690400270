import { ErrorMessages, InputName } from '../Content/Contants'
import { EMAIL_REGEX, mobileRegEx, NAME_REGEX } from '../Content/RegexContants'
import { IAuthCreatePassword } from '../Pages/CreateNewPassword/CreatenewPassword.types'
import { IAuthCreate } from '../Pages/SignUpPage/SignUpPage.types'

export const getIsValidValidation = (
  name: string,
  value: string,
  setErrors: Function | undefined,
  formValue?: IAuthCreate | IAuthCreatePassword
): void => {
  if (name === InputName.EMAIL && !EMAIL_REGEX.test(value))
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.EMAIL,
    }))
  else if (name === InputName.PASSWORD && value.trim().length < 8)
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.PASSWORD,
    }))
  else if (
    (name === InputName.FIRST_NAME || name === InputName.LAST_NAME) &&
    !NAME_REGEX.test(value)
  )
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.NAME,
    }))
  else if (
    (name === InputName.PHONE || name === InputName.PHONE_CHECKOUT) &&
    !mobileRegEx.test(value)
  )
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.PHONE,
    }))
  else if (name === InputName.CONFIRM_PASSWORD && value !== formValue?.password)
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.CONFIRM_PASSWORD,
    }))
  else if (
    [
      InputName.FIRST_NAME,
      InputName.LAST_NAME,
      InputName.PASSWORD,
      InputName.CONFIRM_PASSWORD,
      InputName.PHONE,
      InputName.EMAIL,
      InputName.ADDRESS1,
    ].includes(name) &&
    value?.trim()?.length === 0
  )
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: 'Field required',
    }))
  else if (name === InputName.CONFIRM_PASSWORD && value !== formValue?.password)
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: ErrorMessages.CONFIRM_PASSWORD,
    }))
  else
    setErrors?.((prevError: any) => ({
      ...prevError,
      [name]: '',
    }))
}
