import React from 'react'

const SubstractIcon = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6663 0.664062H0.333008V3.33073H21.6663V0.664062Z"
        fill="#323232"
      />
    </svg>
  )
}

export default SubstractIcon

SubstractIcon.defaultProps = {
  height: 4,
  width: 32,
}
