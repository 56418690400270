import React from 'react'
import { SimpleGrid, GridItem, Heading, Box, Center } from '@chakra-ui/react'
import Justice from '../../Assets/Images/Justice'
import Heart from '../../Assets/Images/Heart'
import Persons from '../../Assets/Images/Persons'
import HeaderWithText from '../HeaderWithText/HeaderWithText'
import { IPropsHowItWorks } from './HowItWorks.types'

const HowItWorks = (props: IPropsHowItWorks): JSX.Element => {
  return (
    <Box
      bg="lightWhite"
      p={{
        base: '4rem 1.5rem',
        lg: '6rem 4rem 10rem 4rem',
        xl: '6rem 11rem 10rem 11rem',
      }}>
      <Heading variant={{ base: 'mobileBold18', lg: 'Bold' }} mb={'4.5rem'}>
        <Center color={'darkgrey'}>How TPN Helps You Save</Center>
      </Heading>
      <SimpleGrid columns={{ base: 1, lg: 3 }} gap={'4.5rem'}>
        <GridItem>
          <Justice />
          <HeaderWithText
            heading="Compare Prices"
            text="
              Find the lowest prices from pharmacies closest to you and enjoy
              discounts on the Pharmacy Network."
            headingStyle={{ variant: { base: 'mobileBold20', lg: 'font24' } }}
            textStyle={{ variant: 'lightMedium' }}
          />
        </GridItem>
        <GridItem>
          <Heart />
          <HeaderWithText
            heading="Use discount vouchers"
            text="
              With TPN's discount vouchers, you pay less than the original
              price of your medications."
            headingStyle={{
              mt: '3rem',
              variant: { base: 'mobileBold20', lg: 'font24' },
            }}
            textStyle={{ variant: 'lightMedium' }}
          />
        </GridItem>
        <GridItem>
          <Persons />
          <HeaderWithText
            heading="Show proof"
            text="
              Simply show your pharmacist your discount voucher. Present your
              prescription for prescription-only-medicines."
            headingStyle={{ variant: { base: 'mobileBold20', lg: 'font24' } }}
            textStyle={{ variant: 'lightMedium' }}
          />
        </GridItem>
      </SimpleGrid>
    </Box>
  )
}

export default HowItWorks
