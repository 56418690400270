import React from 'react'
import { Image } from '@chakra-ui/react'

const VisibilityFILL = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  return <Image sx={{ top: '-20px !important' }} src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1024' viewBox='0 96 960 960' width='1024'%3E%3Cpath d='M480.118 726Q551 726 600.5 676.382q49.5-49.617 49.5-120.5Q650 485 600.382 435.5q-49.617-49.5-120.5-49.5Q409 386 359.5 435.618q-49.5 49.617-49.5 120.5Q310 627 359.618 676.5q49.617 49.5 120.5 49.5Zm-.353-58Q433 668 400.5 635.265q-32.5-32.736-32.5-79.5Q368 509 400.735 476.5q32.736-32.5 79.5-32.5Q527 444 559.5 476.735q32.5 32.736 32.5 79.5Q592 603 559.265 635.5q-32.736 32.5-79.5 32.5ZM480 856q-146 0-264-83T40 556q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Zm0-300Zm-.169 240Q601 796 702.5 730.5 804 665 857 556q-53-109-154.331-174.5-101.332-65.5-222.5-65.5Q359 316 257.5 381.5 156 447 102 556q54 109 155.331 174.5 101.332 65.5 222.5 65.5Z' fill='%23000'%3E%3C/path%3E%3C/svg%3E" boxSize={{ width, height }} className="custom-svg" alt="Visibility FILL Icon" />
}

export default VisibilityFILL

VisibilityFILL.defaultProps = {
  height: 30,
  width: 30,
}
