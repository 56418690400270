import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NextArrow from '../../Assets/Images/NextArrow.svg'
import BackArrow from '../../Assets/Images/BackArrow.svg'
import './DSCarousel.css'
import CarouselContent from './CarouselContent'
import HeaderWithText from '../HeaderWithText/HeaderWithText'
import useSWR from 'swr'
import { PharmacyProduct, IPropsCarousel } from './DSCarousel.types'
import { endpoints } from '../../Content/Endpoints'
import { useLocation } from 'react-router-dom'
import { HOME } from '../../Routes/Routes'
import ContentWrap from '../ContentWrap/ContentWrap'
import { paddingForMobile } from '../../styles/commonStyles'

const DSCarousel = ({ heading, text }: IPropsCarousel): JSX.Element => {
  const [customSlider, setCustomSlider] = React.useState<any>()
  const location = useLocation()
  const isHomePage = location.pathname === HOME
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 728,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  }

  const {
    data: relatedProducts,
    isLoading: relatedProductsLoading,
    error: relatedProductsError,
  } = useSWR(endpoints.RELATED_PRODUCTS)
  const InitialRelatedProductsArray = relatedProducts?.results?.slice(0, 20)

  const {
    data: newProductsData,
    isLoading: newProductsLoading,
    error: newProductError,
  } = useSWR(endpoints.NEW_PRODUCTS)
  const InitialNewProductsArray = newProductsData?.results?.slice(0, 20)

  return (
    <Box my={'3rem'} p={paddingForMobile}>
      <HeaderWithText
        heading={heading}
        headingStyle={{
          variant: { base: 'mobileBold18', lg: 'Bold' },
          textAlign: 'center',
        }}
        textStyle={{ variant: 'lightMedium ', mb: '5rem', textAlign: 'center' }}
        text={text}
      />
      <ContentWrap
        isFetching={isHomePage ? newProductsLoading : relatedProductsLoading}
        isError={isHomePage ? newProductError : relatedProductsError}
        count={isHomePage ? newProductsData?.count : relatedProducts?.count}>
        <Slider {...settings} ref={(c) => setCustomSlider(c)}>
          {(isHomePage
            ? InitialNewProductsArray
            : InitialRelatedProductsArray
          )?.map((Product: PharmacyProduct, index: number) => (
            <CarouselContent Product={Product} key={index} />
          ))}
        </Slider>
      </ContentWrap>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        gap={'5rem'}
        my={{ base: '3rem', lg: '1rem' }}>
        <Box onClick={customSlider?.slickPrev}>
          <img src={BackArrow} alt="back" />
        </Box>

        <Box onClick={customSlider?.slickNext}>
          <img src={NextArrow} alt="next" />
        </Box>
      </Flex>
    </Box>
  )
}

export default DSCarousel
