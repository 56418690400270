export function convertHoursToDaysHoursMinutes(hours: number): string {
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24
  const minutes = Math.floor((remainingHours - Math.floor(remainingHours)) * 60)

  const dayString = days > 0 ? `${days} day${days > 1 ? 's' : ''}, ` : ''
  const hourString =
    remainingHours > 0
      ? `${Math.floor(remainingHours)} hour${remainingHours > 1 ? 's' : ''}, `
      : ''
  const minuteString =
    minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : ''

  return `${dayString}${hourString}${minuteString}`.replace(/,\s*$/, '')
}
