import React from 'react'

const PlaceOrderIcon = (): JSX.Element => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_57723_79312)">
        <path
          d="M1.43294 14V2C1.43294 1.82319 1.50318 1.65362 1.6282 1.5286C1.75323 1.40357 1.9228 1.33333 2.09961 1.33333H5.43294V0H2.09961C1.56918 0 1.06047 0.210714 0.685396 0.585786C0.310323 0.960859 0.0996094 1.46957 0.0996094 2L0.0996094 14C0.0996094 14.5304 0.310323 15.0391 0.685396 15.4142C1.06047 15.7893 1.56918 16 2.09961 16H5.43294V14.6667H2.09961C1.9228 14.6667 1.75323 14.5964 1.6282 14.4714C1.50318 14.3464 1.43294 14.1768 1.43294 14Z"
          fill="white"
        />
        <path
          d="M15.5156 6.58565L12.4583 3.52832L11.5156 4.47099L14.3583 7.31365L3.43359 7.33299V8.66632L14.3956 8.64699L11.5143 11.5283L12.4569 12.471L15.5143 9.41365C15.8894 9.03878 16.1002 8.53026 16.1005 7.99993C16.1007 7.4696 15.8904 6.96089 15.5156 6.58565Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default PlaceOrderIcon
