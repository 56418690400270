/* eslint-disable linebreak-style */
const handleLinkedInCallback = (): void => {
  window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENTID}&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_URL}&scope=${process.env.REACT_APP_LINKEDIN_SCOPE}&state=${process.env.REACT_APP_LINKEDIN_STATE}`
}

const handleGoogleCallback = (): void => {
  window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENTID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&scope=${process.env.REACT_APP_GOOGLE_SCOPE}`
}

const handleFacebookCallback = (): void => {
  window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APPID}&redirect_uri=${process.env.REACT_APP_FACEBOOK_REDIRECT_URL}&scope=${process.env.REACT_APP_FACEBOOK_SCOPE}&response_type=code&state=${process.env.REACT_APP_FACEBOOK_STATE}
`
}

export { handleLinkedInCallback, handleGoogleCallback, handleFacebookCallback }
