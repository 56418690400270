/* eslint-disable linebreak-style */
import React from 'react'
import { handleLinkedInCallback } from '../../utils/OAuthCallback/handleOAuthCallback'
import linkedIn from '../../Assets/Images/linkedinIcon.svg'
import { Button, Image } from '@chakra-ui/react'

const LinkedInButton = (): JSX.Element => {
  const buttonStyles = {
    border: '1px solid #787579',
    paddingInline: '5px',
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
    fontSize: 14,
    w: '190px',
    h: '50px',
  }

  const imgStyles = {
    w: '30px',
    mr: -2,
  }

  const handleClickButton = (): void => {
    handleLinkedInCallback()
  }

  return (
    <Button onClick={handleClickButton} style={{ ...buttonStyles, color: '#0A66C2', height: '50px' }} className="socialButton">
      <Image fallbackSrc="https://via.placeholder.com/48" sx={{ ...imgStyles, mr: 1 }} src={linkedIn} alt="linkedIn logo" borderRadius="full" />
      Sign in with LinkedIn
    </Button>
  )
}

export default LinkedInButton
