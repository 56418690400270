export const HOME = '/'
export const PRODUCT = '/products'
export const CART = '/cart'
export const PRODUCT_DESCRIPTION = '/product/:productId'
export const LOGIN = '/login'
export const FORGET_PASSWORD = '/forget_password'
export const SIGNUP = '/signup'
export const NEW_PASSWORD = '/new_password'
export const BILL = '/bill'
export const CHECKOUT = '/checkout'
export const ACCOUNT = '/account'
export const VOUCHER = '/voucher'
export const HELP = '/help'
export const LINKEDIN = '/linkedin'
export const GOOGLE = '/callback'
export const FACEBOOK = '/redirect'
