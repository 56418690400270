import { extendTheme } from '@chakra-ui/react'
import { ButtonStyles as Button } from './ButtonStyles'
import { Select } from './SelectStyles'
import { toastStyles, failedToastStyles, successToastStyles } from '../styles/toast'

export const theme = extendTheme({
  styles: {
    global: {
      '.socialButton:hover': {
        transition: '0.2s ease-in-out',
        transform: 'translateY(-2px)',
        boxShadow: '2px 3px 3px 0px #ccc'
      },
      '.html-modal': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '1000000000',
        '.html-form-group': {
          marginBlock: '20px',
          input: {
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px 7px',
          },
        },
        '.dropdownRegionPosition': {
          pos: 'relative',
        },
        '.dropdownRegion': {
          position: 'absolute',
          right: '10px',
          bottom: '14px',
        },
        img: {
          cursor: 'pointer',
        },
        button: {
          backgroundColor: '#3182ce',
          color: '#fff',
          padding: '10px 16px',
          borderRadius: '0.375rem',
          cursor: 'pointer',
          fontWeight: 500,
        },
      },
      '.regionDropdown': {
        height: 270,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        border: '1px solid rgb(204, 204, 204)',
        marginBottom: '20px',
      },
      '.html-modal-content': {
        backgroundColor: 'white',
        width: '80%',
        maxWidth: '600px',
        maxHeight: '70vh',
        overflowY: 'auto',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      '.html-modal-header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
      },
      '.html-close-button': {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '18px',
        color: '#555',
      },
      '.regionList:hover': {
        backgroundColor: '#3182ce',
        color: '#fff !important',
      },
      '.failedToastStyles': {
        ...failedToastStyles,
      },
      '.toastStyles': {
        ...toastStyles,
      },
      '.successToastStyles': {
        ...successToastStyles,
      },
      '@media(max-width: 991px)': {
        '.address_first': {
          display: 'none',
        },
      },
      '.custom-svg': {
        position: 'relative',
        top: '0 !important',
        width: '24px',
        height: '16px',
      },
      '.linkedButton': {
        textDecoration: 'none',
        backgroundColor: 'blue',
        padding: '5px 10',
        borderRadius: '38.7%',
      },
      '.centerLayout': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      },
    },
  },
  colors: {
    primary: '#FDFDFD',
    secondary: '#556AB0',
    black: '#000000',
    white: '#FFFFFF',
    lightBlack: '#514F6D',
    lightWhite: '#F9F9FC',
    darkgrey: '#1F1C19',
  },
  breakpoints: {
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
    '3xl': '108em',
  },
  components: {
    Button,
    Select,
    Heading: {
      baseStyle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '25px',
        lineHeight: '40px',
        color: 'lightBlack',
      },
      variants: {
        ExtraBold: {
          color: 'secondary',
          fontWeight: '600',
          fontSize: '48px',
          lineHeight: '83px',
        },
        font24: {
          fontWeight: 600,
          fontSize: '2rem',
          lineHeight: '3rem',
          color: 'black',
        },
        Bold: {
          fontWeight: 500,
          fontSize: '2rem',
          lineHeight: '3rem',
        },
        heading25: {
          fontWeight: 500,
          fontSize: '25px',
          lineHeight: '40px',
        },
        mobileExtraBold: {
          color: 'secondary',
          fontWeight: '600',
          fontSize: '24px',
          lineHeight: '40px',
        },
        mobileBold: {
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '32px',
        },
        mobileBold18: {
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '24px',
        },
        mobileBold20: {
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          color: 'black',
        },
        mobileMedium20: {
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '32px',
          color: 'secondary',
        },
        mobileFont16: {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          color: 'black',
        },
        mobileFont24: {
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '32px',
          color: 'black',
        },
        heading16Mobile: {
          fontWeight: 500,
          fontSize: '13px',
          lineHeight: '20px',
        },
        mediumTab: {
          color: 'secondary',
          fontWeight: '600',
          fontSize: '40px',
          lineHeight: '72px',
        },
      },
    },
    Text: {
      baseStyle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: { base: '10px', lg: '22px' },
        lineHeight: '38px',
        color: 'black',
      },
      variants: {
        extraBold: {
          fontWeight: '500',
          fontSize: '25px',
          lineHeight: '48px',
          color: '#979797',
        },
        Bold: {
          fontWeight: '500',
          fontSize: '24px',
          lineHeight: '38px',
          color: 'black',
        },
        lightBold: {
          fontWeight: '500',
          fontSize: '22px',
          lineHeight: '48px',
          color: '#514F6D',
        },
        light: {
          fontWeight: '400',
          fontSize: '22px',
          lineHeight: '48px',
          color: '#1C1B1F',
        },
        lightMedium: {
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '38px',
          color: 'black',
        },
        mobileExtraBold: {
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '32px',
          color: '#979797',
        },
        mobileBold16: {
          fontSize: '16px',
          lineHeight: '32px',
        },
        mobileMediumBold: {
          fontSize: '16px',
          lineHeight: '40px',
        },
        mobileLightMedium: {
          fontWeight: '500',
          fontSize: '13px',
          lineHeight: '24px',
          color: '#514F6D',
        },
        Mobilelight: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '50px',
        },
        extraBoldMobile16: {
          fontWeight: '500',
          fontSize: '13px',
          lineHeight: '20px',
          color: '#556AB0',
        },
        mediumTabText: {
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '36px',
          color: '#979797',
        },
        mediumBoldText: {
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '30px',
          color: 'black',
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: { base: '16px', lg: '24px' },
        lineHeight: { base: '32px', lg: '48px' },
        color: '#484649',
      },
    },
  },
})
