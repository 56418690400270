import React from 'react'
import { Heading, Text } from '@chakra-ui/react'
import IPropsHeadingWithText from './HeaderWithText.types'

const HeaderWithText = ({
  heading,
  text,
  headingStyle,
  textStyle,
}: IPropsHeadingWithText): JSX.Element => {
  return (
    <>
      <Heading
        variant={{
          base: 'mobileExtraBold',
          xl: 'mediumTab',
          '3xl': 'ExtraBold',
        }}
        mt={'1.2rem'}
        {...headingStyle}>
        {heading}
      </Heading>
      <Text
        fontSize={{ base: '16px', lg: '25px' }}
        lineHeight={{ base: '32px', lg: '48px' }}
        mt={'0.6rem'}
        {...textStyle}>
        {text}
      </Text>
    </>
  )
}

export default HeaderWithText
