/* eslint-disable linebreak-style */
const LocationContextDefault = {
  userLocation: '',
  setUserLocation: () => {},
  position: {
    latitude: null,
    longitude: null,
  },
  setPosition: () => {},
}

export default LocationContextDefault
