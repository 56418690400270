import React, { useContext } from 'react'
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react'
import HamburgerIcon from '../../Assets/Images/Hamburger.svg'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsMobileHeader } from './MobileHeader.types'
import { Link, useNavigate } from 'react-router-dom'
import { ACCOUNT, CART, HELP, HOME, LOGIN } from '../../Routes/Routes'
import Cart from '../../Assets/Images/Cart'
import Person from '../../Assets/Images/Person'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import BackButton from '../../Assets/Images/BackButton'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import ProfileIcon from '../../Assets/Images/ProfileIcons'

const MobileHeader = ({ headerFor }: IPropsMobileHeader): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const btnRef = React.useRef(null)

  const { token, setToken, firstTimeUser } = useContext(AuthContext)
  const { notificationCartCount, setNotificationCartCount } = useContext(CartContext)

  const onLogoutHandler = (): void => {
    setNotificationCartCount(0)
    setToken('')
    navigate(LOGIN)
    onClose()
  }

  const onBackHandler = (): void => {
    navigate(HOME)
  }

  const checkToken = token !== null && token !== undefined

  return (
    <Flex as="nav" align="center" justify="space-between" wrap="wrap" paddingX={'2rem'} height="4.5rem" position="fixed" top="0" left="0" width="100%" zIndex="10" backgroundColor={'#F9F9FC'} display={commonStyles.mobileDisplay}>
      {!firstTimeUser && headerFor !== 'AUTH' && headerFor !== 'VOUCHER' && <img src={HamburgerIcon} alt={'toggle'} ref={btnRef} onClick={onOpen} />}
      {headerFor === 'VOUCHER' && (
        <Button gap={'8px'} onClick={onBackHandler} backgroundColor={'inherit'}>
          <BackButton height={24} width={20} />
          <Text variant={commonStyles.productHeaderVariant} color={commonStyles.productHeaderColor}>
            Continue search
          </Text>
        </Button>
      )}
      {headerFor !== 'VOUCHER' && (
        <Flex align="center">
          <Heading variant={{ base: 'mobileBold', lg: 'heading25' }}>
            <Link to="/">Logo</Link>
          </Heading>
        </Flex>
      )}
      {!firstTimeUser && headerFor !== 'AUTH' && headerFor !== 'VOUCHER' && (
        <Link to={CART}>
          <Box position={'relative'}>
            <Cart height={25} width={25} />
            {notificationCartCount !== 0 && <Text sx={commonStyles.notificationText}>{notificationCartCount > 10 ? `10+` : notificationCartCount}</Text>}
          </Box>
        </Link>
      )}
      {firstTimeUser && headerFor !== 'AUTH' && <img src={HamburgerIcon} alt={'toggle'} ref={btnRef} onClick={onOpen} />}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent width={'49% !important'}>
          <DrawerBody>
            <Flex flexDirection={'column'} gap={'1.5rem'}>
              <DrawerCloseButton position="static" />
              {checkToken && <ProfileIcon height={48} width={48} />}
              <Link to={HOME}>
                <Heading sx={commonStyles.mobileView.heading} onClick={onClose}>
                  Home
                </Heading>
              </Link>
              <Link to={HELP}>
                <Heading sx={commonStyles.mobileView.heading} onClick={onClose}>
                  Help
                </Heading>
              </Link>
              <Link to={checkToken ? ACCOUNT : LOGIN}>
                <Button variant={'mobileSecondary'} sx={commonStyles.mobileView.heading} onClick={onClose} leftIcon={token !== null && token !== '' ? <Person height={12} width={12} /> : undefined}>
                  {checkToken ? 'Account' : 'Log In'}
                </Button>
              </Link>
              {checkToken && (
                <Heading sx={commonStyles.mobileView.heading} cursor={'pointer'} onClick={onLogoutHandler}>
                  Log Out
                </Heading>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default MobileHeader
