import React from 'react'
import { HOME, PRODUCT, CART, PRODUCT_DESCRIPTION, LOGIN, FORGET_PASSWORD, SIGNUP, NEW_PASSWORD, BILL, VOUCHER, HELP, LINKEDIN, GOOGLE, FACEBOOK } from './Routes'
import HomePage from '../Pages/HomePage/HomePage'
import ProductPage from '../Pages/ProductPage/ProductPage'
import CartPage from '../Pages/CartPage/CartPage'
import ProductDescription from '../Pages/ProductdescriptionPage/ProductDescription'
import LoginPage from '../Pages/LoginPage/LoginPage'
import ForgetPassworPage from '../Pages/ForgetPassworPage/ForgetPassworPage'
import SignUpPage from '../Pages/SignUpPage/SignUpPage'
import CreateNewPassword from '../Pages/CreateNewPassword/CreateNewPassword'
import BillPage from '../Pages/BillPage/BillPage'
import VoucherPage from '../Pages/VoucherPage/VoucherPage'
import HelpPage from '../Pages/HelpPage/HelpPage'
import LinkedInPage from '../Pages/OAuthPages/LinkedInPage'
import GooglePage from '../Pages/OAuthPages/GooglePage'
import FacebookPage from '../Pages/OAuthPages/FacebookPage'

export const publicRoute = [
  {
    path: HOME,
    component: <HomePage />,
  },
  {
    path: PRODUCT,
    component: <ProductPage />,
  },
  {
    path: CART,
    component: <CartPage />,
  },
  {
    path: PRODUCT_DESCRIPTION,
    component: <ProductDescription />,
  },
  {
    path: LOGIN,
    component: <LoginPage />,
  },
  {
    path: FORGET_PASSWORD,
    component: <ForgetPassworPage />,
  },
  {
    path: SIGNUP,
    component: <SignUpPage />,
  },
  {
    path: NEW_PASSWORD,
    component: <CreateNewPassword />,
  },
  {
    path: BILL,
    component: <BillPage />,
  },
  {
    path: VOUCHER,
    component: <VoucherPage />,
  },
  {
    path: HELP,
    component: <HelpPage />,
  },
  {
    path: LINKEDIN,
    component: <LinkedInPage />,
  },
  {
    path: GOOGLE,
    component: <GooglePage />,
  },
  {
    path: FACEBOOK,
    component: <FacebookPage />,
  },
]
