import React from 'react'
import FooterInfo from '../../components/Footer/FooterInfo'
import FooterNavbar from '../../components/Footer/FooterNavbar'
import { IPropsFooter } from './Footer.types'

const Footer = ({ props }: IPropsFooter): JSX.Element => {
  return (
    <>
      <FooterNavbar />
      <FooterInfo />
    </>
  )
}

export default Footer
