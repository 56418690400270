import React from 'react'

const GreenCheck = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_57358_74314)">
        <path
          d="M62.0006 10.333C33.4806 10.333 10.334 33.4797 10.334 61.9997C10.334 90.5197 33.4806 113.666 62.0006 113.666C90.5206 113.666 113.667 90.5197 113.667 61.9997C113.667 33.4797 90.5206 10.333 62.0006 10.333ZM51.6673 87.833L25.834 61.9997L33.119 54.7147L51.6673 73.2113L90.8823 33.9963L98.1673 41.333L51.6673 87.833Z"
          fill="#21A560"
        />
      </g>
      <defs>
        <clipPath id="clip0_57358_74314">
          <rect width="124" height="124" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GreenCheck

GreenCheck.defaultProps = {
  height: 100,
  width: 100,
}
