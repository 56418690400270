import React from 'react'
import { Image } from '@chakra-ui/react'

const Flag = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  return (
    <Image as="svg" boxSize={{ width, height }} sx={{ top: '-20px !important' }} className="custom-svg" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="31.5" height="21.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5" />
      <g mask="url(#mask0_52334_47057)">
        <rect x="21.333" width="10.6667" height="22" fill="#189B62" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 22H10.6667V0H0V22Z" fill="#189B62" />
      </g>
    </Image>
  )
}

export default Flag

Flag.defaultProps = {
  height: 22,
  width: 32,
}
