import { Box, Flex, Text, Button, useMediaQuery } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import SearchIcon from '../../Assets/Images/SearchIcon'
import { commonStyles } from '../../styles/commonStyles'
import { IOptions, loadOptions } from '../FindDrug/FindDrug.types'
import { IAutoComplete, ILoadsMore } from './DrugAutocomplete.types'

const DrugAutoComplete: React.FC<IAutoComplete> = ({
  selectedOption,
  setSelectedOption,
  onClick,
  style,
}) => {
  const [xl] = useMediaQuery(`(min-width: 1280px)`)
  const [XLxl] = useMediaQuery(`(min-width: 1726px)`)

  const [options, setOptions] = useState<IOptions[]>()
  const [inputValue, setInputValue] = useState<string>('')
  const [loadMore, setLoadMore] = useState<ILoadsMore>({
    next: null,
    prev: null,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue !== '')
        loadOptions(inputValue, null, setLoadMore, setOptions, setIsLoading)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [inputValue])

  const handleChange = (result: any): void => {
    setSelectedOption(result)
    setIsLoading(false)
  }

  const handleInputChangeHandler = (input: string): void => {
    if (input.length > 0) setInputValue(input)
  }

  const handleMenuScrollToBottom = (): void => {
    if (loadMore.next !== null)
      loadOptions(inputValue, null, setLoadMore, setOptions, setIsLoading)
  }

  const handleMenuScrollToTop = (): void => {
    if (loadMore.prev !== null)
      loadOptions(inputValue, null, setLoadMore, setOptions, setIsLoading)
  }

  return (
    <Box sx={style}>
      <Select
        styles={{
          control: (baseStyles, state) => {
            return {
              ...baseStyles,
              ...commonStyles.autoComplete,
              padding: XLxl ? '1rem 2rem' : xl ? '0.1rem 1rem' : '0',
              height: XLxl ? '96px' : xl ? '68px' : '60px',
              fontSize: XLxl ? '25px' : xl ? '20px' : '14px',
              border: state.isFocused ? '1px solid #556AB0' : '1px solid gray',
            }
          },
          option: (baseStyles, state) => ({
            ...baseStyles,
            padding: '1rem 4rem',
            fontWeight: '600',
            fontSize: XLxl ? '22px' : xl ? '18px' : '14px',
            backgroundColor: state.isSelected ? '#556AB0' : 'white',
            color: state.isSelected ? 'white' : 'black',
            '&:hover': {
              backgroundColor: state.isSelected
                ? '#556AB0'
                : 'rgb(241,241,241)',
            },
          }),
          placeholder: (styles, { isFocused }) => ({
            ...styles,
            opacity: isFocused ? 0 : 1,
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        placeholder={
          <Flex
            alignItems={'center'}
            gap={{ base: '0.8rem', lg: '1rem', '3xl': '2.5rem' }}>
            {XLxl ? (
              <SearchIcon fill={false} />
            ) : xl ? (
              <SearchIcon base={25} fill={false} />
            ) : (
              <SearchIcon base={18} fill={false} />
            )}
            <Text variant={commonStyles.variantBoldExtra}>
              Type your drug name
            </Text>
          </Flex>
        }
        defaultValue={selectedOption}
        onChange={handleChange}
        onInputChange={handleInputChangeHandler}
        options={options}
        value={selectedOption}
        isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onMenuScrollToTop={handleMenuScrollToTop}
      />
      <Button
        onClick={onClick}
        sx={{ ...commonStyles.button, ...commonStyles.primaryButton }}>
        Find the lowest prices
      </Button>
    </Box>
  )
}

export default DrugAutoComplete
