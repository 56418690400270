/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react'
import { Heading, Flex, Button, Text, useMediaQuery, Box } from '@chakra-ui/react'
import Cart from '../../Assets/Images/Cart'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsHeader } from './Header.types'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ACCOUNT, CART, HELP, HOME, LOGIN } from '../../Routes/Routes'
import DSMenu from '../../components/DSMenu/DSMenu'
import BackButton from '../../Assets/Images/BackButton'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import useSWR from 'swr'
import { endpoints } from '../../Content/Endpoints'
import swrConfig from '../../config/swr.Config'

export const Header = ({ headerFor, showHeader, isProductAdded }: IPropsHeader): JSX.Element => {
  const navigate = useNavigate()
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const [fullName, setFullName] = useState<string>('')
  const menuItems = ['Account', 'Log out']
  const { token, setToken } = useContext(AuthContext)
  const { notificationCartCount, setNotificationCartCount } = useContext(CartContext)
  const { pathname } = useLocation()
  const { fetcher } = swrConfig

  const { data: profileData } = useSWR(token !== null && token !== '' ? [endpoints.ACCOUNT, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  useEffect(() => {
    setFullName(() => String(profileData?.first_name ?? '') + ' ' + String(profileData?.last_name ?? ''))
  }, [profileData])

  const onMenuItemClickHandler = (itemText: string): void => {
    if (itemText === menuItems[0]) navigate(ACCOUNT)
    else {
      setNotificationCartCount(0)
      setToken('')
      navigate(LOGIN)
    }
  }

  const onBackHandler = (): void => {
    if (headerFor === 'VOUCHER' || isProductAdded === true) navigate('/')
    else navigate(-1)
  }

  return (
    <Flex as="nav" sx={{ zIndex: pathname === '/login' || pathname === '/signup' ? 1000000000 : 10 }} align="center" justify={headerFor === 'AUTH' ? 'center' : 'space-between'} wrap="wrap" paddingX={commonStyles.paddingLg} height="80px" color="white" position="fixed" top="0" left="0" width="100%" backgroundColor={headerFor === 'PRODUCTS' || headerFor === 'PHARMACY' || headerFor === 'VOUCHER' || headerFor === 'AUTH' ? commonStyles.productHeaderBackGroundColor : commonStyles.productHeaderBackGroundColorHome} display={showHeader === true ? 'flex' : commonStyles.headerDisplay}>
      <Flex align="center">
        {(headerFor === 'HOME' || headerFor === 'AUTH') && (
          <Heading variant={commonStyles.headingVariant}>
            <Link to="/">Logo</Link>
          </Heading>
        )}
        {(headerFor === 'PRODUCTS' || headerFor === 'PHARMACY' || headerFor === 'VOUCHER') && (
          <Button gap={'1rem'} onClick={onBackHandler} backgroundColor={'inherit'}>
            {largeScreen992 ? <BackButton /> : <BackButton height={24} width={20} />}
            <Text variant={commonStyles.productHeaderVariant} color={commonStyles.productHeaderColor}>
              {headerFor === 'VOUCHER' || isProductAdded === true ? 'Home' : 'Continue search'}
            </Text>
          </Button>
        )}
      </Flex>

      {headerFor !== 'AUTH' && (
        <Flex align="center" gap={'4.5rem'}>
          {headerFor === 'HOME' && (
            <Link to={HOME}>
              <Heading variant={commonStyles.headingVariant}>Home</Heading>
            </Link>
          )}
          {headerFor !== 'VOUCHER' && (
            <Link to={HELP}>
              <Heading variant={commonStyles.headingVariant}>Help</Heading>
            </Link>
          )}
        </Flex>
      )}
      {!['PHARMACY', 'VOUCHER', 'AUTH'].includes(headerFor) && (
        <Flex gap={'3rem'} alignItems={'center'}>
          {token === undefined || token === null ? (
            <Link to={LOGIN}>
              <Button variant={'buttonBorder'} sx={commonStyles.Signup}>
                Log In
              </Button>
            </Link>
          ) : (
            <DSMenu menuItems={menuItems} onMenuItemClickHandler={onMenuItemClickHandler} fullName={fullName} />
          )}
          <Link to={CART}>
            <Box position={'relative'}>
              {largeScreen992 ? <Cart /> : <Cart height={28} width={28} />}
              {notificationCartCount !== 0 && <Text sx={commonStyles.notificationText}>{notificationCartCount > 10 ? `10+` : notificationCartCount}</Text>}
            </Box>
          </Link>
        </Flex>
      )}
    </Flex>
  )
}
