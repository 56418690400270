import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Heading, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import AddIcon from '../../Assets/Images/AddIcon'
import SubstractIcon from '../../Assets/Images/SubstractIcon'
import { FAQs } from '../../Content/Contants'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsFAQs } from './FAQs.types'

const FAQ = ({ props }: IPropsFAQs): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const email: string = 'Customercare@drugstoc.com'

  return (
    <Box p={'1rem'}>
      <Heading textAlign={'center'} sx={commonStyles.FAQHeading}>
        FAQs(Frquently Asked Questions)
      </Heading>
      <Accordion allowMultiple sx={commonStyles.accordianWidth}>
        {FAQs?.map((faq, index) => (
          <AccordionItem key={index} mb="1.5rem">
            {({ isExpanded }) => (
              <>
                <Box sx={commonStyles.FAQContent}>
                  <AccordionButton>
                    <Heading as={'span'} flex="1" textAlign="left" variant={commonStyles.headingVariant}>
                      {faq.heading}
                    </Heading>
                    {isExpanded ? largeScreen992 ? <SubstractIcon /> : <SubstractIcon height={4} width={17} /> : largeScreen992 ? <AddIcon /> : <AddIcon height={24} width={24} />}
                  </AccordionButton>
                </Box>
                <AccordionPanel pb={4} sx={commonStyles.accordianPanel}>
                  {faq.text}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
        <AccordionItem mb="1.5rem">
          {({ isExpanded }) => (
            <>
              <Box sx={commonStyles.FAQContent}>
                <AccordionButton>
                  <Heading as={'span'} flex="1" textAlign="left" variant={commonStyles.headingVariant}>
                    I am having issues login in, who do I approach?
                  </Heading>
                  {isExpanded ? largeScreen992 ? <SubstractIcon /> : <SubstractIcon height={4} width={17} /> : largeScreen992 ? <AddIcon /> : <AddIcon height={24} width={24} />}
                </AccordionButton>
              </Box>
              <AccordionPanel pb={4} sx={commonStyles.accordianPanel}>
                Reach out to the customer care line seen on the site for immediate issue resolution.
                <br />
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer" style={{ textDecoration: 'underline' }}>
                  {' '}
                  {email}
                </a>
                <br />
                Phone number: 08036879999
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  )
}

export default FAQ
