export const endpoints = {
  SIGNUP_ROUTE: 'auth/register-enduser',
  LOGIN_ROUTE: 'auth/login',
  FORGET_PASSWORD_ROUTE: 'auth/password_reset/',
  CONFIRM_PASSWORD_ROUTE: 'auth/password_reset/confirm/',
  PRODUCT_LISTING: 'inventory/business-products',
  ADD_TO_CART: 'tpn/add-to-cart',
  CART: 'tpn/users-cart',
  ACCOUNT: 'account/profile',
  ORDERS: 'tpn/user-orders',
  SALES_ORDER: 'tpn/sale-order',
  ADDRESS: 'shipping/shipping-address',
  RELATED_PRODUCTS: 'tpn/related-products',
  NEW_PRODUCTS: 'tpn/new-products',
  MOST_SEARCHED: 'tpn/most-searched',
  ADDRESS_LISTING: 'shipping/shipping-address',
  SPECIFIC_PRODUCT: 'tpn/my-business-product/',
  ORDER_LIST: 'tpn/verify-order/',
  SOCIAL_AUTH: 'auth/social_auth',
}
