import React from 'react'
import { Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import BackButton from '../../Assets/Images/BackButton'
import { Link } from 'react-router-dom'
import { IPropsNotFound } from './NotFoundHeader.types'
import { commonStyles } from '../../styles/commonStyles'

const NotFoundHeader = ({ type }: IPropsNotFound): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')

  return (
    <Flex as="nav" align="center" justify={type === 'NotFound' || !largeScreen992 ? 'space-between' : 'center'} position="fixed" width="100%" zIndex="100" wrap="wrap" paddingX={commonStyles.paddingLg} height="7vh" color="white" backgroundColor={'#F7F8F9'} display={'flex'}>
      <Link to={'/'}>
        <Flex alignItems="center" gap={'1rem'}>
          {type === 'NotFound' && <BackButton />}

          {type === 'NotFound' ? (
            <Text variant={'extraBold'} color={'secondary'}>
              Home Page
            </Text>
          ) : (
            <Heading variant={'heading25'}>Logo</Heading>
          )}
        </Flex>
      </Link>
    </Flex>
  )
}

export default NotFoundHeader

NotFoundHeader.defaultProps = {
  type: 'auth',
}
