/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box } from '@chakra-ui/react'
import React, { useContext } from 'react'
import Footer from '../../Layout/Footer/Footer'
import { Header } from '../../Layout/Header/Header'
import { ICheckout } from './CheckoutPage.types'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import AddressModal from '../../components/AddressModal/AddressModal'
import deleteIcons from '../../Assets/Images/delete-sign.png'
import placeMarker from '../../Assets/Images/place-marker.png'
import dropdown from '../../Assets/Images/region-dropdown.png'
import { CheckoutContext } from '../../Context/CheckoutContext.Provider/CheckoutContext'
import Checkout from './Checkout'

const CheckoutPage = ({ props }: ICheckout): JSX.Element => {
  const { isOpen, onClose, setPreviousAddressSelected, formSuccess, formFail, showModal, handleResultClick, handleSubmit, handleChange, handleCloseModal, onCloseModal, showDropdown, handleInputFocus, regionList, textToCheck, sendButton, formData, query, setQuery } = useContext(CheckoutContext)
  const personRegion = (formData.region = query)

  const modalContent = showModal && (
    <div className="html-modal" onClick={handleCloseModal}>
      <div className="html-modal-content">
        <div className="html-modal-header">
          <h2 style={{ fontWeight: 600 }}>Create a Shipping Address (Ctrl + K)</h2>
          <img onClick={onCloseModal} src={deleteIcons} width={24} height={24} alt="close icon" />
        </div>
        <div className="html-modal-body">
          <form onSubmit={handleSubmit}>
            <div className="html-form-group">
              <input id="fname" type="text" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="First Name (Required)" />
            </div>
            <div className="html-form-group">
              <input id="lname" type="text" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="Last Name (Required)" />
            </div>
            <div className="html-form-group">
              <input id="line1" type="text" name="address_line1" value={formData.address_line1} onChange={handleChange} placeholder="Shipping Address 1 (Required)" />
            </div>

            <div className="html-form-group">
              <input id="line2" type="text" name="address_line2" value={formData.address_line2} onChange={handleChange} placeholder="Shipping Address 2" />
            </div>
            <div className="html-form-group">
              <input id="phone2" type="text" name="phone_number2" value={formData.phone_number2} onChange={handleChange} placeholder="Alternative Phone Number" />
            </div>

            <div className="html-form-group">
              <input id="add_info" type="text" name="additional_information" value={formData.additional_information} onChange={handleChange} placeholder="Additional Information" />
            </div>
            <div className="html-form-group dropdownRegionPosition">
              <input id="region" type="text" autoComplete="Off" name="region" value={personRegion} onChange={(event) => setQuery(event.target.value)} onFocus={handleInputFocus} placeholder="Region (Required)" />
              <img className="dropdownRegion" src={dropdown} width={16} height={16} alt="dropdown icon" />
            </div>
            {showDropdown &&
              (query.length === 0 ? (
                <div className="regionDropdown">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '200',
                      alignItems: 'center',
                    }}>
                    <span style={{ color: '#999' }}>Type your region</span>
                    <img style={{ cursor: 'default' }} src={placeMarker} alt="place-marker" width={50} height={50} />
                  </div>
                </div>
              ) : regionList.every((item) => {
                  return Array.from(item).every((letter) => textToCheck.includes(letter))
                }) ? (
                <div
                  style={{
                    color: '#000',
                    borderRadius: 4,
                    border: '1px solid #ccc',
                    marginBottom: 20,
                  }}>
                  {regionList.map((item) => (
                    <p
                      className="regionList"
                      style={{
                        color: '#000',
                        padding: '5px 10px',
                        cursor: 'pointer',
                        margin: '15px 10px',
                        border: '1px solid #ccc',
                        borderRadius: 5,
                      }}
                      key={item}
                      onClick={() => handleResultClick(item)}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : null)}

            <button
              type={sendButton ? 'submit' : 'button'}
              style={{
                backgroundColor: sendButton ? undefined : '#edf2f7',
                color: sendButton ? undefined : '#1a2027',
                cursor: sendButton ? undefined : 'default',
              }}
              className="html-submit-button">
              Send
            </button>
          </form>
          {formFail.length > 0 && (
            <div className="toastStyles failedToastStyles">
              <span>{formFail}</span>
            </div>
          )}
          {formSuccess.length > 0 && (
            <div className="toastStyles successToastStyles">
              <span>{formSuccess}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <Box>
      {modalContent}
      <>
        <Header headerFor="VOUCHER" />
        <MobileHeader headerFor="VOUCHER" />
        <Checkout />
        <Footer />
      </>
      {isOpen && <AddressModal isOpen={isOpen} onClose={onClose} setPreviousAddressSelected={setPreviousAddressSelected} />}
    </Box>
  )
}

export default CheckoutPage
