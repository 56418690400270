import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import FAQ from '../../components/FAQs/FAQ'
import { Header } from '../../Layout/Header/Header'
import Facebook from '../../Assets/Images/Facebook.svg'
import Linkedin from '../../Assets/Images/Linkedin.svg'
import Instagram from '../../Assets/Images/Instagram.svg'
import { commonStyles, HelpLinkButton } from '../../styles/commonStyles'
import FooterInfo from '../../components/Footer/FooterInfo'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { IHelpPage } from './HelpPage.types'

const HelpPage = ({ props }: IHelpPage): JSX.Element => {
  return (
    <Box>
      <Header headerFor="HOME" />
      <MobileHeader headerFor="NORMAL" />
      <Box mt={commonStyles.spacingFromHeader}>
        <FAQ />
        <Flex
          sx={commonStyles.helpContainer}
          mt={{ base: '2rem', lg: '6rem' }}
          mb={'2rem'}
          mx={'auto'}>
          <Heading sx={commonStyles.heading30}>
            Need help? Contact us at
          </Heading>
          <Flex
            flexDir={'column'}
            gap={'16px'}
            justifyContent="center"
            alignItems={'center'}>
            <Text sx={HelpLinkButton}>info@thepharmacynetwork.com</Text>
            <Flex justifyContent={'flex-start'} gap={'3rem'}>
              <img src={Facebook} alt="facebook" />
              <img src={Linkedin} alt="linkedin" />
              <img src={Instagram} alt="instagram" />
            </Flex>
          </Flex>
        </Flex>
        <FooterInfo />
      </Box>
    </Box>
  )
}

export default HelpPage
