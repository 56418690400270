import { AxiosError, AxiosResponse } from 'axios'
import { axiosInstance } from '../../config/axios.config'
export interface IPropsSignUpPage {
  props?: object
}

export interface IAuthCreate {
  first_name: string
  last_name: string
  email?: string
  password: string
  phone: string
  confirm_password?: string
}

export interface IAuthError extends IAuthCreate {
  confirm_password: string
}

export interface IAuthCreateSuccessResponse {
  status: string
  token: string
  id: number
}

export interface IAuthCreateErrorResponse {
  status: string
  error: string
}

export function authRegisterCreate(
  url: string,
  payload: IAuthCreate,
  callback?: (
    result:
      | AxiosResponse<IAuthCreateSuccessResponse>
      | AxiosError<IAuthCreateErrorResponse>
  ) => void
): void {
  axiosInstance
    .post<IAuthCreateSuccessResponse>(url, payload)
    .then((response) => {
      callback?.(response)
    })
    .catch((error: unknown) => {
      if (error instanceof AxiosError) {
        callback?.(error)
      }
    })
}
