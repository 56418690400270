/* eslint-disable linebreak-style */
const AuthContextDefault = {
  token: null,
  setToken: () => {},
  firstTimeUser: true,
  setFirstTimeUser: () => {},
  name: '',
  email: '',
  picture: '',
  defaultFontSize: '',
}

export default AuthContextDefault
