import React, { createContext, useState, useEffect } from 'react'
import { IPosition, IPureLocation } from '../../components/LocationModal/LocationModal.types'
import { showToast } from '../../components/Toaster/Toaster'
import { LocationContextProps } from '../Context.types'
import { useLocation } from 'react-router-dom'
import { LocationContextType } from './LocationContextType'
import LocationContextDefault from './LocationContextDefault'

export const LocationContext = createContext<LocationContextType>(LocationContextDefault)

const LocationContextProvider: React.FC<LocationContextProps> = ({ children }) => {
  const [userLocation, setUserLocation] = useState<string>('')
  const [position, setPosition] = useState<IPosition>({
    latitude: null,
    longitude: null,
  })

  const { pathname } = useLocation()
  const handleGoogleLocation = ({ latitude, longitude }: IPureLocation): void => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${String(latitude)},${String(longitude)}&key=${process.env.REACT_APP_GOOGLEAPIKEY as string}`)
      .then(async (response) => await response.json())
      .then((data) => {
        setUserLocation(data?.results[0]?.formatted_address)
      })
      .catch(() => {})
  }

  const onSuccess = (pos: GeolocationPosition): void => {
    const { latitude, longitude }: IPureLocation = pos.coords
    setPosition({ latitude, longitude })
    handleGoogleLocation({ latitude, longitude })
  }

  const onError = (error: GeolocationPositionError): void => {
    showToast({
      title: 'Error!',
      description: error.message,
      status: 'error',
    })
  }

  const getGeoLocationHandler = (): void => {
    const geo = navigator.geolocation
    navigator.permissions
      .query({ name: 'geolocation' })
      .then()
      .catch(() => {})
    if (geo === undefined) {
      showToast({
        title: 'WARNING!',
        description: 'Geolocation is not supported by this browser.',
        status: 'warning',
      })
    } else {
      if (pathname !== '/login' && pathname !== '/signup' && pathname !== '/linkedin' && pathname !== '/redirect' && pathname !== '/callback') {
        geo.getCurrentPosition(onSuccess, onError)
      }
    }
  }

  useEffect(() => {
    getGeoLocationHandler()
  }, [])

  return <LocationContext.Provider value={{ position, setPosition, userLocation, setUserLocation }}>{children}</LocationContext.Provider>
}

export default LocationContextProvider
