import React from 'react'
import { Button, Flex, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text } from '@chakra-ui/react'
import { IPropsEditProductModal } from './EditProductModal.types'
import { buttonDisabled, commonStyles, inputFieldEditModal } from '../../styles/commonStyles'
import AddIcon from '../../Assets/Images/AddIcon'
import SubstractIcon from '../../Assets/Images/SubstractIcon'
import { ErrorMessages } from '../../Content/Contants'

function EditProductModal({ isOpen, onClose, setQuantity, quantity, addProductHandler, isLoading, quantityInStock }: IPropsEditProductModal): JSX.Element {
  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  return (
    <>
      <Modal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth={commonStyles.QuantityModalMaxWidth} p={3} borderRadius={'1rem'}>
          <ModalHeader>
            <Heading variant={commonStyles.modalBoldVariant} color={'#1C1B1F'} mt={'2.3rem'} ml={{ base: '', lg: '1rem' }} fontSize={commonStyles.headingOverRideFont}>
              Edit Quantity
            </Heading>

            <ModalCloseButton size={commonStyles.SizeCloseButton} top={commonStyles.marginCloseButtonTop} right={commonStyles.locationModalCloseRight} bg={'#E5E8EA'} borderRadius="50%" onClick={() => setQuantity(1)} />
          </ModalHeader>
          <ModalBody pb={6} my={commonStyles.modalBodyMarginY}>
            <Flex alignItems={'center'} justifyContent={'center'} gap={commonStyles.quantityModalGap}>
              <Button sx={commonStyles.iconBorder} height={'2.6rem'} isDisabled={quantity <= 1} onClick={() => setQuantity((prev: number) => prev - 1)}>
                <SubstractIcon />
              </Button>
              <Input
                type={'number'}
                sx={inputFieldEditModal}
                onChange={(e) => {
                  setQuantity(parseInt(e.target.value))
                }}
                value={quantity}
              />
              <Button sx={commonStyles.iconBorder} isDisabled={quantity === quantityInStock} onClick={() => setQuantity((prev: number) => prev + 1)}>
                <AddIcon />
              </Button>
            </Flex>
            {quantity >= quantityInStock && (
              <Text sx={commonStyles.errorText} textAlign="center" mt={'1rem'}>
                {ErrorMessages.MAXIMUM_QUANTITY}
              </Text>
            )}
          </ModalBody>

          <ModalFooter display={'flex'} justifyContent={'center'} mb={{ base: '', lg: '1.5rem' }}>
            <Button isDisabled={quantity >= quantityInStock} colorScheme="blue" variant={commonStyles.locationModalFooterButtonVariant} sx={{ ...commonStyles.quantityModalButton, ...buttonDisabled }} onClick={isLoading ? () => {} : addProductHandler}>
              {isLoading ? <Spinner /> : 'Continue'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditProductModal
