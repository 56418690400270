import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  useMediaQuery,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import {
  IAuthCreatePassword,
  authCreatePassword,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  IPropsCreateNewPasswordPage,
  IAuthCreatePasswordError,
} from './CreatenewPassword.types'
import LoginSide from '../../Assets/Images/LoginSide.svg'
import { commonStyles } from '../../styles/commonStyles'
import DSInput from '../../components/DSInput/DSInput'
import LockIcon from '../../Assets/Images/LockIcon'
import { AxiosError } from 'axios'
import { showToast } from '../../components/Toaster/Toaster'
import { endpoints } from '../../Content/Endpoints'
import { HOME } from '../../Routes/Routes'
import { APIMessages, InputName, minWidth } from '../../Content/Contants'
import RightSidePasswordIcon from '../../components/RightSidePasswordIcon/RightSidePasswordIcon'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { Header } from '../../Layout/Header/Header'
import arrow from '../../Assets/arrow.svg'
import imageCover from '../../Assets/Images/ImageCover.png'

const CreateNewPassword = (): any => {
  const [largeScreen992] = useMediaQuery(minWidth)
  const [isLargeScreen] = useMediaQuery("(min-width: 1057px)");
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [formValue, setFormValue] = useState<IAuthCreatePassword>({
    password: '',
    confirm_password: '',
    token: '',
  })

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)

  const [errors, setErrors] = useState<IAuthCreatePasswordError>()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  useEffect(() => {
    setIsButtonEnabled((formValue.password === formValue.confirm_password) && formValue.confirm_password.length >= 8);
  }, [formValue]);

  console.log(formValue)

  useEffect(() => {
    setFormValue((prevFormValue: any) => ({
      ...prevFormValue,
      token: token ?? '',
    }))
  }, [searchParams])

  const onSubmitHandler = (event: any): void => {
    event.preventDefault()
    authCreatePassword(
      endpoints.CONFIRM_PASSWORD_ROUTE,
      formValue,
      (result) => {
        if (result instanceof AxiosError) {
          const { response } = result
          if (response?.status === 400) {
            const message = Object.values(response.data)?.[0]?.[0]
            showToast({
              title: 'Error!',
              description: message,
              status: 'error',
            })
          } else {
            showToast({
              title: 'Error!',
              description: result?.message,
              status: 'error',
            })
          }
        } else {
          showToast({
            title: 'SUCCESS!',
            description: APIMessages.NEW_PASSWORD_SET_SUCCESS,
            status: 'success',
          })
          navigate(HOME)
        }
      }
    )
  }

  let confirmPassWord: string | undefined;

  if (formValue.confirm_password !== formValue.password) {
    confirmPassWord = errors?.confirm_password
  } else {
    confirmPassWord = undefined
  }

  return (
    <Box>
      <Header headerFor="AUTH" />
      <MobileHeader headerFor="AUTH" />
      <Box sx={{ display: 'flex', justifyContent: isLargeScreen ? 'space-between' : 'center' }}>
        <Box
          sx={{
            display: isLargeScreen ? 'flex' : 'none',
            alignItems: 'stretch',
            // height: '1300px',
          }}
        >
          <Image
            src={imageCover}
            alt=""
            sx={{
              flex: 1,
              display: 'flex',
              height: '100%',
              position: 'fixed',
              mw: '25%',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </Box>
        <Box sx={{ width: isLargeScreen ? '75%' : '90%' }}>

          <Flex mt={'4rem'}>
            {largeScreen992 && (
              <Box flex={3}>
                <Image
                  src={LoginSide}
                  alt="sidebar img"
                  position={'fixed'}
                  height={'100%'}
                />
              </Box>
            )}
            <Box flex={7} p={commonStyles.ProductHeaderDisplay}>
              <Link to='/forget_password'>
                <Image sx={{ cursor: 'pointer' }} width="24px" src={arrow} alt='arrow icon' />
              </Link>
              <Heading sx={commonStyles.font40}>Create a new password</Heading>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'}>
                <span style={{ fontSize: 16 }}>Enter your new password.</span>
              </Text>
              <Flex mt={commonStyles.margin3} sx={commonStyles.FormContainer}>
                <DSInput
                  formLabel="Enter your new password"
                  type={showPassword ? 'text' : 'password'}
                  placeHolder="8 characters"
                  Icon={
                    largeScreen992 ? (
                      <LockIcon />
                    ) : (
                      <LockIcon width={20} height={16} />
                    )
                  }
                  rightIcon={<RightSidePasswordIcon showPassword={showPassword} />}
                  rightIconClickhandler={() => {
                    setShowPassword(!showPassword)
                  }}
                  setFormValue={setFormValue}
                  name={InputName.PASSWORD}
                  error={errors?.password}
                  setErrors={setErrors}
                  isRequired={true}
                />
                <DSInput
                  formLabel="Confirm password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeHolder="8 characters"
                  Icon={
                    largeScreen992 ? (
                      <LockIcon />
                    ) : (
                      <LockIcon width={20} height={16} />
                    )
                  }
                  rightIcon={
                    <RightSidePasswordIcon showPassword={showConfirmPassword} />
                  }
                  rightIconClickhandler={() => {
                    setShowConfirmPassword(!showConfirmPassword)
                  }}
                  setFormValue={setFormValue}
                  name={InputName.CONFIRM_PASSWORD}
                  error={confirmPassWord}
                  isRequired={true}
                />

              </Flex>
              <Flex sx={commonStyles.loginBottom}>
                <style>
                  {
                    `
                     @media (max-width: 991px) {
                      .newPasswordButton {
                        margin-top:-40px;
                      }
                     } 
                    `
                  }
                </style>

                <Box className='newPasswordButton' width={'100%'} mt="-120px">
                  <Button
                    sx={{ ...commonStyles.buttonFullWidth, height: 50, fontSize: 16 }}
                    onClick={onSubmitHandler}
                    isDisabled={!isButtonEnabled}
                  >
                    Next
                  </Button>
                </Box>
                <Box>
                  <Text sx={{ ...commonStyles.font20, fontSize: 14 }}>
                    By continuing, you agree to our
                    <span style={commonStyles.blueColor}> Terms</span> and{' '}
                    <span style={commonStyles.blueColor}>Privacy Policy.</span>
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateNewPassword
