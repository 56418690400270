export const commonStyles = {
  grayBorder: {
    border: { lg: '1px solid #DADCF0' },
    borderRadius: '8px',
    minWidth: { base: '100%', lg: '35rem' },
    backgroundColor: 'white',
    padding: { base: '0', lg: '2rem' },
  },
  grayBackGround: {
    backgroundColor: '#DFE6EE',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    padding: '1rem 2rem',
    width: { base: '', xl: '29rem' },
  },
  textBold: {
    fontWeight: 600,
    fontSize: { base: '13px', xl: '16px', '3xl': '20px' },
    lineHeight: '40px',
  },
  button: {
    color: '#484649',
    padding: '0.5rem 1rem',
    height: '72px',
    fontWeight: 500,
    fontSize: { base: '14px', '3xl': '20px' },
    lineHeight: { base: '32px', lg: '30px' },
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  primaryButton: {
    background: '#556AB0',
    color: 'white',
    display: 'block',
    width: '100%',
    marginBottom: '4rem',
    height: { base: '56px', xl: '68px', '3xl': '88px' },
    fontSize: { base: '14px', xl: '19px', '3xl': '24px' },
  },
  emailSubscription: {
    background:
      'radial-gradient(211.69% 211.69% at 48.15% -7.99%, #F9F9FC 0%, #F7EBE7 100%)',
    margin: { base: '2rem 0', lg: '0 4rem 4rem 4rem' },
    borderRadius: '1.5rem',
  },
  inputField: {
    width: { base: '273px', lg: '754px' },
    height: { base: '64px', lg: '88px' },
    background: '#F9F9FC',
    border: '1px solid #556AB0',
    borderRadius: { base: '8px', lg: '8px 0 0 8px' },

    fontWeight: 400,
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: { base: '32px', lg: '40px' },
    paddingLeft: { base: '1.2rem', lg: '4rem' },
  },

  addMeButton: {
    width: { base: '271px', md: '130px', lg: '166px' },
    height: { base: '64px', lg: '88px' },
    background: '#556AB0',
    borderRadius: { base: '8px', lg: '0px 8px 8px 0px' },
  },
  footer: {
    padding: '16px 58px',
    height: { lg: '5rem' },
    background: '#F9F9FC',
  },
  text14: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '30px',
    color: '#514F6D',
  },
  text18: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    textDecorationLine: 'line-through',
    color: '#514F6D',
    marginRight: '1rem',
  },
  text22: {
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '48px',
    color: '#514F6D',
  },
  innerCarouselContent: {
    width: { base: '100%', lg: '288px' },
    padding: { base: '2rem', lg: '3rem' },
  },
  autoComplete: {
    marginBottom: '1.2rem',
    background: '#F9F9FC',
    border: '1px solid #DADCF0',
    borderRadius: '8px',
    fontWeight: '500',
    fontSize: '25px',
    lineHeight: '48px',
    color: '#979797',
  },
  mobileView: {
    heading: {
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '32px',
      color: '#514F6D',
    },
  },
  Signup: {
    padding: '0.5rem 1rem',
  },

  font32: {
    fontSize: { base: '20px', lg: '32px' },
    lineHeight: '40px',
    fontWeight: '600',
    color: 'black',
  },
  font24: {
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: { base: '24px', lg: '40px' },
    fontWeight: '400',
    color: '#484649',
  },
  productButton: {
    color: 'black',
    background: '#F7F8F9',
    borderRadius: '8px',
    padding: { base: '1rem 7rem', lg: '1rem' },
    fontSize: { base: '10px', lg: '24px' },
    lineHeight: { base: '20px', lg: '40px' },
  },
  productButtonAdd: {
    color: 'white',
    background: '#556AB0',
    borderRadius: '8px',
    padding: { base: '1rem 6.8rem', lg: '1rem' },
    fontSize: { base: '10px', lg: '24px' },
    lineHeight: { base: '20px', lg: '40px' },
  },
  deleteItemButton: {
    color: '#AC1E1A',
    background: '#FAFAFA',
    borderRadius: '8px',
    padding: { base: '1rem 5rem', lg: '1rem' },
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: '40px',
  },
  paginationButton: {
    padding: { base: '0.5rem', lg: '11px' },
    fontSize: { base: '14px', lg: '22px' },
    lineHeight: '16px',
  },
  continueSearchButton: {
    width: '100%',
    background: '#F7F8F9',
    color: '#514F6D',
    borderRadius: '8px',
    height: '65px',
  },
  searchButton: {
    padding: '0.5rem 2rem',
    height: '65px',
    fontWeight: 500,
    fontSize: { base: '14px', lg: '24px' },
    lineHeight: { base: '32px', lg: '32px' },
    background: '#556AB0',
    color: 'white',
    width: { base: '19rem', lg: '40rem' },
  },
  Font40Checkout: {
    marginBottom: '1rem',
    fontSize: { base: '16px', lg: '40px' },
    lineHeight: { base: '24px', lg: '48px' },
    color: '#1F1C19',
    letterSpacing: '-0.0004em',
    fontWeight: { base: 600, lg: 700 },
  },
  font2424: {
    fontSize: { base: '13px', lg: '24px' },
    lineHeight: { base: '20px', lg: '40px' },
    fontWeight: '500',
  },
  font1624: {
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: { base: '20px', lg: '40px' },
    fontWeight: '500',
  },
  addToCart: {
    width: '100%',
    background: '#556AB0',
    color: 'white',
    borderRadius: '8px',
    height: '65px',
  },
  locationModal: {
    inputField: {
      fontWeight: 400,
      fontSize: { base: '10px', lg: '23px' },
      lineHeight: { base: '20px', lg: '40px' },
      padding: '16px 24px 16px 48px',
      gap: '24px',
      height: { base: '52px', lg: '72px' },
      background: '#FFFFFF',
      border: '1px solid #AEAAAE',
      borderRadius: '8px',
    },
    buttonStyles: {
      width: '100%',
      background: '#FFFFFF',
      height: { base: '52px', lg: '72px' },
      borderRadius: '16px',
      padding: '1rem',
      color: 'secondary',
      border: '1px solid #556AB0',
    },
  },
  headerDisplay: { base: 'none', lg: 'flex' },
  paddingLg: { base: '1rem', lg: '4rem' },
  mobileDisplay: { base: 'flex', lg: 'none' },
  ProductHeaderDisplay: { base: '1rem', lg: '3.5rem 3.5rem 3.5rem 3.5rem' },
  productHeaderBackGroundColor: { base: '#F7F8F9', lg: '#F7F8F9' },
  productHeaderBackGroundColorHome: { base: '#F7F8F9', lg: '#FFFFFF' },
  productHeaderVariant: { base: 'extraBoldMobile16', lg: 'extraBold' },
  productHeaderColor: { base: 'secondary', lg: 'secondary' },
  productHeaderGap: { base: '2rem', lg: '3rem' },
  productListingMargin: { base: '1rem auto', lg: '4rem auto' },
  productListingFontSize: { base: '13px', lg: '24px' },
  productListingFlexDirection: { base: 'column', lg: 'row' },
  productListingVariant: { base: 'mobileFont24', lg: 'font24' },
  productListingVariantHeading: { base: 'mobileFont16', lg: 'font24' },
  productListingButton: {
    background: '#F7F8F9',
    borderRadius: '8px',
    padding: '1rem',
    fontSize: { base: '10px', lg: '24px' },
    lineheight: { base: '20px', lg: '40px' },
    color: 'black',
    width: '22rem',
    '&:disabled': {
      opacity: 1,
      color: 'gray',
    },
  },
  productListingButton2: {
    background: '#F7F8F9',
    maxWidth: '100%',
    width: { base: '100%', lg: 'fit-content' },
    borderRadius: '8px',
    padding: '1rem 2rem',
    fontSize: { base: '10px', lg: '22px' },
    lineHeight: { base: '20px', lg: '40px' },
    color: 'secondary',
  },
  editOrderButton: {
    background: 'transparent',
    padding: '1rem',
    fontSize: { base: '14px', lg: '24px' },
    lineHeight: { base: '20px', lg: '48px' },
    color: '#484649',
  },
  products: {
    margin: '0 auto',
  },
  locationModalWidth: { base: '19rem', lg: '47rem' },
  locationModalText: {
    fontSize: { base: '13px', lg: '25px' },
    lineHeight: { base: '20px' },
    mt: { base: '0.5rem', lg: '1rem' },
  },
  locationModalCloseTop: { base: '1.5rem', lg: '2rem' },
  locationModalCloseRight: { base: '1.5rem', lg: '3rem' },
  locationModalTextBody: {
    fontSize: { base: '10px', lg: '20px' },
    lineHeight: { base: '20px', lg: '40px' },
    mt: { base: '1rem', lg: '1.5rem' },
    mb: { base: '1rem', lg: '2.5rem' },
  },
  locationModalFooterButtonVariant: {
    base: 'mediumMobileButton',
    lg: 'mediumButton',
  },
  locationModalY: {
    base: '6',
    lg: '10',
  },
  locationModalFont: { base: '8px', lg: '22px' },
  locationModalR: { base: 2, lg: 5 },
  font40: {
    fontWeight: 700,
    fontSize: { base: '24px', lg: '20px' },
    lineHeight: '62px',
    marginBottom: { base: '0.5rem', lg: '1.1rem' },
  },
  blueColor: { color: '#5EA3D6' },
  font20: {
    fontWeight: 500,
    fontSize: { base: '13px', lg: '20px' },
    lineHeight: { base: '24px', lg: '38px' },
  },
  buttonFullWidth: {
    width: '100%',
    height: { base: '56px', lg: '72px' },
    background: 'secondary',
    borderRadius: '4px',
    color: 'white',
    my: { base: '2rem', lg: '3rem' },
    '&:disabled': {
      background: '#D9D9D9 !important',
      opacity: 1,
      color: 'black',
    },
    '&:hover': {
      opacity: '0.8',
    },
  },
  FormContainer: {
    gap: { base: '1rem', lg: '5rem' },
    justifyContent: 'space-between',
    flexDirection: { base: 'column', lg: 'row' },
  },
  FormContainerCheckout: {
    gap: '2rem',
    justifyContent: 'space-between',
    flexDirection: { base: 'column', lg: 'row' },
  },
  formInput: {
    padding: '12px 4rem',
    width: '100%',
    height: { base: '50px', lg: '50px' },
    background: '#F9F9FC',
    borderRadius: '4px',
    border: 0,
    fontWeight: 400,
    fontSize: { base: '16px', lg: '22px' },
    lineHeight: { base: '32px', lg: '48px' },
    color: 'black',
    '&::placeholder': {
      color: '#AEAAAE',
    },
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active':
      {
        transition: 'background-color 5000s ease-in-out 0s',
      },
  },
  formLabel: {
    fontWeight: 500,
    mb: { base: '3rem', lg: '2.2rem' },
    color: 'black',
    fontSize: 16,
  },
  loginBottom: {
    flexDirection: 'column',
    alignItems: 'center',
    mt: { base: '60px', lg: '110px' },
  },
  VariantBold: { base: 'mobileBold16', lg: 'Bold' },
  margin3: { base: '2rem', lg: '3rem' },
  margin1: '1.1rem',
  flexD: { base: 'column', lg: 'row' },
  variantTextBold: {
    variant: { base: 'mobileBold16', xl: 'mediumBoldText', '3xl': 'Bold' },
  },
  boxMargin: { base: '0 0 3rem 0', lg: '4rem' },
  gridColumns: { base: 2, lg: 3 },
  variantBoldNormal: { base: 'mobileBold', lg: 'Bold' },
  variantBoldExtra: {
    base: 'mobileExtraBold',
    xl: 'mediumTabText',
    '3xl': 'extraBold',
  },
  variantMobileLightMedium: { base: 'mobileLightMedium', lg: 'Bold' },
  lightTextCart: {
    color: '#787579 !important',
    width: { base: '19rem', lg: '42rem' },
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  FAQHeading: {
    fontWeight: 600,
    fontSize: { base: '16px', lg: '30px' },
    lineHeight: '40px',
    marginBottom: { base: '2rem', lg: '5rem' },
  },
  FAQContent: {
    background: '#FFFFFF',
    border: '1px solid #C9C5CA',
    borderRadius: '8px',
    padding: '16px',
  },
  accordianPanel: {
    fontFamily: 'poppins',
    fontWeight: 400,
    fontSize: { base: '14px', lg: '25px' },
    lineHeight: { base: '25px', lg: '40px' },
  },
  headingVariant: { base: 'heading16Mobile', lg: 'heading25' },
  accordianWidth: {
    maxWidth: { base: '20rem', lg: '58rem' },
    margin: '0 auto',
  },
  cartBody: {
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05);',
    borderRadius: '16px',
    maxWidth: { base: '21rem', lg: '32rem' },
    margin: { base: '1.5rem auto', lg: '3rem auto' },
    padding: '2rem',
    backgroundColor: '#FFFFFF',
  },
  heading30: {
    fontWeight: 500,
    fontSize: { base: '20px', lg: '30px' },
    lineHeight: { base: '24px', lg: '40px' },
    color: 'black',
  },
  font20_400: {
    fontWeight: 400,
    fontSize: { base: '12px', lg: '20px' },
    lineHeight: { base: '18px', lg: '40px' },
  },

  font24_40: {
    fontSize: { base: '10px', lg: '24px' },
    lineHeight: { base: '20px', lg: '40px' },
  },
  font28: {
    fontWeight: 500,
    fontSize: { base: '14px', lg: '28px' },
    lineHeight: { base: '24px', lg: '36px' },
    color: '#787579',
  },
  font32_lightBlack: {
    fontSize: { base: '16px', lg: '32px' },
    lineHeight: { base: '24px', lg: '40px' },
    fontWeight: '600',
    color: '#787579',
  },
  inputModal: {
    fontWeight: 400,
    fontSize: { base: '16px', lg: '23px' },
    lineHeight: { base: '24px', lg: '40px' },
    color: 'black',
    padding: { base: '0px 3rem', lg: '12px 4rem' },
    height: { base: '56px', lg: '72px' },
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #AEAAAE',
    borderRadius: '8px',
    '&::placeholder': {
      color: '#AEAAAE',
    },
    '&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus,&:-webkit-autofill:active':
      {
        transition: 'background-color 5000s ease-in-out 0s',
      },
  },
  labelModal: {
    fontWeight: 400,
    fontSize: { base: '13px', lg: '20px' },
    lineHeight: { base: '24px', lg: '40px' },
    margin: { base: '2rem 0 1rem 0', lg: '1.5rem 0 1rem 0' },
  },
  modalContent: {
    fontWeight: 400,
    fontSize: { base: '10px', lg: '22px' },
    lineHeight: { base: '20px', lg: '40px' },
    color: '#787579',
    marginTop: '3rem',
  },
  font13: {
    fontWeight: 600,
    fontSize: { base: '13px', lg: '16px' },
    lineHeight: '24px',
    color: '#514F6D',
  },
  textAlignModal: { base: 'center', lg: 'left' },
  margin2: { base: '2rem', lg: '5rem' },
  font32Only: {
    fontSize: { base: '20px', lg: '32px' },
    lineHeight: { base: '32px', lg: '40px' },
  },
  font8: {
    fontWeight: { base: 400, lg: 500 },
    fontSize: { base: '8px', lg: '20px' },
    lineHeight: { base: '16px', lg: '40px' },
  },

  marginLeft20: { base: '0', lg: '20rem' },
  errorText: {
    width: '100%',
    color: 'red',
    maxHeight: '2.25rem',
    fontSize: '0.875rem',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  modalMaxWidth: { base: '19rem', lg: '40rem' },
  modalBoldVariant: { base: 'mobileBold20', lg: 'Bold' },
  marginCloseButtonTop: { base: '1.5rem', lg: '4rem' },
  marginCloseButtonRight: { base: '1.5rem', lg: '3rem' },
  margin2_Half: { base: '1.5rem', lg: '2.5rem' },
  SizeCloseButton: { base: 'sm', lg: 'lg' },
  imageWidthProducts: { base: '50%', lg: '250px' },
  imageWidthProductDescription: { base: '50%', lg: '70%' },
  iconSpacing: { base: '1', lg: '8' },

  QuantityModalMaxWidth: { base: '19rem', lg: '35rem' },
  iconBorder: {
    border: '1px solid black',
    borderRadius: '8px',
    padding: '0.3rem',
  },

  quantityModalButton: {
    height: { base: '45px', lg: '56px' },
    width: { base: '17rem', lg: '18rem' },
    fontSize: { base: '16px !important', lg: '25px !important' },
    backgroundColor: 'secondary',
    color: 'white',
  },
  quantityModalGap: { base: '3rem', lg: '5rem' },
  modalBodyMarginY: { base: '1rem', lg: '1rem' },
  headingOverRideFont: { base: '24px !important', lg: '32px !important' },
  notificationText: {
    fontWeight: 700,
    fontSize: { base: '14px', lg: '24px' },
    lineHeight: { base: '19px', lg: '24px' },
    position: 'absolute',
    top: { base: '-16px', lg: '-17px' },
    left: { base: '16px', lg: '22px' },
    padding: { base: '5px 8px', lg: '5px 11px' },
    borderRadius: '50%',
    backgroundColor: 'red.700',
    textAlign: 'center',
    color: 'white',
  },
  spacingFromHeader: { base: '7rem', lg: '10rem' },
  spacingForBillPage: { base: '1rem', lg: '5rem' },
  containerMaxWidth: { base: '86vw', lg: '60rem', xl: '80rem' },
  AutoCompleteMaxWidth: { base: '86vw', lg: '38rem', xl: '54rem' },
  checkoutHeading: {
    fontWeight: 700,
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: '32px',
    letterSpacing: '-0.0004em',
    color: '#1F1C19',
  },
  checkoutHeadingDeliver: {
    fontWeight: 600,
    fontSize: { base: '16px', lg: '24px' },
    lineHeight: { base: '24px', lg: '40px' },
    letterSpacing: '-0.0004em',
    color: '#1C1B1F',
  },
  checkoutTextDeliver: {
    fontWeight: 400,
    fontSize: { base: '14px', lg: '20px' },
    lineHeight: { base: '20px', lg: '32px' },
    color: '#484649',
  },
  placeOrderButton: {
    margin: 'auto',
    width: { base: '223px', lg: '306px' },
    height: '52px',
    background: '#556AB0',
    borderRadius: '8px',
    lineHeight: { base: '20px', lg: '52px' },
    fontWeight: 700,
    fontSize: '16px',
    textAlign: 'center',
    letterSpacing: '-0.0004em',
    color: '#FFFFFF',
    '&:disabled': {
      background: '#D9D9D9 !important',
      opacity: 1,
      color: 'white',
    },
    '&:hover': {
      opacity: '0.8',
    },
  },
  paymentGetwayContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: { base: '30px 24px', lg: '60px 24px' },
    margin: 'auto',
    gap: '30px',
    height: '400px',
    maxWidth: { base: '100%', lg: '350px' },
    background: '#FFFFFF',
    border: '1px solid #E6E1E5',
    borderRadius: '16px',
  },
  ButttonTakeFromStore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: { base: '100%', lg: '350px' },
    padding: { base: '30px 24px', lg: '60px 24px' },
    gap: '30px',
    height: '200px',
    background: '#FFFFFF',
    border: '1px solid #E6E1E5',
    borderRadius: '16px',
  },
  textArea: {
    width: '100%',
    fontWeight: 400,
    fontSize: '16px',
    lineheight: '24px',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    padding: '1.2rem 1rem',
    color: 'black',
  },
  font16: {
    fontWeight: 500,
    fontSize: { base: '13px', lg: '16px' },
    lineHeight: '20px',
    color: '#484649',
    letterSpacing: '-0.0004em',
  },
  select: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    height: { base: '48px !important', lg: '64px !important' },
    padding: { base: '0 1rem', lg: '1rem 1rem' },
    fontWeight: 400,
    fontSize: { base: '16px', lg: '16px' },
    lineHeight: { base: '20px', lg: '24px' },
    color: '#718096',
    '&::placeholder': {
      color: '#718096',
    },
    menuItem: {
      color: 'red',
      padding: '16px',
      lineHeight: '40px',
      _hover: { backgroundColor: 'gray' },
    },
  },
  options: {
    padding: '16px',
    lineHeight: '40px',
  },

  helpContainer: {
    maxWidth: '80rem',
    padding: '2rem',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '38px',
  },
  addressModal: { base: '19rem', lg: '35.8rem' },
  font1824: {
    fontSize: { base: '18px', lg: '24px' },
    lineHeight: { base: '24px', lg: '40px' },
    color: '#000000',
  },
  font1620: {
    fontWeight: 500,
    fontSize: { base: '16px', lg: '20px' },
    lineHeight: { base: '20px', lg: '24x' },
    color: '#000000',
  },
  text14_22: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#484649',
    letterSpacing: '0.1px',
  },
  borderedPlaceButton: {
    width: 'fit-content',
    padding: ' 8px 16px',
    gap: '8px',
    background: '#FFFFFF',
    border: '0.8px solid #C9C5CA',
    borderRadius: '8px',
    mt: {
      base: '',
      xl: '3rem',
      '3xl': '4rem',
    },
    mb: '1rem',
  },
  addressField: {
    background: '#F7F8F9',
    borderRadius: '8px',
    padding: '16px 24px',
  },
}

export const delteModalContent = {
  ...commonStyles.modalContent,
  marginTop: { base: '0', lg: '1rem' },
}

export const centerHeading = {
  style: {
    ...commonStyles.font2424,
    textAlign: { base: 'center', lg: 'left' },
    color: 'black',
    fontSize: { base: '15px !important', lg: '24px !important' },
  },
}

export const font32Center = {
  style: {
    ...commonStyles.font32,
    lineHeight: { base: '18px !important', lg: '40px !important' },
    fontSize: { base: '16px !important', lg: '32px !important' },
    textAlign: { base: 'left', lg: 'right' },
  },
}

export const font20Center = {
  style: {
    ...commonStyles.font20_400,
    textAlign: { base: 'left', lg: 'right' },
  },
}

export const font16Center = {
  style: {
    fontWeight: 400,
    fontSize: { base: '13px', lg: '16px' },
    lineHeight: { base: '18px', lg: '40px' },
    textAlign: { base: 'center', lg: 'right' },
  },
}

export const exitingAddressButton = {
  ...font16Center.style,
  fontWeight: '600 !important',
}

export const contentWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 60,
}

export const contentWrapTable = {
  textAlign: 'center',
  verticalAlign: 'middle',
  height: 80,
}

export const radioStyles = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
}

export const linkButton = {
  ...commonStyles.blueColor,
  cursor: 'pointer',
  textDecoration: 'underline',
}

export const HelpLinkButton = {
  ...commonStyles.font24,
  color: 'black !important',
  textDecoration: 'underline',
}

export const Text1422600 = {
  ...commonStyles.text14_22,
  fontWeight: '600 !important',
}

export const Text1422700 = {
  ...commonStyles.text14_22,
  fontSize: '16px solid',
  fontWeight: '700 !important',
}

export const Text1422500 = {
  ...commonStyles.text14_22,
  fontWeight: '500 !important',
}

export const buttonDisabled = {
  '&:disabled': {
    background: '#D9D9D9 !important',
    opacity: 1,
    color: 'white',
  },
  '&:hover': {
    opacity: '0.8',
  },
}

export const paddingForMobile = {
  base: '0rem 1.5rem',
}

export const inputFieldEditModal = {
  ...commonStyles.iconBorder,
  fontSize: '22px',
  height: '2.6rem',
  textAlign: 'center',
}

export const locationInput = {
  ...commonStyles.font20,
  border: 'none',
  color: '#1C1B1F',
}
