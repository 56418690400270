import React from 'react'

const Heart = (): JSX.Element => {
  return (
    <svg
      width="71"
      height="62"
      viewBox="0 0 71 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.4"
        d="M62.3335 31C62.3395 33.0315 63.1492 34.978 64.5857 36.4145C66.0221 37.851 67.9687 38.6606 70.0001 38.6667V50.1667C70.223 51.7288 70.0792 53.3213 69.5801 54.8182C69.081 56.3151 68.2402 57.6753 67.1245 58.7911C66.0087 59.9068 64.6486 60.7475 63.1517 61.2467C61.6548 61.7458 60.0622 61.8896 58.5001 61.6667H12.5001C10.938 61.8896 9.34548 61.7458 7.84858 61.2467C6.35167 60.7475 4.99151 59.9068 3.87575 58.7911C2.75999 57.6753 1.91928 56.3151 1.42016 54.8182C0.921049 53.3213 0.777239 51.7288 1.00012 50.1667V38.6667C3.03344 38.6667 4.98349 37.859 6.42127 36.4212C7.85905 34.9834 8.66678 33.0334 8.66678 31C8.66678 28.9667 7.85905 27.0167 6.42127 25.5789C4.98349 24.1411 3.03344 23.3334 1.00012 23.3334V11.8334C0.777239 10.2713 0.921049 8.67873 1.42016 7.18183C1.91928 5.68493 2.75999 4.32476 3.87575 3.209C4.99151 2.09324 6.35167 1.25253 7.84858 0.753415C9.34548 0.254302 10.938 0.11049 12.5001 0.333367H58.5001C60.0622 0.11049 61.6548 0.254302 63.1517 0.753415C64.6486 1.25253 66.0087 2.09324 67.1245 3.209C68.2402 4.32476 69.081 5.68493 69.5801 7.18183C70.0792 8.67873 70.223 10.2713 70.0001 11.8334V23.3334C67.9687 23.3394 66.0221 24.1491 64.5857 25.5856C63.1492 27.0221 62.3395 28.9686 62.3335 31Z"
        fill="#396CE8"
      />
      <path
        d="M50.546 29.252C48.5335 39.4832 35.5001 45.375 35.5001 45.375C35.5001 45.375 22.4668 39.4832 20.4543 29.252C19.2276 22.973 21.9071 16.6595 28.7228 16.625C30.2287 16.691 31.68 17.208 32.8884 18.1089C34.0969 19.0098 35.0068 20.253 35.5001 21.6773C35.9934 20.253 36.9033 19.0098 38.1118 18.1089C39.3203 17.208 40.7716 16.691 42.2775 16.625C49.1046 16.6595 51.7726 22.973 50.546 29.252Z"
        fill="#396CE8"
      />
    </svg>
  )
}

export default Heart
