/* eslint-disable */

import { Box, Flex, Heading, Text, Button, Image, useMediaQuery } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { authRegisterCreate, IPropsSignUpPage, IAuthCreate, IAuthError } from './SignUpPage.types'
import { AxiosError } from 'axios'
import DSInput from '../../components/DSInput/DSInput'
import LoginSide from '../../Assets/Images/LoginSide.svg'
import Person from '../../Assets/Images/Person'
import Flag from '../../Assets/Images/Flag'
import LockIcon from '../../Assets/Images/LockIcon'
import MailIcon from '../../Assets/Images/MailIcon'
import { showToast } from '../../components/Toaster/Toaster'
import { APIMessages, InputName, minWidth } from '../../Content/Contants'
import { endpoints } from '../../Content/Endpoints'
import { LOGIN, HOME } from '../../Routes/Routes'
import { commonStyles } from '../../styles/commonStyles'
import RightSidePasswordIcon from '../../components/RightSidePasswordIcon/RightSidePasswordIcon'
import { Header } from '../../Layout/Header/Header'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import ButtonAuth from '../../components/MediaOAuth/MediaOauth'
import arrow from '../../Assets/arrow.svg'
import imageCover from '../../Assets/Images/ImageCover.png'

const SignUpPage = (): any => {
  const [largeScreen992] = useMediaQuery(minWidth)
  const [smallScreen992] = useMediaQuery('(max-width: 992px)')
  const [isLargeScreen991] = useMediaQuery('(min-width: 991px)')
  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(false)

  const defaultFontSize = {
    fontSize: isLargeScreen991 ? '16px !important' : undefined,
  }

  const [formValue, setFormValue] = useState<IAuthCreate>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone: '',
  })

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [isLargeScreen] = useMediaQuery('(min-width: 1057px)')
  const [smallerScreen] = useMediaQuery('(min-width: 675px)')

  const [errors, setErrors] = useState<IAuthError>()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const checkValidEmail: any = errors?.email?.length ? errors?.email.length : undefined

  const handleButtonClick = () => {
    if (!currentPage) {
      setCurrentPage(true)
    } else {
      setCurrentPage(false)
    }
  }

  useEffect(() => {
    setIsButtonEnabled(errors?.first_name === '' && errors?.last_name === '' && errors?.email === '' && errors?.phone === '' && errors?.password === '' && formValue.password === formValue.confirm_password)
  }, [errors])

  const onSubmitHandler = (event: any): void => {
    event.preventDefault()
    authRegisterCreate(endpoints.SIGNUP_ROUTE, formValue, (result) => {
      if (result instanceof AxiosError) {
        const { response } = result
        if (response?.status === 400) {
          const message = Object.values(response.data)?.[0]?.[0]
          showToast({
            title: 'Error!',
            description: message,
            status: 'error',
          })
        } else {
          showToast({
            title: 'Error!',
            description: result?.message,
            status: 'error',
          })
        }
      } else {
        showToast({
          title: 'Success!',
          description: APIMessages.SIGNUP_SUCESS,
          status: 'success',
        })
        setToken(result.data.token)
        localStorage.setItem('token', result.data.token)
        navigate(HOME)
      }
    })
  }

  const authButtons = (
    <Flex sx={commonStyles.loginBottom}>
      <Box
        className="signupButton"
        width={'100%'}
        sx={{
          mt: '-45px',
          pos: 'relative',
          top: smallScreen992 ? '-10px' : '-66px',
          mb: largeScreen992 ? '40px' : 0,
        }}>
        <Button
          sx={{
            ...commonStyles.buttonFullWidth,
            height: 50,
            fontSize: 16,
            mb: smallScreen992 ? '50px' : undefined,
          }}
          onClick={onSubmitHandler}
          isDisabled={!isButtonEnabled}>
          Register
        </Button>
      </Box>
      <ButtonAuth />
      <Box sx={{ mr: 'auto' }}>
        <Text sx={{ ...commonStyles.font20, mt: 4, fontSize: 14, lineHeight: 1.5 }}>
          By clicking on the 'Next' or using your gmail to sign up, you've agreed to the <Box sx={{ ...commonStyles.blueColor, display: 'inline' }}> Terms</Box> and <Box sx={{ ...commonStyles.blueColor, display: 'inline' }}>Terms of Use</Box> and{' '}
          <Link to="/privacy" style={commonStyles.blueColor}>
            Privacy Policy
          </Link>
        </Text>
      </Box>
      <Text
        sx={{
          fontSize: 14,
          mb: '-10px',
          textAlign: 'left',
          mr: 'auto',
          fontWeight: 500,
          pos: 'relative',
          top: '-10px',
        }}>
        Already have an account.{' '}
        <Link to="/login" style={commonStyles.blueColor}>
          Login
        </Link>
      </Text>
    </Flex>
  )

  const commonComponent = (
    <Box sx={{ w: '100%', mx: 'auto' }}>
      <Box>
        <Header headerFor="AUTH" />
        <MobileHeader headerFor="AUTH" />
        <Box
          sx={{
            display: 'flex',
            justifyContent: isLargeScreen ? 'space-between' : 'center',
          }}>
          <Box
            sx={{
              display: isLargeScreen ? 'flex' : 'none',
              alignItems: 'stretch',
            }}>
            <Image
              src={imageCover}
              alt=""
              sx={{
                display: 'flex',
                height: '100%',
                position: 'fixed',
                mw: '25%',
                left: 0,
                top: 0,
                bottom: 0,
              }}
            />
          </Box>
          <Box sx={{ width: isLargeScreen ? '75%' : '90%' }}>
            {largeScreen992 && (
              <Box flex={3} position="relative">
                <Image src={LoginSide} alt="sidebar img" position={'fixed'} height={'100%'} />
              </Box>
            )}
            <Heading
              sx={{
                ...commonStyles.font40,
                marginTop: '100px',
                mt: isLargeScreen991 ? 20 : '80px',
                top: isLargeScreen991 ? '20px' : undefined,
                pos: 'relative',
              }}>
              Let's Get Started
            </Heading>
            <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={defaultFontSize}>
              Receive price alerts, savings, and news on your prescriptions.
            </Text>
            <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={{ ...defaultFontSize, mb: '-25px' }}>
              Already have an account?
              <Link to={LOGIN}>
                <Box style={{ ...commonStyles.blueColor, display: 'inline' }}>{'   '}Log in</Box>
              </Link>
            </Text>
            <Flex
              mt={commonStyles.margin3}
              sx={{
                ...commonStyles.FormContainer,
                alignItems: isLargeScreen991 ? 'flex-end' : 'flex-start',
                gap: 4,
              }}>
              <DSInput formLabel="Enter your email address" type="email" placeHolder="example@mail.com" Icon={largeScreen992 ? <MailIcon /> : <MailIcon width={20} height={16} />} isRequired={true} setFormValue={setFormValue} name={InputName.EMAIL} error={errors?.email} setErrors={setErrors} formValue={formValue?.email} />
              <Button
                className="button"
                onClick={handleButtonClick}
                sx={{
                  h: '50px',
                  w: smallerScreen ? '20%' : '100%',
                  mt: smallScreen992 ? '10px' : undefined,
                  fontSize: 16,
                  bgColor: !checkValidEmail ? '#556AB0' : '#D9D9D9',
                  color: !checkValidEmail ? '#fff' : '#000',
                }}>
                Sign up
              </Button>
            </Flex>
            {authButtons}
          </Box>
        </Box>
      </Box>
    </Box>
  )

  let confirmError
  if (formValue?.password !== formValue?.confirm_password) {
    confirmError = errors?.confirm_password
  } else {
    confirmError = ''
  }

  const emailButton = <>{commonComponent}</>

  return !currentPage ? (
    <Box sx={{ w: '90%', mx: 'auto' }}>{emailButton}</Box>
  ) : (
    <Box>
      <Header headerFor="AUTH" />
      <MobileHeader headerFor="AUTH" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: isLargeScreen ? 'space-between' : 'center',
        }}>
        <Box
          sx={{
            display: isLargeScreen ? 'flex' : 'none',
            alignItems: 'stretch',
            // height: '1300px',
          }}>
          <Image
            src={imageCover}
            alt=""
            sx={{
              flex: 1,
              display: 'flex',
              height: '100%',
              position: 'fixed',
              mw: '25%',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </Box>
        <Box sx={{ width: isLargeScreen ? '75%' : '90%' }}>
          <Flex mt={'4rem'}>
            {largeScreen992 && (
              <Box flex={3} position="relative">
                <Image src={LoginSide} alt="sidebar img" position={'fixed'} height={'100%'} />
              </Box>
            )}
            <Box flex={7} p={commonStyles.ProductHeaderDisplay}>
              <Image sx={{ cursor: 'pointer' }} onClick={handleButtonClick} src={arrow} alt="arrow icon" w="24px" />
              <Heading sx={commonStyles.font40}>Continue account setup</Heading>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={defaultFontSize}>
                Receive price alerts, savings, and news on your prescriptions.
              </Text>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={{ ...defaultFontSize, mb: '-25px' }}>
                Already have an account?
                <Link to={LOGIN}>
                  <Box style={{ ...commonStyles.blueColor, display: 'inline' }}>{'   '}Log in</Box>
                </Link>
              </Text>
              <Flex mt={commonStyles.margin3} sx={commonStyles.FormContainer}>
                <DSInput formLabel="Enter your First Name" type="text" placeHolder="Ex. Nick" Icon={largeScreen992 ? <Person /> : <Person height={16} width={16} />} setFormValue={setFormValue} name={InputName.FIRST_NAME} error={errors?.first_name} setErrors={setErrors} isRequired={true} />
                <DSInput formLabel="Enter your Last Name" type="text" placeHolder="Ex. Jason" Icon={largeScreen992 ? <Person /> : <Person height={16} width={16} />} setFormValue={setFormValue} name={InputName.LAST_NAME} error={errors?.last_name} setErrors={setErrors} isRequired={true} />
              </Flex>
              <Flex mt={commonStyles.margin3} sx={{ ...commonStyles.FormContainer, mb: -10, mt: '10px' }}>
                <DSInput formLabel="Enter your Phone Number" type="phone" placeHolder="+234 0000000000" Icon={largeScreen992 ? <Flag /> : <Flag height={16} width={24} />} setFormValue={setFormValue} name={InputName.PHONE} error={errors?.phone} setErrors={setErrors} isRequired={true} />
                <DSInput formLabel="Enter your email address" type="email" placeHolder="example@mail.com" Icon={largeScreen992 ? <MailIcon /> : <MailIcon width={20} height={16} />} isRequired={true} setFormValue={setFormValue} name={InputName.EMAIL} error={errors?.email} setErrors={setErrors} formValue={formValue?.email} />
              </Flex>
              <Flex mt={commonStyles.margin3} sx={commonStyles.FormContainer}>
                <DSInput
                  formLabel="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  placeHolder="8 characters"
                  Icon={largeScreen992 ? <LockIcon /> : <LockIcon width={20} height={16} />}
                  rightIcon={<RightSidePasswordIcon showPassword={showPassword} />}
                  rightIconClickhandler={() => {
                    setShowPassword(!showPassword)
                  }}
                  setFormValue={setFormValue}
                  name={InputName.PASSWORD}
                  error={errors?.password}
                  setErrors={setErrors}
                  isRequired={true}
                />
                <DSInput
                  formLabel="confirm password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeHolder="8 characters"
                  Icon={largeScreen992 ? <LockIcon /> : <LockIcon width={20} height={16} />}
                  rightIcon={<RightSidePasswordIcon showPassword={showConfirmPassword} />}
                  rightIconClickhandler={() => {
                    setShowConfirmPassword(!showConfirmPassword)
                  }}
                  setFormValue={setFormValue}
                  name={InputName.CONFIRM_PASSWORD}
                  error={confirmError}
                  setErrors={setErrors}
                  isRequired={true}
                  formValue={formValue.confirm_password}
                />
              </Flex>
              {authButtons}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default SignUpPage
