import React, { useContext } from 'react'
import { forwardRef, Menu, MenuItem, MenuList, MenuButton, IconButton, useMediaQuery } from '@chakra-ui/react'
import Iprops from './DSMenu.types'
import ProfileIcon from '../../Assets/Images/ProfileIcons'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'

const style = {
  menuButton: {
    backgroundColor: 'inherit',
    border: 'none',
  },
  menuItem: {
    height: '40px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '40px',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    backgroundColor: 'white',
    border: 'none',
  },
  menuList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '20px 30px',
    gap: '42px',
    position: 'absolute',
    width: '300px',
    height: '180px',
    right: '-47px',
    top: '0px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
}

const DSMenu = forwardRef<Iprops, 'div'>((props, ref) => {
  const { menuItems, styleData, onMenuItemClickHandler, ...restProps } = props
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const { token } = useContext(AuthContext)

  const menuItemClickHandler = (e: any): void => {
    onMenuItemClickHandler(e.target.innerText)
  }

  return (
    <Menu {...restProps} placement={'bottom-start'} preventOverflow={true} flip={true} direction={'ltr'}>
      <MenuButton as={IconButton} aria-label="Options" icon={largeScreen992 && token !== null ? <ProfileIcon /> : <ProfileIcon height={28} width={28} />} ref={ref} variant="outline" sx={style.menuButton} />
      <MenuList sx={style.menuList}>
        {menuItems?.map((menuItem: String) => (
          <MenuItem onClick={menuItemClickHandler} sx={{ ...style.menuItem, ...styleData }} key={menuItem as string}>
            {menuItem}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
})

export default DSMenu

DSMenu.defaultProps = {
  menuItems: [],
  styleData: {},
}
