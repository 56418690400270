import { getToken } from '../utils/getToken'
import { axiosInstance } from './axios.config'

const swrConfig = {
  fetcher: async (url: string, token?: boolean) =>
    await axiosInstance
      .get(
        url,
        token === true
          ? {
              headers: { Authorization: `token ${getToken() ?? ''}` },
            }
          : {}
      )
      .then((res) => res.data),
}

export default swrConfig
