import { Box, Button, Center, Flex, Heading, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EditIcon from '../../Assets/Images/EditIcon'
import ForwardArray from '../../Assets/Images/ForwardArray'
import EditProductModal from '../EditProductModal/EditProductModal'
import { BILL, CART, PRODUCT } from '../../Routes/Routes'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsProductListing, IPropsResult, addProduct, deleteProduct, updateProduct } from './ProductListing.types'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import BackButton from '../../Assets/Images/BackButton'
import Pagination from '../Pagination/Pagination'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import CustomAlertDialog from '../CustomAlertDialog/CustomAlertDialog'
import { showToast } from '../Toaster/Toaster'
import { APIMessages, itemsPerPage } from '../../Content/Contants'
import DeliveryIcon from '../../Assets/Images/DeliveryIcon'
import ProductItem from '../ProductItem/ProductItem'

const ProductsListing: React.FC<IPropsProductListing> = ({ data, type, mutate, sepcificPharmacyProduct, dataWithNoZeroQuantity }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const navigate = useNavigate()
  const location = useLocation()
  const { token, setFirstTimeUser } = useContext(AuthContext)

  const [localCartItems, setLocalCartItems] = useState<IPropsResult[]>([])
  const [cartItem, setCartItem] = useState<IPropsResult>()
  const [quantity, setQuantity] = useState<number>(1)
  const [quantityInStock, setQuantityInStock] = useState<number>(1)

  const [idToUpdate, setIdToUpdate] = useState<number>()
  const [productDeleteID, setProductDeleteID] = useState<number>()
  const [modalToOpen, setModalToOpen] = useState<'Update' | 'Delete' | ''>('')
  const [currentItems, setCurrentItems] = useState<IPropsResult[]>(data?.results?.slice(0, itemsPerPage) ?? [])
  const [groupedData, setGroupedData] = useState<IPropsResult[][] | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const { notificationCartCount, setNotificationCartCount } = useContext(CartContext)

  useEffect(() => {
    const cartData = localStorage.getItem('cart')
    if (cartData !== null) setLocalCartItems(JSON.parse(cartData))
  }, [])

  const isCartDisabled: boolean = useMemo(() => (data?.results ?? localCartItems)?.filter((item: IPropsResult) => item.quantity_in_stock !== 0)?.length !== (data?.count ?? localCartItems.length), [data, localCartItems])

  useEffect(() => {
    const resultData = (data?.results ?? localCartItems)?.reduce((groups: any[], item: IPropsResult) => {
      const items = groups.find((group) => group?.[0].business?.name === item?.business?.name)
      if (items != null) {
        items.push(item)
      } else {
        groups.push([item])
      }
      return groups
    }, [])
    setGroupedData(resultData)
  }, [data, localCartItems])

  useEffect(() => {
    if (dataWithNoZeroQuantity != null) setCurrentItems(dataWithNoZeroQuantity?.slice(0, itemsPerPage))
  }, [dataWithNoZeroQuantity])

  const dataExist = data?.results != null && data?.results?.length > itemsPerPage

  const showSpecificProduct = notificationCartCount > 0 && sepcificPharmacyProduct != null && sepcificPharmacyProduct?.length > 0

  const onModalOpenHandler = (productItem: IPropsResult): void => {
    onOpen()
    setQuantityInStock(productItem?.quantity_in_stock)
    setModalToOpen('Update')
    setCartItem(productItem)
  }

  const addProductHandler = (): void => {
    const payload = {
      product_id: cartItem?.id ?? 1,
      business_id: cartItem?.business.id ?? 1,
      quantity,
    }
    setFirstTimeUser(false)
    setIsLoading(true)

    if (cartItem !== undefined)
      addProduct({
        payload,
        cartItem,
        localCartItems,
        quantity,
        setLocalCartItems,
        token,
      })
        .then(() => {
          setNotificationCartCount(notificationCartCount + 1)
          showToast({
            title: 'Success!',
            description: APIMessages.ADD_TO_CART,
            status: 'success',
          })
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
          if (err?.response === undefined)
            showToast({
              title: 'Error!',
              description: APIMessages.ADD_TO_CART_ERROR,
              status: 'error',
            })
          else {
            const messageArray = Object.values<string[]>(err?.response?.data)?.[0]
            const message: string = messageArray[0]
            showToast({
              title: 'Error!',
              description: message,
              status: 'error',
            })
          }
        })
        .finally(() => {
          setQuantity(1)
          onClose()
        })
  }

  const deleteProductHandler = (): void => {
    if (mutate !== undefined && productDeleteID !== undefined)
      deleteProduct({
        id: productDeleteID,
        setLocalCartItems,
        localCartItems,
        setNotificationCartCount,
        mutate,
        token,
        setIsLoading,
        onClose,
        setQuantity,
      })
  }

  const updateCartItemHandler = (): void => {
    if (idToUpdate !== undefined && mutate !== undefined)
      updateProduct({
        setLocalCartItems,
        quantity,
        idToUpdate,
        mutate,
        token,
        setIsLoading,
        setQuantity,
        onClose,
      })
  }

  const onContinueButton = (): void => {
    navigate(PRODUCT)
  }

  return (
    <Box my={type === 'bill' || type === 'product' ? commonStyles.spacingForBillPage : commonStyles.spacingFromHeader} mx="auto" maxW={commonStyles.containerMaxWidth}>
      {type === 'Cart' && !largeScreen992 && (
        <Button gap={'10px'} onClick={onContinueButton} backgroundColor={'inherit'} mb="2rem">
          {largeScreen992 ? <BackButton /> : <BackButton height={24} width={20} />}
          <Text variant={commonStyles.productHeaderVariant} color={commonStyles.productHeaderColor}>
            Continue search
          </Text>
        </Button>
      )}
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        {type !== 'bill' && <Heading variant={commonStyles.productListingVariantHeading}>{type === 'Cart' ? `${data?.count ?? localCartItems.length} product(s) in cart` : `${dataWithNoZeroQuantity?.length ?? localCartItems.length} result(s)`}</Heading>}
        {type === 'bill' && (
          <>
            <Heading sx={commonStyles.font28}>{data?.results?.length ?? localCartItems.length} items</Heading>
            <Button
              rightIcon={largeScreen992 ? <EditIcon /> : <EditIcon height={18} width={18} />}
              iconSpacing={'4'}
              sx={commonStyles.editOrderButton}
              onClick={() => {
                navigate(CART)
              }}>
              Edit Oder
            </Button>
          </>
        )}
      </Flex>
      {type === 'product' && showSpecificProduct && (
        <Box>
          <Flex justifyContent={'space-between'} flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" p={'1rem 4rem'} background={'#F7F8F9'} mt="2rem">
            <Heading sx={commonStyles.font1824} fontWeight="400">
              This item is also sold at <span style={{ fontWeight: '600' }}>{sepcificPharmacyProduct?.[0]?.business?.name}</span>
            </Heading>
          </Flex>
          {sepcificPharmacyProduct?.map((productItem: IPropsResult, index: number) => (
            <ProductItem productItem={productItem} key={index} type={'product'} onModalOpenHandler={onModalOpenHandler} onOpen={onOpen} setProductDeleteID={setProductDeleteID} setModalToOpen={setModalToOpen} setQuantity={setQuantity} setQuantityInStock={setQuantityInStock} setIdToUpdate={setIdToUpdate} />
          ))}
        </Box>
      )}
      {type === 'product' && showSpecificProduct && (
        <Flex justifyContent={'space-between'} alignItems="center" p={'1rem 4rem'} background={'#F7F8F9'} mt="2rem">
          <Heading sx={commonStyles.font1824} fontWeight="400" textAlign={'center'}>
            Try other pharmacies
          </Heading>
        </Flex>
      )}
      <Box>
        {type === 'Cart' &&
          groupedData?.map((pharmacyData: IPropsResult[], index: number) => (
            <div key={index}>
              <Flex justifyContent={'space-between'} flexDirection={{ base: 'column', lg: 'row' }} alignItems="center" p={'1rem 4rem'} background={'#F7F8F9'} mt="2rem">
                <Heading sx={commonStyles.font1824} fontWeight="600">
                  {pharmacyData?.[0]?.business?.name?.charAt(0).toUpperCase() + pharmacyData?.[0]?.business?.name?.slice(1)}
                </Heading>
              </Flex>
              {pharmacyData?.map((productItem: IPropsResult) => {
                return <ProductItem productItem={productItem} key={index} type={'Cart'} onModalOpenHandler={onModalOpenHandler} onOpen={onOpen} setProductDeleteID={setProductDeleteID} setModalToOpen={setModalToOpen} setQuantity={setQuantity} setQuantityInStock={setQuantityInStock} setIdToUpdate={setIdToUpdate} />
              })}
            </div>
          ))}
        {type === 'product' && currentItems?.map((productItem: IPropsResult, index: number) => <ProductItem productItem={productItem} key={index} type={'product'} onModalOpenHandler={onModalOpenHandler} onOpen={onOpen} setProductDeleteID={setProductDeleteID} setModalToOpen={setModalToOpen} setQuantity={setQuantity} setQuantityInStock={setQuantityInStock} setIdToUpdate={setIdToUpdate} />)}
        {type === 'bill' && (data?.results ?? localCartItems)?.map((productItem: IPropsResult, index: number) => <ProductItem productItem={productItem} key={index} type={'bill'} onModalOpenHandler={onModalOpenHandler} onOpen={onOpen} setProductDeleteID={setProductDeleteID} setModalToOpen={setModalToOpen} setQuantity={setQuantity} setQuantityInStock={setQuantityInStock} setIdToUpdate={setIdToUpdate} />)}
      </Box>

      {type === 'Cart' && (
        <Flex flexDirection={{ base: 'column', lg: 'row' }} justifyContent={'center'} alignItems="center" p={{ base: '1rem', lg: '1rem 4rem' }} background={'#F7F8F9'} gap="1rem" my="3rem">
          <DeliveryIcon />
          <Heading sx={commonStyles.font1824} fontWeight="500" textAlign={'center'}>
            Added items in cart with different locations influences delivery charges.
          </Heading>
        </Flex>
      )}

      {type === 'Cart' && (
        <Center>
          <Button sx={commonStyles.productListingButton} mt={'2rem'} isDisabled={isCartDisabled} rightIcon={largeScreen992 ? <ForwardArray fill={isCartDisabled ? 'gray' : '#323232'} /> : <ForwardArray height={20} width={20} fill={isCartDisabled ? 'gray' : '#323232'} />} onClick={() => navigate(BILL)}>
            Continue{' '}
          </Button>
        </Center>
      )}
      {type === 'product' && dataExist && <Pagination count={dataWithNoZeroQuantity?.length ?? 0} data={dataWithNoZeroQuantity ?? []} setCurrentItems={setCurrentItems} />}
      <EditProductModal isOpen={isOpen && modalToOpen === 'Update'} onClose={onClose} setQuantity={setQuantity} quantity={quantity} isLoading={isLoading} addProductHandler={location.pathname === CART ? updateCartItemHandler : addProductHandler} quantityInStock={quantityInStock} />
      <CustomAlertDialog isOpen={isOpen && modalToOpen === 'Delete'} onClose={onClose} isLoading={isLoading} deleteProductHandler={deleteProductHandler} />
    </Box>
  )
}

export default ProductsListing
