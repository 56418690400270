import React, { createContext, useEffect, useContext, useState } from 'react'
import swrConfig from '../../config/swr.Config'
import useSWR from 'swr'
import { CartContextProps } from '../Context.types'
import { endpoints } from '../../Content/Endpoints'
import { AuthContext } from '../AuthContext.Provider/AuthContext'
import { CartContextType } from './CartContextType'
import CartContextDefault from './CartContextDefault'

export const CartContext = createContext<CartContextType>(CartContextDefault)

const CartContextProvider: React.FC<CartContextProps> = ({ children }) => {
  const [notificationCartCount, setNotificationCartCount] = useState<number>(0)
  const { token } = useContext(AuthContext)

  const { fetcher } = swrConfig
  const { data: cartListing } = useSWR(token !== null && token !== '' ? [endpoints.CART, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  useEffect(() => {
    if (cartListing?.count !== undefined) setNotificationCartCount(cartListing?.count)
    else {
      const cartData = localStorage.getItem('cart')
      if (cartData !== null) setNotificationCartCount(JSON.parse(cartData)?.length)
    }
  }, [cartListing])

  return <CartContext.Provider value={{ notificationCartCount, setNotificationCartCount }}>{children}</CartContext.Provider>
}

export default CartContextProvider
