import { axiosInstance } from '../../config/axios.config'
import { ILoadsMore } from '../DrugAutocomplete/DrugAutocomplete.types'
import { IPosition } from '../LocationModal/LocationModal.types'

export interface IPropsFindDrug {
  userAddress: string
  position: IPosition
}

export interface IOptions {
  value?: string
  label?: string
}

export const loadOptions = (
  input: string,
  url: string | null,
  setLoadMore: React.Dispatch<React.SetStateAction<ILoadsMore>>,
  setOptions: React.Dispatch<React.SetStateAction<IOptions[] | undefined>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
): void => {
  setIsLoading(true)
  const resultingURL = url?.replace(process.env.REACT_APP_API_URL, '')
  axiosInstance
    .get(resultingURL !== undefined ? resultingURL : `search/${input}`)
    .then((response) => {
      setLoadMore({
        next: response?.data?.next,
        prev: response?.data?.previous,
      })
      const data = response?.data?.results
      const optionsArray = data?.map((item: any) => ({
        label: item.name,
        value: item.id,
      }))
      setOptions(() => optionsArray)
      setIsLoading(false)
    })
    .catch(() => {})
}
