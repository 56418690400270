import React from 'react'

const DeliveryIcon = ({ size }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59978_97506)">
        <path
          d="M38 14C38 11.8 36.2 10 34 10H28V14H34V19.3L27.04 28H20V18H12C7.58 18 4 21.58 4 26V32H8C8 35.32 10.68 38 14 38C17.32 38 20 35.32 20 32H28.96L38 20.7V14ZM14 34C12.9 34 12 33.1 12 32H16C16 33.1 15.1 34 14 34Z"
          fill="#323232"
        />
        <path d="M20 12H10V16H20V12Z" fill="#323232" />
        <path
          d="M38 26C34.68 26 32 28.68 32 32C32 35.32 34.68 38 38 38C41.32 38 44 35.32 44 32C44 28.68 41.32 26 38 26ZM38 34C36.9 34 36 33.1 36 32C36 30.9 36.9 30 38 30C39.1 30 40 30.9 40 32C40 33.1 39.1 34 38 34Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_59978_97506">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeliveryIcon

DeliveryIcon.defaultProps = {
  size: 48,
}
