import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
  Heading,
  Spinner,
} from '@chakra-ui/react'
import { ErrorMessages } from '../../Content/Contants'
import { CustomAlertProps } from './CustomAlertDialog.types'
import { commonStyles, delteModalContent } from '../../styles/commonStyles'

const CustomAlertDialog: React.FC<CustomAlertProps> = ({
  isOpen,
  onClose,
  deleteProductHandler,
  isLoading,
}) => {
  const cancelRef = React.useRef(null)

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent
          p={5}
          height={{ base: '222px', lg: '330px' }}
          m="7rem">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Heading
              variant={{ base: 'mobileBold20', lg: 'Bold' }}
              color={'#1C1B1F'}>
              Delete Product
            </Heading>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text sx={delteModalContent}>{ErrorMessages.DELETE_MSG}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              backgroundColor={'white'}
              color="black"
              variant={commonStyles.locationModalFooterButtonVariant}
              border={'1px solid #E6E1E5'}
              padding={'0.5rem 2rem !important'}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={isLoading ? () => { } : deleteProductHandler}
              ml={3}
              variant={commonStyles.locationModalFooterButtonVariant}
              color="white"
              backgroundColor={'red'}
              width={{ base: '112px', lg: '155px' }}
              height={{ base: '38px', lg: '58px' }}
              padding={'0.5rem 2rem !important'}>
              {isLoading ? <Spinner /> : 'Delete'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default CustomAlertDialog
