/* eslint-disable linebreak-style */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Spinner } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME } from '../../Routes/Routes'
import HomePage from '../HomePage/HomePage'

const LinkedInPage = (): JSX.Element => {
  const [linkedInUserInfo, setLinkedInUserInfo] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [lkdCode, setLkdCode] = useState('')
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(window.location.search)
  const code: any = urlParams.get('code')

  useEffect(() => {
    if (pathname === '/linkedin') {
      void handleAccessToken()
    }
  }, [code, lkdCode, linkedInUserInfo, pathname, navigate])

  const handleAccessToken = async (): Promise<void> => {
    setLkdCode(code)
    const response = await fetch(`${process.env.REACT_APP_BASE_URL_OAUTH}/api/v2/auth/linkedin_auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: lkdCode }),
    })

    if (!response.ok) {
      throw new Error('Failed to obtain access token')
    }

    if (response.ok) {
      const data = await response.json()
      setLoading(true)
      setLinkedInUserInfo(data)

      localStorage.setItem('token', data?.access_token)
      localStorage.setItem('picture', data?.userData?.picture ?? 'https://img.icons8.com/fluency/48/user-male-circle--v1.png')
      localStorage.setItem('email', data?.userData?.email)
      localStorage.setItem('name', `${String(data?.userData?.given_name)} ${String(data?.userData?.family_name)}`)
      localStorage.setItem('id_token', data?.id_token)
      localStorage.setItem('oauth_provider', 'linkedin')

      const oauthToken = localStorage.getItem('token')
      const oauthPicture = localStorage.getItem('picture') ?? ''
      const oauthEmail = localStorage.getItem('email') ?? ''
      const oauthName = localStorage.getItem('name') ?? ''
      const oauthIDToken = localStorage.getItem('id_token')
      const oauthProvider = localStorage.getItem('oauth_provider')

      if (oauthToken !== undefined && oauthIDToken !== undefined && oauthPicture !== undefined && oauthEmail !== undefined && oauthName !== undefined && oauthIDToken !== undefined && oauthProvider !== undefined) {
        navigate(HOME)
      }
    }
  }

  if (!loading) {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </div>
      </>
    )
  }
  return <HomePage />
}

export default LinkedInPage
