/* eslint-disable indent */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React from 'react'
import { Box, Text, useMediaQuery } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import LinkedInButton from '../OauthButtons/LinkedInButton'
import GoogleButton from '../OauthButtons/GoogleButton'
import FacebookButton from '../OauthButtons/FacebookButton'

const ButtonAuth = (): any => {
  const isLargerThan991 = useMediaQuery('(min-width: 991px)')
  const { pathname } = useLocation()

  const [isSmallScreen] = useMediaQuery('(max-width: 991px)')

  const buttonAuth = {
    pos: 'relative',
    textAlign: 'center',
    top: isLargerThan991 && pathname === '/login' ? '-12.5px' : '-22px',
    backgroundColor: '#fff',
    padding: '0 10px',
    mt: isLargerThan991 && pathname === '/login' ? '20px' : '-70px',
    mb: isLargerThan991 && pathname === '/login' ? '-10px' : '-10px',
    fontSize: 16,
    my: isSmallScreen && pathname === '/signup' ? '-45px' : undefined,
    display: 'inline',
    zIndex: 1,
  }

  return (
    <>
      <style>
        {`
          @media only screen and (max-width: 990px) {
            .ButtonAuthHr {
              display: none
            }
          }
          @media only screen and (max-width: 990px) {
            .ButtonAuthOr {
              top: -10px;
              margin-bottom: 5px;
              margin-top: ${pathname === '/login' ? '25px' : undefined};
            }
          }

          @media only screen and (min-width: 991px) {
            .ButtonAuthHr {
              position: ${pathname === '/login' ? 'relative' : undefined};
              top: ${pathname === '/login' ? '15px !important' : undefined};
            }
          }
          `}
      </style>
      <Text className="ButtonAuthOr" sx={buttonAuth}>
        Or
      </Text>
      <hr
        style={{
          borderTop: '1px solid #ccc',
          width: '100%',
          position: 'relative',
          top: '-32px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 7,
          flexWrap: 'wrap',
          pos: 'relatve',
        }}>
        <GoogleButton />
        <LinkedInButton />
        <FacebookButton />
      </Box>
    </>
  )
}

export default ButtonAuth
