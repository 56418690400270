import React from 'react'

const Persons = (): JSX.Element => {
  return (
    <svg
      width="93"
      height="92"
      viewBox="0 0 93 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.75 18.6875C17.75 21.8631 20.3244 24.4375 23.5 24.4375C26.6756 24.4375 29.25 21.8631 29.25 18.6875V14.375C29.25 11.1994 26.6756 8.625 23.5 8.625C20.3244 8.625 17.75 11.1994 17.75 14.375V18.6875Z"
        fill="#FEC9A3"
      />
      <path
        d="M10.5625 44.5625H30.6875V34.5L33.5625 44.5625H40.75L37.5264 28.7929C37.2154 27.5489 36.4974 26.4445 35.4867 25.6553C34.4759 24.8661 33.2304 24.4375 31.948 24.4375H16.3125C14.7875 24.4375 13.325 25.0433 12.2466 26.1216C11.1683 27.2 10.5625 28.6625 10.5625 30.1875V44.5625Z"
        fill="#8FD0D9"
      />
      <path
        d="M30.6875 30.1875H16.3125V44.5625H30.6875V38.8125C31.2538 38.2462 31.7031 37.5739 32.0096 36.8339C32.3161 36.094 32.4738 35.3009 32.4738 34.5C32.4738 33.6991 32.3161 32.906 32.0096 32.1661C31.7031 31.4261 31.2538 30.7538 30.6875 30.1875Z"
        fill="#8FD0D9"
      />
      <path
        d="M17.0312 14.375C19.016 14.375 20.625 12.766 20.625 10.7812C20.625 8.79648 19.016 7.1875 17.0312 7.1875C15.0465 7.1875 13.4375 8.79648 13.4375 10.7812C13.4375 12.766 15.0465 14.375 17.0312 14.375Z"
        fill="#78462F"
      />
      <path
        d="M17.75 18.6875L22.0625 14.375C23.9532 15.3203 26.0381 15.8125 28.1519 15.8125H29.25V14.375C29.25 12.85 28.6442 11.3875 27.5659 10.3091C26.4875 9.2308 25.025 8.625 23.5 8.625C21.975 8.625 20.5125 9.2308 19.4341 10.3091C18.3558 11.3875 17.75 12.85 17.75 14.375V18.6875Z"
        fill="#92563A"
      />
      <path
        d="M19.1875 24.4375L24.9375 33.0625L29.25 24.4375H19.1875Z"
        fill="#348FD9"
      />
      <path
        d="M47.9375 34.5H40.75L36.4375 44.5625H43.625L47.9375 34.5Z"
        fill="#8FD0D9"
      />
      <path
        d="M53.6875 28.75H46.2903C45.4894 28.75 44.7044 28.973 44.0231 29.3941C43.3418 29.8151 42.7913 30.4176 42.4331 31.1339L40.75 34.5H48.1472C48.9481 34.5 49.7331 34.277 50.4144 33.8559C51.0957 33.4349 51.6462 32.8324 52.0044 32.1161L53.6875 28.75Z"
        fill="#FEC9A3"
      />
      <path
        d="M4.8125 44.5625H68.0625V81.9375H4.8125V44.5625Z"
        fill="#348FD9"
      />
      <path
        d="M4.8125 44.5625H68.0625V50.3125H4.8125V44.5625Z"
        fill="#459DE6"
      />
      <path d="M9.125 56.0625H12V58.9375H9.125V56.0625Z" fill="#459DE6" />
      <path d="M9.125 61.8125H12V73.3125H9.125V61.8125Z" fill="#459DE6" />
      <path
        d="M45.0625 63.25H39.3125V57.5H33.5625V63.25H27.8125V69H33.5625V74.75H39.3125V69H45.0625V63.25Z"
        fill="#E0E0DE"
      />
      <path
        d="M4.8125 50.3125H68.0625V53.1875H4.8125V50.3125Z"
        fill="#3086CD"
      />
      <path
        d="M81 23C81 19.8244 78.4256 17.25 75.25 17.25C72.0744 17.25 69.5 19.8244 69.5 23V27.3125C69.5 30.4881 72.0744 33.0625 75.25 33.0625C78.4256 33.0625 81 30.4881 81 27.3125V23Z"
        fill="#FEC9A3"
      />
      <path
        d="M68.0625 81.9375H75.25V87.6875H68.0625C67.3 87.6875 66.5687 87.3846 66.0296 86.8454C65.4904 86.3063 65.1875 85.575 65.1875 84.8125C65.1875 84.05 65.4904 83.3187 66.0296 82.7796C66.5687 82.2404 67.3 81.9375 68.0625 81.9375Z"
        fill="#34507B"
      />
      <path d="M75.25 81.9375H82.4375V87.6875H75.25V81.9375Z" fill="#2B4266" />
      <path
        d="M81 27.3125H75.25L70.9375 23H69.5C68.7375 23 68.0062 22.6971 67.4671 22.1579C66.9279 21.6188 66.625 20.8875 66.625 20.125C66.625 19.3625 66.9279 18.6312 67.4671 18.0921C68.0062 17.5529 68.7375 17.25 69.5 17.25H75.25C76.775 17.25 78.2375 17.8558 79.3159 18.9341C80.3942 20.0125 81 21.475 81 23V27.3125Z"
        fill="#92563A"
      />
      <path
        d="M68.0625 56.0625H82.4375V81.9375H68.0625V56.0625Z"
        fill="#92563A"
      />
      <path
        d="M73.8125 59.5908H76.6875V81.9373H73.8125V59.5908Z"
        fill="#78462F"
      />
      <path
        d="M68.0625 56.0625H82.4375L88.1875 56.1223V38.8125C88.1875 37.2875 87.5817 35.825 86.5034 34.7466C85.425 33.6683 83.9625 33.0625 82.4375 33.0625H68.0625C66.5375 33.0625 65.075 33.6683 63.9966 34.7466C62.9183 35.825 62.3125 37.2875 62.3125 38.8125V56.0625H68.0625Z"
        fill="#FFB531"
      />
      <path d="M66.625 38.8125H69.5V56.0625H66.625V38.8125Z" fill="#FAA50F" />
      <path d="M81 38.8125H83.875V56.0625H81V38.8125Z" fill="#FAA50F" />
      <path
        d="M62.3125 56.0625H68.0625V61.8125H62.3125V56.0625Z"
        fill="#FEC9A3"
      />
      <path
        d="M82.4375 56.0625H88.1875V61.8125H82.4375V56.0625Z"
        fill="#FEC9A3"
      />
      <path
        d="M56.5625 23H45.0625C44.3 23 43.5687 22.6971 43.0296 22.1579C42.4904 21.6188 42.1875 20.8875 42.1875 20.125V17.25L37.875 14.375L42.1875 11.5V7.1875C42.1875 6.425 42.4904 5.69373 43.0296 5.15457C43.5687 4.6154 44.3 4.3125 45.0625 4.3125H56.5625C57.325 4.3125 58.0563 4.6154 58.5954 5.15457C59.1346 5.69373 59.4375 6.425 59.4375 7.1875V20.125C59.4375 20.8875 59.1346 21.6188 58.5954 22.1579C58.0563 22.6971 57.325 23 56.5625 23Z"
        fill="#8FD0D9"
      />
      <path d="M49.375 14.375H52.25V18.6875H49.375V14.375Z" fill="#B7E7EB" />
      <path d="M49.375 8.625H52.25V11.5H49.375V8.625Z" fill="#B7E7EB" />
      <path
        d="M32.125 44.5624H29.25V38.8124C29.25 38.6236 29.2872 38.4367 29.3594 38.2623C29.4316 38.0879 29.5375 37.9294 29.671 37.7959C30.5452 36.9217 31.0363 35.7361 31.0363 34.4999C31.0363 33.2636 30.5452 32.078 29.671 31.2039L31.704 29.1709C33.0392 30.5095 33.8233 32.3004 33.9013 34.1894C33.9793 36.0784 33.3453 37.9279 32.125 39.3719V44.5624Z"
        fill="#76BBC4"
      />
      <path d="M14.875 30.1875H17.75V44.5625H14.875V30.1875Z" fill="#76BBC4" />
    </svg>
  )
}

export default Persons
