// @ts-nocheck
import { Box, Flex, Heading, Text, Button, Image, useMediaQuery } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { authLogin, IAuthLogin, IPropsLoginPage } from './LoginPage.types'
import LoginSide from '../../Assets/Images/LoginSide.svg'
import MailIcon from '../../Assets/Images/MailIcon'
import LockIcon from '../../Assets/Images/LockIcon'
import DSInput from '../../components/DSInput/DSInput'
import { showToast } from '../../components/Toaster/Toaster'
import { SIGNUP, HOME } from '../../Routes/Routes'
import { endpoints } from '../../Content/Endpoints'
import { APIMessages, InputName, minWidth } from '../../Content/Contants'
import { commonStyles } from '../../styles/commonStyles'
import RightSidePasswordIcon from '../../components/RightSidePasswordIcon/RightSidePasswordIcon'
import { Header } from '../../Layout/Header/Header'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import ButtonAuth from '../../components/MediaOAuth/MediaOauth'
import imageCover from '../../Assets/Images/ImageCover.png'

const LoginPage = ({ props }: IPropsLoginPage): JSX.Element => {
  const [largeScreen992] = useMediaQuery(minWidth)
  const [isLargeScreen] = useMediaQuery('(min-width: 1057px)')
  const [isLargeScreen991] = useMediaQuery('(min-width: 991px)')

  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext)
  const [formValue, setFormValue] = useState<IAuthLogin>({
    email: '',
    password: '',
  })

  const defaultFontSize = {
    fontSize: isLargeScreen991 ? '16px !important' : undefined,
  }

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const [errors, setErrors] = useState<IAuthLogin>()
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  useEffect(() => {
    setIsButtonEnabled(errors?.email === '' && errors?.password === '')
  }, [errors])

  const onSubmitHandler = (event: any): void => {
    event.preventDefault()
    authLogin(endpoints.LOGIN_ROUTE, formValue, (result) => {
      if (result instanceof AxiosError) {
        const { response } = result
        if (response?.status === 400) {
          const message = Object.values(response.data)?.[0]?.[0]
          showToast({
            title: 'Error!',
            description: message,
            status: 'error',
          })
        } else {
          showToast({
            title: 'Error!',
            description: result?.message,
            status: 'error',
          })
        }
      } else {
        showToast({
          title: 'Success!',
          description: APIMessages.LOGIN_SUCCESS,
          status: 'success',
        })
        localStorage.setItem('token', result.data.token)
        setToken(result.data.token)
        navigate(HOME)
      }
    })
  }

  return (
    <Box sx={{ w: '100%', mx: 'auto' }}>
      <Header headerFor="AUTH" />
      <MobileHeader headerFor="AUTH" />

      <Box sx={{ display: 'flex', justifyContent: isLargeScreen ? 'space-between' : 'center' }}>
        <Box
          sx={{
            display: isLargeScreen ? 'flex' : 'none',
            alignItems: 'stretch',
          }}>
          <Image
            src={imageCover}
            alt=""
            sx={{
              flex: 1,
              display: 'flex',
              height: '100%',
              position: 'fixed',
              mw: '25%',
              left: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </Box>
        <Box sx={{ width: isLargeScreen ? '75%' : '90%' }}>
          <Flex mt={'4rem'}>
            {largeScreen992 && (
              <Box flex={3}>
                <Image src={LoginSide} alt="sidebar img" position="fixed" />
              </Box>
            )}
            <Box flex={7} p={commonStyles.ProductHeaderDisplay}>
              <style>
                {`
                    @media (max-width: 991px) {
                      .HeadingWelcome {
                        margin-top: 0
                      }
                    }
                  `}
              </style>
              <Heading className="HeadingWelcome" sx={{ ...commonStyles.font40, marginTop: '100px', mt: isLargeScreen991 ? -10 : '80px', top: isLargeScreen991 ? '20px' : undefined, pos: 'relative' }}>
                Welcome back
              </Heading>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={defaultFontSize}>
                Enter your email address to sign in to your account.
              </Text>
              <Text variant={commonStyles.VariantBold} fontWeight={'500'} sx={{ ...defaultFontSize, mb: '-25px' }}>
                Don&apos;t have an account?
                <Link to={SIGNUP}>
                  <span style={commonStyles.blueColor}>{'   '}Sign up</span>
                </Link>
              </Text>
              <Flex mt={commonStyles.margin3} sx={{ ...commonStyles.FormContainer, alignItems: isLargeScreen991 ? 'flex-end' : 'flex-start', gap: 4 }}>
                <DSInput formLabel="Enter your email address" type="email" placeHolder="example@mail.com" name={InputName.EMAIL} Icon={largeScreen992 ? <MailIcon /> : <MailIcon width={20} height={16} />} setFormValue={setFormValue} error={errors?.email} setErrors={setErrors} isRequired={true} formValue={formValue?.email} />
                <DSInput
                  formLabel="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  placeHolder="8 characters"
                  name={InputName.PASSWORD}
                  Icon={largeScreen992 ? <LockIcon /> : <LockIcon width={20} height={16} />}
                  rightIcon={<RightSidePasswordIcon showPassword={showPassword} />}
                  rightIconClickhandler={() => {
                    setShowPassword(!showPassword)
                  }}
                  setFormValue={setFormValue}
                  error={errors?.password}
                  setErrors={setErrors}
                  isRequired={true}
                  formValue={formValue?.password}
                />
              </Flex>
              <Flex sx={{ ...commonStyles.loginBottom, position: 'relative', top: '-120px' }}>
                <Box width={'100%'}>
                  <Button sx={{ ...commonStyles.buttonFullWidth, height: '50px', fontSize: 16, mt: '105px', mb: '55px' }} onClick={onSubmitHandler} isDisabled={!isButtonEnabled}>
                    Login
                  </Button>
                </Box>
                <Box pos="relative" top="-25px" textAlign="center" mb="20px" w="100%">
                  <ButtonAuth />
                </Box>
                <Link to={'/forget_password'} style={{ marginLeft: 'auto' }}>
                  <style>
                    {`
                        @media (max-width: 991px) {
                            .TextForgotPassword {
                              margin-top: -12px;
                              margin-bottom: 16px;
                            }
                        }
                      `}
                  </style>
                  <Text className="TextForgotPassword" sx={{ ...commonStyles.font20, fontSize: 14, mt: -12 }} textDecoration={'underline'} color={'secondary'}>
                    Forgot your password ?
                  </Text>
                </Link>
                <Text sx={{ ...commonStyles.font20, mt: -3, mb: 'calc(-10rem + -10px)', fontSize: 14, lineHeight: 1.5 }}>
                  By clicking on the &lsquo;Next&rsquo; or using your gmail to sign up, you&apos;ve agreed to the <span style={commonStyles.blueColor}> Terms</span> and <span style={commonStyles.blueColor}>Terms of Use</span> and{' '}
                  <Link to="/privacy" style={commonStyles.blueColor}>
                    Privacy Policy
                  </Link>
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default LoginPage
