/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { CheckoutContextProps } from '../Context.types'
import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CartContext } from '../CartContext.Provider/CartContext'
import { LocationContext } from '../LocationContext.Provider/LocationContext'
import { IAddress, IAddressReceive } from '../../Pages/CheckoutPage/CheckoutPage.types'
import { endpoints } from '../../Content/Endpoints'
import { CheckoutContextType, FormData } from './CheckoutInterface'
import swrConfig from '../../config/swr.Config'
import useSWR from 'swr'
import { baseURL } from '../../config/axios.config'
import CheckoutContextDefault from './CheckoutContextDefault'

const defaultCheckoutContextValue: CheckoutContextType = CheckoutContextDefault

export const CheckoutContext = createContext<CheckoutContextType>(defaultCheckoutContextValue)

const CheckoutContextProvider: React.FC<CheckoutContextProps> = ({ children }) => {
  useEffect(() => {
    if (pathname !== '/login' && pathname !== '/signup') {
      void getProfileInfo()
    }
  }, [])

  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const [formValue, setFormValue] = useState<IAddress>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState<IAddress>()
  const [paymentMethod, setPaymentMethod] = useState('card')
  const [isDeliverChecked, setIsDeliverChecked] = useState(true)
  const [isButtonEnabled] = useState(false)
  const [personAddress, setPersonAddress] = useState<any>()
  const [profileDetail, setProfileDetail] = useState<any>()
  const [formSuccess, setFormSuccess] = useState('')
  const [formFail, setFormFail] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [query, setQuery] = useState<string>('')
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands

  const accessToken = localStorage.getItem('token')

  const { fetcher } = swrConfig
  const { data: addressListing } = useSWR(accessToken !== null && accessToken !== '' ? [endpoints.ADDRESS_LISTING, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const address = addressListing?.results?.length
  // console.log(address)

  const addressCount = address?.count
  const handleKeyDown = (event: any): void => {
    if (
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      (event.ctrlKey || event.metaKey) &&
      event.code === 'KeyK'
    ) {
      setShowModal(true)
      event.preventDefault()
    }
  }

  const handleFormClick = (): void => {
    setShowModal(true)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { pathname } = useLocation()

  const [searchParams] = useSearchParams()
  const businessId = searchParams.get('businessId')

  const { notificationCartCount } = useContext(CartContext)
  const location = useLocation()
  const amount = location.state !== null ? location.state.amount : undefined
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [suggestions, setSuggestions] = useState([])
  const { userLocation: preFilledlocation } = useContext(LocationContext)
  const [userLocation, setUserLocation] = useState<string>(preFilledlocation)
  const [shippingResult, setShippingResult] = useState<IAddressReceive[] | undefined>(undefined)

  const [previousAddressSelected, setPreviousAddressSelected] = useState<IAddressReceive | undefined>()

  const handleResultClick = (result: any): void => {
    setQuery(result)
    setShowDropdown(false)
  }

  const fetchSuggestions = async (): Promise<void> => {
    try {
      const apiUrl = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyC_rDhEDabNPPeyuDpfOcQbA4VASGZc9EQ&input=CorprateHouse,Gurugram`

      const response = await fetch(apiUrl)
      const data = await response.json()
      console.log(data)
      setSuggestions([])
    } catch (error) {
      setSuggestions([])
    }
  }

  const token: string | null = localStorage.getItem('token')
  const authorizationHeader = `token ${token ?? ''}`

  const addressEndpoint = `${baseURL}${endpoints.ADDRESS}`

  const getAddress = async (): Promise<void> => {
    const response = await fetch(addressEndpoint, {
      method: 'GET',
      headers: {
        Authorization: authorizationHeader,
      },
    })
    const data = await response.json()
    setPersonAddress(data)
    setShippingResult(data?.results)
  }

  useEffect(() => {
    if (pathname === '/checkout') {
      void getAddress()
    }
  }, [address, navigate, shippingResult])
  useEffect(() => {
    setShippingResult(personAddress?.results)
  }, [addressCount, shippingResult, navigate])

  const profileEndpoint = `${baseURL}${endpoints.ACCOUNT}`

  const getProfileInfo = async (): Promise<any> => {
    const response = await fetch(profileEndpoint, {
      method: 'GET',
      headers: {
        Authorization: authorizationHeader,
      },
    })
    const profileData = await response.json()
    setProfileDetail(profileData)
  }

  const first_name = profileDetail?.first_name ?? ''
  const last_name = profileDetail?.last_name ?? ''
  const address_line1 = profileDetail?.address_line1 ?? ''
  const address_line2 = profileDetail?.address_line2 ?? ''
  const phone_number1 = profileDetail?.phone_number1 ?? ''
  const phone_number2 = profileDetail?.phone_number2 ?? ''
  const additional_information = profileDetail?.additional_information ?? ''
  const region = profileDetail?.region ?? ''

  const initialFormData: FormData = {
    is_default_address: true,
    first_name,
    last_name,
    address_line1,
    address_line2,
    phone_number1,
    phone_number2,
    additional_information,
    region,
  }
  const [formData, setFormData] = useState<FormData>(initialFormData)

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault()

    try {
      const response = await fetch(addressEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authorizationHeader,
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        setFormSuccess('Form submitted successfully')
        setTimeout(() => {
          setFormSuccess('')
          setShowModal(false)
          onOpen()
        }, 3000)
      } else {
        setFormFail('Form submission failed')
        setTimeout(() => {
          setFormFail('')
        }, 3000)
      }
    } catch (error) {
      setFormFail('An error occurred')
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleCloseModal = (e: any): any => {
    if (e.target === e.currentTarget) {
      setShowModal(false)
    }
  }
  const onCloseModal = (): void => {
    setShowModal(false)
  }
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const handleInputFocus = (): void => {
    setShowDropdown(true)
  }

  const regionList: string[] = ['lagos', 'ogun', 'oyo', 'cross-rivers', 'others']
  const textToCheck = 'lagosgunycr-riveth'

  const sendButton = formData.first_name !== '' && formData.last_name !== '' && formData.address_line1 !== '' && (query === 'lagos' || query === 'ogun' || query === 'oyo' || query === 'cross-rivers' || query === 'others')

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      if (pathname !== '/login' && pathname !== '/signup') {
        fetchSuggestions().catch(() => {})
      }
    }, 500)
    return () => clearTimeout(debounceFn)
  }, [userLocation])

  const handleTextAreaValueChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>): void => {
    if (e.target.value === 'cash') {
      setFormValue((prevValue: any) => ({
        ...prevValue,
        [e.target.name]: e.target.value,
        transaction_no: 'cash_on_delivery',
      }))
    } else {
      setFormValue((prevValue: any) => ({
        ...prevValue,
        [e.target.name]: e.target.value,
      }))
    }
  }

  const onDeliverSelectHandler = (): void => {
    setIsDeliverChecked(!isDeliverChecked)
    const checkedEvent: any = {
      target: { value: isDeliverChecked ? 'cash' : '' },
    }
    handleTextAreaValueChange(checkedEvent)
  }

  return (
    <CheckoutContext.Provider
      value={{
        largeScreen992,
        isOpen,
        onOpen,
        onClose,
        businessId,
        notificationCartCount,
        amount,
        previousAddressSelected,
        setPreviousAddressSelected,
        setUserLocation,
        formValue,
        setErrors,
        paymentMethod,
        setPaymentMethod,
        isButtonEnabled,
        formSuccess,
        formFail,
        showModal,
        handleResultClick,
        handleSubmit,
        handleChange,
        handleCloseModal,
        onCloseModal,
        showDropdown,
        handleInputFocus,
        regionList,
        textToCheck,
        sendButton,
        setShowModal,
        userLocation,
        onDeliverSelectHandler,
        shippingResult,
        setQuery,
        query,
        formData,
        isDeliverChecked,
        handleFormClick,
        addressCount,
      }}>
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutContextProvider
