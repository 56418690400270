import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import Footer from '../../Layout/Footer/Footer'
import CartEmpty from '../../components/CartEmpty/CartEmpty'
import { commonStyles } from '../../styles/commonStyles'
import { IPropsAccountPage, Item, Order } from './AccountPage.types'
import swrConfig from '../../config/swr.Config'
import useSWR from 'swr'
import { endpoints } from '../../Content/Endpoints'
import ContentWrap from '../../components/ContentWrap/ContentWrap'
import { Header } from '../../Layout/Header/Header'
import moment from 'moment'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'

const AccountPage = ({ props }: IPropsAccountPage): JSX.Element => {
  const headingStyle = {
    ...commonStyles.font24_40,
    fontWeight: '500',
    color: '#787579',
  }
  const columnStyle = {
    ...commonStyles.font24_40,
    fontWeight: '500',
    color: '#484649',
  }

  const [totalQuantity, setTotalQuantity] = useState<number[]>([])

  const { fetcher } = swrConfig
  const { token, name, email } = useContext(AuthContext)

  const { data: profileData, isLoading, error } = useSWR(token !== null && token !== '' ? [endpoints.ACCOUNT, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const { data: OrdersData, isLoading: isOrderDataLoading, error: OrderDataError } = useSWR([endpoints.ORDERS, true], async ([url, useToken]) => await fetcher(url, useToken))

  const firstName: string = profileData?.first_name ?? ''
  const lastName: string = profileData?.last_name ?? ''
  const fullName: string = firstName + ' ' + lastName ?? null

  useEffect(() => {
    setTotalQuantity(OrdersData?.results?.map((order: Order) => order.items?.reduce((total: number, obj: Item): number => total + obj.quantity, 0)))
  }, [OrdersData])

  const tokenLength = token?.length
  const checkToken = tokenLength !== null

  return (
    <>
      <Header headerFor="HOME" />
      <MobileHeader headerFor="NORMAL" />
      <Box maxW={commonStyles.containerMaxWidth} my={commonStyles.spacingFromHeader} mx="auto">
        <ContentWrap isFetching={isLoading} isError={error} count={1}>
          <Box my={commonStyles.paddingLg}>
            <Heading sx={commonStyles.font2424}>{fullName}</Heading>
            <Heading sx={commonStyles.font2424}>{profileData?.email}</Heading>
            <Heading sx={commonStyles.font2424}>{profileData?.phone}</Heading>
          </Box>
        </ContentWrap>

        {checkToken && (
          <Box my={commonStyles.paddingLg}>
            <Heading sx={commonStyles.font2424}>{name}</Heading>
            <Heading sx={commonStyles.font2424}>{email}</Heading>
          </Box>
        )}

        <Heading sx={commonStyles.font32_lightBlack}>Order Activity</Heading>
        {OrdersData?.count > 0 && (
          <Heading mt={{ base: '2rem', lg: '4rem' }} sx={commonStyles.font28}>
            Recent Orders
          </Heading>
        )}

        <TableContainer mt={{ base: '1rem', lg: '2rem' }} mb={{ base: '7rem', lg: '10rem' }} overflowY="auto" display="block" height={{ base: '36rem', lg: '45rem' }}>
          <Table variant="unstyled">
            <Thead background={'#F7F8F9'} borderBottom={'1px solid #C9C5CA'} position="sticky" top={0} zIndex={1}>
              <Tr>
                <Th sx={headingStyle}>Order ID</Th>
                <Th sx={headingStyle}>Quantity</Th>
                <Th sx={headingStyle}>Cost</Th>
                <Th sx={headingStyle}>Status</Th>
                <Th sx={headingStyle}>Date/time</Th>
              </Tr>
            </Thead>
            <ContentWrap
              isFetching={isOrderDataLoading}
              isError={OrderDataError}
              count={OrdersData?.count}
              NoDataPage={
                <Tr>
                  <Td colSpan={4}>
                    <CartEmpty cartText={'You currently do not have any order history, continue by searching for a drug.'} />
                  </Td>
                </Tr>
              }
              isInsideTable={true}>
              {OrdersData?.count > 0 ? (
                <Tbody>
                  {OrdersData?.results?.map((order: Order, index: number) => (
                    <Tr key={index} border={'none'} background={'#FAFAFA'}>
                      <Td sx={columnStyle}>{order.order_no}</Td>
                      <Td sx={columnStyle}>{totalQuantity?.[index]}</Td>
                      <Td sx={columnStyle}>{order.total_amount}</Td>
                      <Td sx={columnStyle}>{order.status}</Td>
                      <Td sx={columnStyle}>{moment(order.created_at).format('MMM DD,YYYY')}</Td>
                    </Tr>
                  ))}
                </Tbody>
              ) : (
                <></>
              )}
            </ContentWrap>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </>
  )
}

export default AccountPage
