import React from 'react'
import { Image } from '@chakra-ui/react'

const Person = ({ height, width }: { height?: number; width?: number }): JSX.Element => {
  return <Image sx={{ top: '0 !important' }} src="data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.0002 10.9997C13.9468 10.9997 16.3335 8.61301 16.3335 5.66634C16.3335 2.71967 13.9468 0.333008 11.0002 0.333008C8.0535 0.333008 5.66683 2.71967 5.66683 5.66634C5.66683 8.61301 8.0535 10.9997 11.0002 10.9997ZM11.0002 13.6663C7.44016 13.6663 0.333496 15.453 0.333496 18.9997V21.6663H21.6668V18.9997C21.6668 15.453 14.5602 13.6663 11.0002 13.6663Z' fill='%23514F6D'/%3E%3C/svg%3E" boxSize={{ width, height }} className="custom-svg" pos="relative" top="-20px" alt="Person Icon" />
}

export default Person

Person.defaultProps = {
  height: 22,
  width: 22,
}
