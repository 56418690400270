import React from 'react'

const ArrowDown = ({
  height,
  width,
}: {
  height?: number
  width?: number
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_59830_84540)">
        <path
          d="M7.41 8.58984L12 13.1698L16.59 8.58984L18 9.99984L12 15.9998L6 9.99984L7.41 8.58984Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_59830_84540">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowDown

ArrowDown.defaultProps = {
  height: 24,
  width: 24,
}
