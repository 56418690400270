import axios, { AxiosInstance } from 'axios'
import { getToken } from '../utils/getToken'

export const baseURL = process.env.REACT_APP_API_URL

const createAxiosInstance = (token: boolean): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })

  instance.interceptors.request.use((config) => {
    if (token && getToken() !== null) {
      config.headers.Authorization = `token ${getToken() ?? ''}`
    }
    return config
  })

  return instance
}

export const axiosInstance = createAxiosInstance(false)
export const axiosAuthInstance = createAxiosInstance(true)
