import { Box, Button, Flex, Heading, Divider, Text, useMediaQuery } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Cart from '../../Assets/Images/Cart'
import Delete from '../../Assets/Images/Delete'
import EditIcon from '../../Assets/Images/EditIcon'
import InfoIcon from '../../Assets/Images/InfoIcon'
import ProductImage from '../ProductImage/ProductImage'
import { PRODUCT_DESCRIPTION } from '../../Routes/Routes'
import { centerHeading, commonStyles, font20Center, font32Center } from '../../styles/commonStyles'
import { IPropsResult } from '../ProductListing/ProductListing.types'
import LocationIcon from '../../Assets/Images/LocationIcon'
import { ProductContext } from '../../Context/ProductContext.Provider/ProductContext'
import { IPropProductItem } from './ProductItem.types'
import { avarageSpeed } from '../../Content/Contants'
import { convertHoursToDaysHoursMinutes } from '../../utils/getTime'

const ProductItem = ({ productItem, type, onModalOpenHandler, onOpen, setProductDeleteID, setModalToOpen, setQuantity, setQuantityInStock, setIdToUpdate }: IPropProductItem): JSX.Element => {
  const [largeScreen992] = useMediaQuery('(min-width: 992px)')
  const navigate = useNavigate()

  const { setProduct } = useContext(ProductContext)

  const onNameClick = (product: IPropsResult, id: number): void => {
    setProduct(product)
    type === 'product' && navigate(PRODUCT_DESCRIPTION.replace(':productId', String(id)))
  }

  const showDiscountedPrice: boolean = productItem?.discounted_price !== undefined && productItem?.discounted_price !== productItem?.price && productItem?.discounted_price !== 0

  return (
    <Box>
      <Flex justifyContent="space-between" gap={{ base: '1rem', lg: '11rem' }} my={'3rem'} flexDirection={{ base: 'column', lg: 'row' }}>
        <Flex flexDirection={{ base: 'column', lg: 'row' }} flex={6} gap={'2rem'} width="100%">
          <ProductImage imageUrl={productItem?.product?.image} key={productItem.id} />
          <Box width="100%">
            <Heading variant={commonStyles.productListingVariant} mb={'1.1rem'} cursor={'pointer'} onClick={() => onNameClick(productItem, productItem?.id)}>
              {productItem?.product?.name?.charAt(0).toUpperCase() + productItem?.product?.name?.slice(1)}
            </Heading>
            <Text sx={commonStyles.font24}>{productItem?.product?.composition?.join(', ')}</Text>
            <Text sx={commonStyles.font24}>{productItem.product.desc}</Text>
            {type === 'product' && (
              <Heading variant={commonStyles.productListingVariant} mb={'1rem'} mt="1.5rem">
                {productItem?.business?.name?.charAt(0).toUpperCase() + productItem?.business?.name?.slice(1)}
              </Heading>
            )}
            {type === 'product' && productItem?.distance != null && (
              <Flex alignItems={'center'} gap="1rem">
                {largeScreen992 ? <LocationIcon fill="#556AB0" /> : <LocationIcon fill="#556AB0" height={25} width={25} />}
                <Heading sx={centerHeading.style}>{convertHoursToDaysHoursMinutes(productItem?.distance / avarageSpeed)}</Heading>
              </Flex>
            )}
            {type === 'Cart' && (
              <Button
                leftIcon={largeScreen992 ? <InfoIcon /> : <InfoIcon height={28} width={28} />}
                rightIcon={largeScreen992 ? <EditIcon /> : <EditIcon height={18} width={18} />}
                iconSpacing={'8'}
                mt={'1.3rem'}
                sx={commonStyles.productListingButton2}
                onClick={() => {
                  setIdToUpdate(productItem.id)
                  setQuantityInStock(productItem.quantity_in_stock)
                  setModalToOpen('Update')
                  setQuantity(productItem?.quantity ?? 1)
                  onOpen()
                }}>
                Quantity: {productItem?.quantity}
              </Button>
            )}
            {type === 'bill' && (
              <Heading sx={commonStyles.font24_40} fontWeight={'500'} color={'#484649'} mt={'2.2rem'}>
                Quantity: {productItem?.quantity}
              </Heading>
            )}
          </Box>
        </Flex>
        <Box flex={2} height="auto">
          {type === 'Cart' && (
            <Flex flexDirection="column" alignItems={'center'} gap={{ base: '1rem', lg: '2rem' }}>
              <Button
                iconSpacing={'6'}
                rightIcon={largeScreen992 ? <Delete fill="#AC1E1A" /> : <Delete height={18} width={16} fill="#AC1E1A" />}
                sx={commonStyles.deleteItemButton}
                onClick={() => {
                  onOpen()
                  setProductDeleteID(productItem.id)
                  setModalToOpen('Delete')
                }}>
                Delete Item
              </Button>
              {productItem?.quantity_in_stock === 0 && <Heading sx={commonStyles.deleteItemButton}>Out of stock</Heading>}
            </Flex>
          )}
          {type === 'product' && (
            <Flex flexDirection="column" justifyContent="space-around" alignItems={{ base: 'flex-start', lg: 'flex-end' }} gap={{ base: '1rem', lg: '' }} height={'100%'}>
              <Box>
                <Flex gap={'8px'} alignItems="center" justifyContent={{ base: 'flex-start', lg: 'flex-end' }}>
                  {showDiscountedPrice && (
                    <Heading sx={commonStyles.font20_400} textDecoration="line-through" color="#556AB0">
                      NGN {productItem.price}
                    </Heading>
                  )}
                  <Heading sx={font32Center.style}>NGN {(productItem.discounted_price === 0 ? productItem.price : productItem.discounted_price) ?? productItem.price}</Heading>
                </Flex>
                <Text sx={font20Center.style} mt={'19px'}>
                  delivery chargers applied
                </Text>
              </Box>
              <Button iconSpacing={'6'} rightIcon={<Cart height={22} width={22} fill={'white'} />} sx={commonStyles.productButtonAdd} onClick={() => onModalOpenHandler(productItem)}>
                Add to cart
              </Button>
            </Flex>
          )}
        </Box>
      </Flex>
      {type !== 'bill' && <Divider borderColor="#AEAAAE" />}
    </Box>
  )
}

export default ProductItem
