import React from 'react'
import './CustomScrollbar.css'

const CustomScrollbar = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  return <div className="custom-scrollbar">{children}</div>
}

export default CustomScrollbar
