import React from 'react'
import { SimpleGrid, GridItem, Box, Text, Center } from '@chakra-ui/react'
import HomePageMedical1 from '../../Assets/Images/HomepageMedical1.svg'
import HomePageMedical2 from '../../Assets/Images/HomepageMedical2.svg'
import HomePageMedical3 from '../../Assets/Images/HomepageMedical3.svg'
import ForwardArray from '../../Assets/Images/ForwardArray'
import HeaderWithText from '../HeaderWithText/HeaderWithText'
import { IPropsBlogs } from './Blogs.types'

const Blogs = (props: IPropsBlogs): JSX.Element => {
  return (
    <Box p={{ base: '0 1.5rem', lg: '5rem 5rem', xl: '5rem 11rem' }}>
      <HeaderWithText headingStyle={{ variant: { base: 'mobileBold20', lg: 'font24' } }} textStyle={{ variant: 'lightMedium' }} heading="Professional answers to your health questions" text="Hear from reliable healthcare providers" />
      <SimpleGrid columns={{ base: 1, lg: 3 }} gap={'4.5rem'} mt={'4rem'}>
        <GridItem>
          <img src={HomePageMedical1} />
          <HeaderWithText
            heading="HEALTH GUIDES"
            text="The Latest on Flu Vaccines"
            headingStyle={{
              variant: { base: 'mobileBold20', lg: 'font24' },
              mt: '3rem',
            }}
            textStyle={{ variant: 'Bold' }}
          />
          <Text variant={{ base: 'mobileBold16', lg: 'lightMedium' }}>How bad will this year&apos;s flu be? Who should get a shot? And more.</Text>
        </GridItem>
        <GridItem>
          {' '}
          <img src={HomePageMedical2} />
          <HeaderWithText
            heading="DECISION TOOLS"
            text="Is Medicare Advantage Right for Me?"
            headingStyle={{
              variant: { base: 'mobileBold20', lg: 'font24' },
              mt: '3rem',
            }}
            textStyle={{ variant: 'Bold' }}
          />
          <Text variant={{ base: 'mobileBold16', lg: 'lightMedium' }}>See what kind of expanded medicare coverage is right for you.</Text>
        </GridItem>
        <GridItem>
          {' '}
          <img src={HomePageMedical3} />
          <HeaderWithText
            heading="HEALTHCARE SAVINGS"
            text="How to Save on Your Insulin"
            headingStyle={{
              variant: { base: 'mobileBold20', lg: 'font24' },
              mt: '3rem',
            }}
            textStyle={{ variant: 'Bold' }}
          />
          <Text variant={{ base: 'mobileBold16', lg: 'lightMedium' }}> Here&apos;s how 27 brands and generics compare.</Text>
        </GridItem>
      </SimpleGrid>
      <Center mt={'4rem'} gap={'2rem'}>
        <Text variant={{ base: 'mobileBold16', lg: 'Bold' }} fontWeight={'500'} color={'black'}>
          See More
        </Text>
        <ForwardArray />
      </Center>
    </Box>
  )
}

export default Blogs
