import React, { useContext, useState, useEffect } from 'react'
import useSWR from 'swr'
import DSCarousel from '../../components/DSCarousel/DSCarousel'
import ProductsListing from '../../components/ProductListing/ProductsListing'
import Footer from '../../Layout/Footer/Footer'
import ContentWrap from '../../components/ContentWrap/ContentWrap'
import { IPropsProductPage } from './ProductPage.types'
import { Header } from '../../Layout/Header/Header'
import { endpoints } from '../../Content/Endpoints'
import MobileHeader from '../../Layout/MobileHeader/MobileHeader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext.Provider/CartContext'
import { AuthContext } from '../../Context/AuthContext.Provider/AuthContext'
import swrConfig from '../../config/swr.Config'
import { IPropsResult } from '../../components/ProductListing/ProductListing.types'
import DrugAutoComplete from '../../components/DrugAutocomplete/DrugAutoComplete'
import { IOptions } from '../../components/FindDrug/FindDrug.types'
import { commonStyles } from '../../styles/commonStyles'
import { PRODUCT } from '../../Routes/Routes'

const ProductPage = ({ props }: IPropsProductPage): JSX.Element => {
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const search = params.get('search')
  const latitude = params.get('latitude')
  const longitude = params.get('longitude')

  const { notificationCartCount } = useContext(CartContext)
  const { token } = useContext(AuthContext)
  const { fetcher } = swrConfig

  const [dataWithNoZeroQuantity, setDataWithNoZeroQuantity] = useState<IPropsResult[]>([])
  const [businessId, setBusinessId] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<IOptions>()

  const { data: productListing, error, isLoading } = useSWR(`${endpoints.PRODUCT_LISTING}${search != null ? `?search=${search}` : ''}${latitude != null && longitude != null ? `&latitude=${latitude}&longitude=${longitude}` : ''}`)

  const { data: cartListing } = useSWR(token !== null && token !== '' ? [endpoints.CART, true] : null, async ([url, useToken]) => await fetcher(url, useToken))

  const speicifProductUrl: string = `${endpoints.SPECIFIC_PRODUCT}${String(cartListing?.results?.[0]?.business?.id ?? businessId)}?product__name__icontains=${String(search ?? '')}`

  const { data: sepcificPharmacyProduct } = useSWR(notificationCartCount > 0 && (cartListing != null || businessId != null) ? speicifProductUrl : null)

  useEffect(() => {
    const noquantityInStock = productListing?.results?.filter((productItem: IPropsResult) => productItem.quantity_in_stock !== 0)
    setDataWithNoZeroQuantity(noquantityInStock)
  }, [productListing])

  useEffect(() => {
    const cartData = localStorage.getItem('cart')
    if (cartData !== null) {
      const data = JSON.parse(cartData)
      setBusinessId(data?.[data?.length - 1]?.business?.id)
    }
  }, [])

  const productSearchHandler = (): void => {
    navigate(`${PRODUCT}${selectedOption?.label != null ? `?search=${selectedOption?.label}${latitude != null && longitude != null ? `&latitude=${latitude}&longitude=${longitude}` : ''}` : ''}`)
  }

  return (
    <>
      <Header headerFor="PRODUCTS" />
      <MobileHeader headerFor="NORMAL" />
      {dataWithNoZeroQuantity?.length > 0 && (
        <DrugAutoComplete
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          style={{
            mx: 'auto',
            maxW: commonStyles.AutoCompleteMaxWidth,
            mt: commonStyles.spacingFromHeader,
          }}
          onClick={productSearchHandler}
        />
      )}
      <ContentWrap isFetching={isLoading} isError={error} count={dataWithNoZeroQuantity?.length} styleNoDataFound={{ mb: '0' }}>
        <ProductsListing data={productListing} type="product" key="product" sepcificPharmacyProduct={sepcificPharmacyProduct?.results} dataWithNoZeroQuantity={dataWithNoZeroQuantity} />
      </ContentWrap>
      {dataWithNoZeroQuantity?.length === 0 && (
        <DrugAutoComplete
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          style={{
            mx: 'auto',
            maxW: commonStyles.AutoCompleteMaxWidth,
          }}
          onClick={productSearchHandler}
        />
      )}
      <DSCarousel heading="Related Products" text="See how much you can save on medications with other brands." />
      <Footer />
    </>
  )
}

export default ProductPage
